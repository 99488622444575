import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { object, string, bool, number, func, shape, any } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import omit from 'lodash/omit';

import {
    SelectMultipleFilter,
    SelectParallelFilter,
    PriceFilter,
    Tooltip,
    SortingBadge,
} from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { setDefaultQueryParams, sanitizeLocationSearchString, parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import css from './SearchFilters.css';
import { SearchAgent } from '../../components';
import config from '../../config';
import IconFilter from './IconFilter';
import SwitchHorse from '../SwitchHorse/SwitchHorse.js';
import {
    initialValuesGroup,
    initialValues,
    initialValuesSubFields,
    initialValuesWithFlag,
    initialPriceRangeValue,
    initialRangeValues,
    handleSelectOptionsHelper,
    handleRangeHelper,
    handlePriceHelper,
    handleSelectMultipleOptionsHelper,
    initialValuesWithoutDependency,
    reactOnDisciplineChange,
    applyVerticalSpacing,
} from './SearchFilters.helpers';
import SearchFiltersModal from './SearchFiltersModal';
import { getDefaultOptions } from '../SortingBadge/SortingBadge';
import { checkMarketplaceCurrentUser, ensureCurrentUser } from '../../util/data';
import {
    qualificationSearchSchemaParam,
    desiredLevelsSearchSchemaParam,
    desiredDisciplinesSearchSchemaParam,
} from '../../containers/SearchPage/SearchPage.helpers';
import IconSpinner from '../IconSpinner/IconSpinner';
import { TopbarLocationSearchForm } from '../../forms';
import { redirectToURLWithModalState } from '../Topbar/Topbar.helpers';
import FilterPanelMobIcon from './FilterPanelMobIcon';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useScrollY } from '../../hooks/useScrollY';
import { isEmailNotVerified } from '../../util/user';
import MapMobIcon from './MapMobIcon';
import {
    RIDER_AVAILABILITY_CONFIRMED,
    RIDER_AVAILABILITY_NOT_AVAILABLE,
} from '../../marketplace-custom-config';
const {
    defaultCountry,
    initialSearchAddress,
    listingTypeHorse,
    listingTypeRider,
    userTypeRider,
    userTypeHorseowner,
    maps: { supportedCountries },
} = config;

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;

const isDocumentDefined = typeof document !== 'undefined';

const SearchFilters = ({
    rootClassName,
    className,
    urlQueryParams,
    searchInProgress,
    genderFilter,
    ageFilter,
    hightFilter,
    desiredDisciplineFilter,
    availabilityFilter,
    mobilityTransportFilter,
    levelFilter,
    activitiesFilter,
    priceFilter,
    qualificationFilter,
    equipmentFilter = {},
    currentUser,
    filterParamNames,
    onUpdateUserProfileInfo,
    updateUserProfileInfoInProgress,
    updateUserProfileInfoError,
    primaryFilters,
    onMapIconClick,
    mapPanelOpen,
    location,
    pagination,
    mainHorse,
    history,
    intl,
}) => {
    const [isTablet, , computing] = useIsMobile(1024);

    const [filtersModalVisible, setVisibility] = useState(false);
    const [tabSelected, setTabSelected] = useState(null);
    const [selectedSortOption, setSelectedSortOption] = useState(null);
    const [scrollY] = useScrollY(!!isTablet);

    const { totalItems } = pagination || {};
    const { listingType, sort: sortKey, extraSort, origin } = parse(location.search);

    /**
     * hide map button a little bit earlier
     * than a user reaching the very bottom of the page
     */
    const extraYSpace = 25;
    const scrolledToBottom =
        isDocumentDefined &&
        (window.innerHeight >= document.body.clientHeight
            ? false
            : Math.ceil(scrollY) + window.innerHeight + extraYSpace >= document.body.clientHeight);

    const isHorseListingType = listingType === listingTypeHorse;
    const isRidersListingType = listingType === listingTypeRider;

    const currentUserDefined = currentUser && currentUser.id;
    const userType = currentUserDefined ? currentUser.attributes.profile.publicData.userType : null;
    const userAvailability = currentUserDefined
        ? currentUser.attributes.profile.publicData.availabilityStatus
        : null;
    const availabilityConfirmed = userAvailability === RIDER_AVAILABILITY_CONFIRMED;
    const riderIsNotAvailable = userAvailability === RIDER_AVAILABILITY_NOT_AVAILABLE;
    const isHorseOwner = userType === userTypeHorseowner;
    const isRider = userType === userTypeRider;

    const riderSearchForRiders = isRider && isRidersListingType;
    const ownerSearchForRiders = isHorseOwner && isRidersListingType;

    const mainHorseAvailableForOwner = isHorseOwner && mainHorse;

    const defaultOptions = getDefaultOptions(currentUser);
    const options = defaultOptions.reduce((acc, item) => {
        const { key } = item;

        if (key === '-price' && isRidersListingType) return [...acc, { ...item, disabled: true }];
        if (key === 'score' && isRider && (riderSearchForRiders || riderIsNotAvailable))
            return [...acc, { ...item, disabled: true }];
        if (key === 'score' && isHorseOwner && ownerSearchForRiders && mainHorseAvailableForOwner)
            return [...acc, { ...item, disabled: false }];

        return [...acc, item];
    }, []);

    useEffect(() => {
        if (extraSort) {
            return setSelectedSortOption(
                options.find(({ key }) => key === extraSort) || options[0]
            );
        }

        setSelectedSortOption(sortKey ? options.find(({ key }) => key === sortKey) : options[0]);
    }, [sortKey, extraSort]);

    useEffect(() => {
        /**
         * wait until email notification is made
         * visible/hidden
         * on map modal
         * closing/opening
         *
         * see applyVerticalSpacing for SP
         */
        if (computing || isTablet) {
            return;
        }
        setTimeout(() => {
            applyVerticalSpacing(!isTablet);
        }, 0);
    }, [isTablet, computing, mapPanelOpen]);

    const {
        attributes: {
            profile: {
                publicData: { country },
            },
        },
    } = ensureCurrentUser(currentUser);

    const emailNotVerified = isEmailNotVerified(currentUser);

    const { currency, code } = supportedCountries[country] || supportedCountries[defaultCountry];
    const { address: initialCountryAddress } = initialSearchAddress[code];

    const geocoderPredictionMaybe =
        urlQueryParams && urlQueryParams.address
            ? sanitizeLocationSearchString(urlQueryParams.address)
            : initialCountryAddress;

    const isDefaultLocation =
        urlQueryParams.address && urlQueryParams.address === initialCountryAddress;

    const queryParamsWithDefaultLocation = queryParams =>
        isDefaultLocation ? setDefaultQueryParams(queryParams) : queryParams;

    const classes = classNames(rootClassName || css.root, {
        [className]: true,
        [css.scrolled]: scrollY,
    });

    const getIntlById = id =>
        intl.formatMessage({
            id,
        });

    const initialDesiredDiscipline = desiredDisciplineFilter
        ? initialValuesWithFlag(urlQueryParams, desiredDisciplineFilter)
        : null;

    const initialActivities = activitiesFilter
        ? initialValuesWithFlag(urlQueryParams, activitiesFilter)
        : null;

    const initialAvailability = availabilityFilter
        ? {
              [availabilityFilter.parallelFilter.name]: initialValues(
                  urlQueryParams,
                  availabilityFilter.parallelFilter.paramName
              ),
              [availabilityFilter.name]: initialValues(
                  urlQueryParams,
                  availabilityFilter.paramName
              ),
          }
        : null;

    const initialLevel = levelFilter
        ? initialValuesWithoutDependency(urlQueryParams, levelFilter.paramName)
        : null;

    const initialGender = genderFilter
        ? initialValues(urlQueryParams, genderFilter.paramName)
        : null;

    const initialAge = ageFilter ? initialRangeValues(urlQueryParams, ageFilter.paramName) : null;

    const initialHight = hightFilter
        ? initialRangeValues(urlQueryParams, hightFilter.paramName)
        : null;

    const initialQualification = qualificationFilter.dependentOnSubOption
        ? initialValuesSubFields(urlQueryParams, qualificationFilter)
        : qualificationFilter
        ? initialValuesWithoutDependency(
              urlQueryParams,
              qualificationFilter.paramName,
              qualificationFilter
          )
        : null;

    // ? qualificationFilter.dependentOnSubOption
    //     ? initialValuesWithDependency(urlQueryParams, qualificationFilter, {})
    //     : initialValuesSubFields(urlQueryParams, qualificationFilter)
    // : null;

    const initialEquipment = equipmentFilter
        ? initialValuesGroup(urlQueryParams, equipmentFilter.groupConfig)
        : null;

    const initialPriceRange = priceFilter
        ? initialPriceRangeValue(urlQueryParams, priceFilter.paramName, currency)
        : null;

    const initialMobilityTransport = mobilityTransportFilter
        ? initialValuesWithFlag(urlQueryParams, mobilityTransportFilter)
        : null;

    const redirectWithQueryParams = (queryParams, skipDefault = false) =>
        history.push(
            createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                skipDefault ? queryParams : queryParamsWithDefaultLocation(queryParams)
            )
        );

    const resetAll = e => {
        const queryParams = omit(urlQueryParams, filterParamNames);
        redirectWithQueryParams(queryParams);

        // blur event target if event is passed
        if (e && e.currentTarget) {
            e.currentTarget.blur();
        }
    };

    const popupFiltersSelected =
        initialHight ||
        initialAge ||
        initialGender.length ||
        initialEquipment.hasValues ||
        (initialMobilityTransport || []).length;

    const filtersSelected = !!(
        popupFiltersSelected ||
        (initialDesiredDiscipline && initialDesiredDiscipline.length) ||
        (initialActivities && initialActivities.length) ||
        (initialAvailability &&
            initialAvailability.openForPartTimeProposals &&
            initialAvailability.openForPartTimeProposals.length) ||
        (initialAvailability && initialAvailability.availability.length) ||
        (initialLevel && initialLevel.length) ||
        (initialQualification && initialQualification.length) ||
        initialPriceRange
    );

    const handleTabSelected = selector => {
        const tabSelectorAlready = selector === tabSelected ? null : selector;
        setTabSelected(tabSelectorAlready);
    };

    const handleSelectMultipleOptions = (...args) =>
        redirectWithQueryParams(handleSelectMultipleOptionsHelper(urlQueryParams, ...args));

    const handleSelectOptions = (...args) =>
        redirectWithQueryParams(handleSelectOptionsHelper(urlQueryParams, ...args));

    const handlePrice = (...args) =>
        redirectWithQueryParams(handlePriceHelper(urlQueryParams, ...args));

    const handleRange = (...args) =>
        redirectWithQueryParams(handleRangeHelper(urlQueryParams, ...args));

    const filterElementProps = {
        onSubmit: handleSelectOptions,
        showAsPopup: !isTablet,
        contentPlacementOffset: FILTER_DROPDOWN_OFFSET,
    };

    const otherFiltersProps = {
        ...filterElementProps,
        showAsPopup: false,
    };

    const disciplinesUrlParam = urlQueryParams[desiredDisciplinesSearchSchemaParam];

    const desiredDisciplineFilterElement = desiredDisciplineFilter && (
        <SelectMultipleFilter
            id="SearchFilters.desiredDisciplineFilter"
            name="desiredDisciplines"
            urlParam={desiredDisciplineFilter.paramName}
            label={getIntlById('SearchFilters.mainDisciplineLabel')}
            options={desiredDisciplineFilter.options[defaultCountry]}
            initialValues={initialDesiredDiscipline}
            popupClassName={css.mainDisciplinePopup}
            className={css.desiredDisciplineFilter}
            {...filterElementProps}
            onSubmit={(paramName, disciplines) => {
                const levelsUrlParam = urlQueryParams[desiredLevelsSearchSchemaParam];
                const qualificationUrlParam = urlQueryParams[qualificationSearchSchemaParam];

                const dependentFilters = [
                    {
                        allowed: !!levelsUrlParam,
                        paramValue: levelsUrlParam,
                        paramName: desiredLevelsSearchSchemaParam,
                    },
                    {
                        allowed: !!qualificationUrlParam,
                        paramValue: qualificationUrlParam,
                        paramName: qualificationSearchSchemaParam,
                    },
                ].filter(({ allowed }) => !!allowed);

                const urlQueryParamsWithDependentFilters =
                    dependentFilters.length > 0
                        ? dependentFilters.reduce((acc, { paramName, paramValue }) => {
                              return {
                                  ...acc,
                                  ...reactOnDisciplineChange(
                                      acc,
                                      paramValue,
                                      disciplines,
                                      paramName
                                  ),
                              };
                          }, urlQueryParams)
                        : urlQueryParams;

                redirectWithQueryParams(
                    handleSelectOptionsHelper(
                        urlQueryParamsWithDependentFilters,
                        paramName,
                        disciplines,
                        null, // groupParams
                        desiredDisciplineFilter.hasFlag
                    )
                );
            }}
        />
    );

    const levelFilterElement = levelFilter && (
        <SelectMultipleFilter
            id="SearchFilters.levelFilter"
            name="desiredDisciplinesLevels"
            urlParam={levelFilter.paramName}
            label={getIntlById('SearchFilters.levelLabel')}
            options={levelFilter.pureOptions}
            initialValues={initialLevel}
            popupClassName={css.mainDisciplinePopup}
            className={css.desiredDisciplineFilter}
            {...filterElementProps}
            onSubmit={(paramName, entries) => {
                const redirectParams =
                    disciplinesUrlParam && entries
                        ? reactOnDisciplineChange(
                              urlQueryParams,
                              entries.join(','),
                              disciplinesUrlParam.split(','),
                              paramName
                          )
                        : handleSelectOptionsHelper(
                              urlQueryParams,
                              paramName,
                              entries,
                              null, // groupParams
                              levelFilter.hasFlag
                          );

                redirectWithQueryParams(redirectParams);
            }}
        />
    );

    const activitiesFilterElement = activitiesFilter && (
        <SelectMultipleFilter
            id="SearchFilters.activitiesFilter"
            name="activities"
            urlParam={activitiesFilter.paramName}
            options={activitiesFilter.options}
            label={getIntlById('SearchFilters.activitiesLabel')}
            initialValues={initialActivities}
            popupClassName={css.mainDisciplinePopup}
            className={css.desiredDisciplineFilter}
            {...filterElementProps}
            onSubmit={(...args) => {
                const groupParams = null;
                handleSelectOptions(...args, groupParams, activitiesFilter.hasFlag);
            }}
        />
    );

    const availabilityFilterElement = availabilityFilter && (
        <SelectParallelFilter
            id="SearchFilters.availabilityFilter"
            name="availability"
            urlParam={availabilityFilter.paramName}
            options={availabilityFilter.options}
            secondaryOptions={availabilityFilter.parallelFilter.options}
            secondaryName="openForPartTimeProposals"
            label={getIntlById('SearchFilters.availabilityLabel')}
            initialValues={initialAvailability}
            popupClassName={css.mainDisciplinePopup}
            className={css.desiredDisciplineFilter}
            {...filterElementProps}
            onSubmit={(...args) => {
                /** for clear all */
                const defaultFilter = {
                    [availabilityFilter.name]: null,
                    [availabilityFilter.parallelFilter.name]: null,
                };
                const [, filters] = args;

                redirectWithQueryParams(
                    Object.entries(filters || defaultFilter).reduce(
                        (acc, [key, value]) => handleSelectOptionsHelper(acc, `pub_${key}`, value),
                        { ...urlQueryParams }
                    )
                );
            }}
        />
    );

    const qualificationFilterElement = qualificationFilter && (
        <SelectMultipleFilter
            id="SearchFilters.qualification"
            name={qualificationFilter.name || 'qualification'}
            urlParam={qualificationFilter.paramName}
            label={getIntlById('SearchFilters.qualificationLabel')}
            options={qualificationFilter.pureOptions}
            initialValues={initialQualification}
            multipleFilters
            multipleFiltersConfig={qualificationFilter}
            separateByCategory={qualificationFilter.separateByCategory}
            popupClassName={css.mainDisciplinePopup}
            className={css.desiredDisciplineFilter}
            {...filterElementProps}
            onSubmit={({ paramName }, entries) => {
                const redirectParams =
                    disciplinesUrlParam && entries
                        ? reactOnDisciplineChange(
                              urlQueryParams,
                              entries.join(','),
                              disciplinesUrlParam.split(','),
                              paramName
                          )
                        : handleSelectOptionsHelper(
                              urlQueryParams,
                              paramName,
                              entries,
                              null, // groupParams
                              qualificationFilter.hasFlag
                          );

                redirectWithQueryParams(redirectParams);
            }}
        />
    );

    const priceFilterElement = priceFilter && isHorseListingType && (
        <PriceFilter
            id="SearchFilters.priceFilter"
            urlParam={priceFilter.paramName}
            onSubmit={handlePrice}
            showAsPopup={!isTablet}
            {...priceFilter.config}
            initialValues={initialPriceRange}
            contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
            user={currentUser}
        />
    );

    const onSortingSubmit = ({ param, key }) => {
        const isOrigin = key === 'origin';
        const isScore = key === 'score';

        if (isScore) {
            const searchParams = {
                ...urlQueryParams,
                extraSort: 'score',
            };
            /**
             * to avoid collision with origin param
             */
            delete searchParams.sort;
            return redirectWithQueryParams(searchParams, true);
        }

        const searchParams = {
            ...urlQueryParams,
            ...param,
        };

        if (!isOrigin) {
            /**
             * origin is used also to sort by distance
             *
             * origin is removed in api request params
             * instead delete searchParams.origin;
             *
             * url origin param is used for applying distance in TopBar
             */

            delete searchParams.extraSort;
        } else {
            /**
             * no combination of origin & sort allowed
             */
            delete searchParams.sort;
            delete searchParams.extraSort;
            /**
             * default origin is a user's current location;
             * set origin from location search params
             */
            searchParams.origin = origin;
        }

        redirectWithQueryParams(searchParams, true);
    };

    const sortingBadgeElement = (
        <SortingBadge
            selectedOption={selectedSortOption}
            options={options}
            breakpoint={1024}
            onSubmit={onSortingSubmit}
        />
    );

    const searchAgentElement = (
        <SearchAgent
            currentUser={currentUser}
            urlQueryParams={urlQueryParams}
            onUpdateUserProfileInfo={onUpdateUserProfileInfo}
            inProgress={updateUserProfileInfoInProgress}
            updateError={updateUserProfileInfoError}
            history={history}
        />
    );

    const searchingResultsElement =
        typeof totalItems === 'number' ? (
            <p className={css.resultsFound}>
                <FormattedMessage
                    id={`SearchFilters.foundResults-${listingType || listingTypeHorse}`}
                    values={{ count: totalItems }}
                />
                <FormattedMessage
                    id="SearchFilters.searchHeader"
                    values={{ geocoderPrediction: geocoderPredictionMaybe }}
                />
            </p>
        ) : (
            <IconSpinner />
        );

    const infoTooltipElement = (
        <div className={css.infoText}>
            <FormattedMessage id="SearchFilters.tooltipTitleText" />
            <Tooltip
                rootClassName={css.tooltipWrapper}
                tooltipClassName={css.tooltipClass}
                content={
                    <>
                        <p>
                            <FormattedMessage id="SearchFilters.tooltipTitleText" />
                        </p>
                        <p>
                            <FormattedMessage id="SearchFilters.tooltipDescription" />
                        </p>
                    </>
                }
            >
                <img
                    className={css.infoIcon}
                    src="https://horsedeal.imgix.net/static/icons/icon-info-m-Ground-Ground-040.svg"
                />
            </Tooltip>
        </div>
    );

    return (
        <>
            <div
                onClick={onMapIconClick}
                className={classNames(css.mapMobBadge, {
                    [css.faded]: scrolledToBottom,
                    [css.hidden]: searchInProgress,
                })}
            >
                <MapMobIcon />
                <FormattedMessage id="SearchFilters.mapIcon" />
            </div>

            <div className={classes} id="filters-panel">
                <SwitchHorse rootClassName={css.desktop} />
                <div className={classNames(css.filters, css.desktop)}>
                    {desiredDisciplineFilterElement}
                    {activitiesFilterElement}
                    {availabilityFilterElement}
                    {levelFilterElement}
                    {qualificationFilterElement}
                    {priceFilterElement}

                    <button
                        className={classNames(
                            css.searchFiltersPanelClosed,
                            popupFiltersSelected ? css.searchFiltersPanelSelected : null
                        )}
                        onClick={() => setVisibility(true)}
                    >
                        <IconFilter />
                        <FormattedMessage id="SearchFilters.otherFilters" />
                    </button>
                </div>

                <div className={classNames(css.sortSearchAgentWrapper, css.desktop)}>
                    {sortingBadgeElement}
                    {searchAgentElement}
                </div>

                <TopbarLocationSearchForm
                    rootClassName={classNames(css.mob, css.filterBadgeMob)}
                    onTabPanelToggle={() =>
                        redirectToURLWithModalState({ location, history }, 'mobilesearch')
                    }
                    searchMarkerIcon={
                        <div
                            className={classNames(css.filterPanelMobIcon, {
                                [css.filterPanelMobIconSelected]: filtersSelected,
                            })}
                            onClick={() => setVisibility(true)}
                        >
                            <FilterPanelMobIcon />
                        </div>
                    }
                    withMapMarker
                />

                {searchInProgress && (
                    <div className={css.loadingResults}>
                        <IconSpinner />
                    </div>
                )}
            </div>

            {filtersModalVisible && (
                <SearchFiltersModal
                    isOpen
                    onClose={() => setVisibility(false)}
                    resetAll={resetAll}
                    intl={intl}
                    resultsCountVisible={totalItems}
                    tabSelected={tabSelected}
                    handleTabSelected={handleTabSelected}
                    urlQueryParams={urlQueryParams}
                    otherFiltersProps={otherFiltersProps}
                    handleRange={handleRange}
                    isHorseListingType={isHorseListingType}
                    isTablet={isTablet}
                    searchInProgress={searchInProgress}
                    searchFilters={[
                        desiredDisciplineFilterElement,
                        activitiesFilterElement,
                        availabilityFilterElement,
                        levelFilterElement,
                        qualificationFilterElement,
                        priceFilterElement,
                    ]}
                    initialValues={{
                        initialDesiredDiscipline,
                        initialActivities,
                        initialAvailability,
                        initialLevel,
                        initialGender,
                        initialHight,
                        initialQualification,
                        initialAge,
                        initialEquipment,
                        initialPriceRange,
                        initialMobilityTransport,
                    }}
                    {...primaryFilters}
                />
            )}

            <div
                className={classNames(
                    {
                        [css.searchResultsUpperSectionEmailBar]: emailNotVerified,
                    },
                    css.searchResultsUpperSection
                )}
            >
                {!searchInProgress && (
                    <>
                        <div className={classNames(css.searchResultSummary, css.desktop)}>
                            {searchingResultsElement}
                            {infoTooltipElement}
                        </div>
                        <div className={classNames(css.searchResultSummary, css.mob)}>
                            <div className={css.searchResultSummaryMobPanel}>
                                {searchingResultsElement}
                                <div>
                                    <SwitchHorse />
                                    {sortingBadgeElement}
                                    {searchAgentElement}
                                </div>
                            </div>
                            {infoTooltipElement}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

SearchFilters.propTypes = {
    rootClassName: string,
    className: string,
    urlQueryParams: object.isRequired,
    searchingInProgress: bool,
    genderFilter: propTypes.filterConfig,
    ageFilter: propTypes.filterConfig,
    hightFilter: propTypes.filterConfig,
    desiredDisciplineFilter: propTypes.filterConfig,
    priceFilter: propTypes.filterConfig,
    qualificationFilter: propTypes.filterConfig,
    equipmentFilter: propTypes.filterConfig,

    updateUserProfileInfoInProgress: bool,
    updateUserProfileInfoError: any,
    // from withRouter
    history: shape({
        push: func.isRequired,
    }).isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
    currentUser: propTypes.currentUser,
    onUpdateUserProfileInfo: func.isRequired,
};

const mapStateToProps = ({
    user,
    marketplaceData,
    ManageListingsPage: { ownEntities },
    SearchPage: { searchInProgress, extraSortInProgess, preflightSearchInProgress, pagination },
}) => {
    const currentUser = checkMarketplaceCurrentUser({
        user,
        marketplaceData,
    });
    const currentUserDefined = currentUser && currentUser.id;
    const mainHorseId = currentUserDefined
        ? currentUser.attributes.profile.publicData.mainHorseId
        : null;

    return {
        searchInProgress: searchInProgress || extraSortInProgess || preflightSearchInProgress,
        pagination,
        currentUser: checkMarketplaceCurrentUser({
            user,
            marketplaceData,
        }),
        mainHorse: ownEntities
            ? Object.values(ownEntities?.ownListing || {})?.find(
                  ({ id, attributes }) =>
                      attributes?.state === 'published' && id.uuid === mainHorseId
              )
            : null,
    };
};

export default compose(withRouter, connect(mapStateToProps), injectIntl)(SearchFilters);
