import {
    getPlaceDetailsData,
    setPlaceDetailsData,
    getPredictionsData,
    setPredictionsData,
} from '../util/api';
import { types as sdkTypes } from '../util/sdkLoader';
import { isLocalhost } from '../util/urlHelpers';

const { LatLng, LatLngBounds } = sdkTypes;

export const getPredictionsFromDB = async searchStr => {
    if (!searchStr || searchStr.length < 3) return null;

    try {
        const response = await getPredictionsData(searchStr);
        const data = await response.json();

        return data;
    } catch (e) {
        if (isLocalhost()) {
            console.log('Error.getPredictionsFromDB:' + e.message);
        }
    }
};

export const addPredictionsToDB = async (search, predictions) => {
    if (!search || !predictions) return;

    try {
        await setPredictionsData(
            JSON.stringify({
                search,
                predictions,
            })
        );
    } catch (e) {
        if (isLocalhost()) {
            console.log('Error.addPredictionsToDB:' + e.message);
        }
    }
};

export const getPlaceDetailsDataFromDB = async placeId => {
    if (!placeId) return;

    try {
        const response = await getPlaceDetailsData(placeId);
        const [data] = await response.json();
        const sdkPlaceData = {
            ...data,
        };

        if (sdkPlaceData.hasOwnProperty('origin')) {
            const { lat, lng } = sdkPlaceData.origin;
            sdkPlaceData.origin = new LatLng(lat, lng);
        }

        if (sdkPlaceData.hasOwnProperty('bounds')) {
            const { ne, sw } = sdkPlaceData.bounds;
            sdkPlaceData.bounds = new LatLngBounds(
                new LatLng(ne.lat, ne.lng),
                new LatLng(sw.lat, sw.lng)
            );
        }

        return sdkPlaceData;
    } catch (e) {
        if (isLocalhost()) {
            console.log('Error.getPlaceDetailsDataFromDB:' + e.message);
        }
    }
};

export const addPlaceDetailsDataToDB = async (placeId, placeData) => {
    if (!placeId || !placeData) return;

    try {
        await setPlaceDetailsData(
            JSON.stringify({
                place_id: placeId,
                placeData,
            })
        );
    } catch (e) {
        if (isLocalhost()) {
            console.log('Error.addPlaceDetailsDataToDB:' + e.message);
        }
    }
};
