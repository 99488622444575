import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { loadData, getUserExternalReview } from '../../containers/ProfilePage/ProfilePage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
    Page,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    NamedRedirect,
    NoResultsBlock,
    Button,
} from '../../components';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { promptUserBio } from '../OnboardingPage/OnboardingPage.duck';
import { ProfileSettingsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import { updateProfile, uploadImage } from './ProfileSettingsPage.duck';
import css from './ProfileSettingsPage.css';
import config from '../../config';
import EditProfileBar from './EditProfileBar';
import { getEntityAssets, removeEntityAssets, uploadEntityAssets } from '../../ducks/Assets.duck';
import { useIsMobile } from '../../hooks/useIsMobile';
import classNames from 'classnames';
import { useScrollY } from '../../hooks/useScrollY';
import { parse } from '../../util/urlHelpers';
import { ArrowNextIcon } from '../../icons';
import EmailConfirmationBar from '../../components/Topbar/EmailConfirmationBar';
import { updateUserViewsNumber, updateUserVisitorsData } from '../../util/api';
import { userDocumentsFromAssetsData } from '../../util/userDocumentsFromAssets';
import InvitesModal from '../ConfirmationPage/InvitesModal';
import { ONBOARDING_INVITES_SENT } from '../OnboardingPage/OnboardingPage';
import { userIsDeletedOrBanned, userIsNotAvailable } from '../../util/user';

const sessionStorageDefined = typeof window !== 'undefined';

const { userTypeRider, userTypeHorseowner } = config;

const { UUID } = sdkTypes;

const onImageUploadHandler = async (values, fn) => {
    const { id, imageId, file } = values;
    if (file) {
        return await fn({ id, imageId, file });
    }
};

const ProfileSettingsPageComponent = ({
    currentUser,
    currentUserRequestInProgress,
    publicUser,
    image,
    onImageUpload,
    onUpdateProfile,
    scrollingDisabled,
    intl,
    isPublic,
    onGetUserExternalReview,
    userShowLoading,
    userShowRequested,
    history,
    assetsData,
    assetsLoadingRequests,
    ...rest
}) => {
    const [isMobile, , computing] = useIsMobile(1024);
    const [scrollY] = useScrollY();

    const [invitesModalOpen, setInvitesModalOpen] = useState(
        () => sessionStorageDefined && sessionStorage.getItem(ONBOARDING_INVITES_SENT)
    );

    const { mobilemenu } = parse(rest.location.search);

    const user = ensureCurrentUser(isPublic ? publicUser : currentUser);
    const isLoggedOut = !currentUser;

    const userIdData = user ? user.id : null;
    const userUuid = userIdData ? userIdData.uuid : null;

    const userDocuments = userDocumentsFromAssetsData(userUuid, assetsData);

    const uploadDocumentsInProgress = assetsLoadingRequests && assetsLoadingRequests[userUuid];

    const {
        attributes: { emailVerified, email },
    } = currentUser || { attributes: {} };

    const { firstName, lastName, bio, publicData } = user.attributes.profile;
    const {
        age,
        licence,
        experience,
        language,
        drivingLicense,
        auto,
        galleryImages,
        riderQualification,
        riderCertification,
        userType,
    } = publicData;
    const { userType: userTypeParam } = rest.params;

    const userNotAvailable =
        (userShowRequested && userIsNotAvailable(publicUser)) ||
        (isPublic && !userShowLoading && !publicUser && !rest.activeTransactionsDataInProgress);
    /** allow non authenticated users view riders profiles */
    const currentUserNotAvailable = userIsDeletedOrBanned(currentUser);

    const isRider =
        currentUser && currentUser.id
            ? userTypeRider === currentUser.attributes.profile.publicData.userType
            : false;

    const { userType: publicUserType } = publicUser?.attributes?.profile?.publicData || {};
    const { userType: currentUserType } = currentUser?.attributes?.profile?.publicData || {};

    const publicUserIsRider =
        publicUser && (publicUser.attributes.profile.publicData || {}).userType === userTypeRider;

    const isSameUser =
        currentUser && currentUser.id && user && user.id && currentUser.id.uuid === user.id.uuid;

    useEffect(() => {
        /** update public user visitors data */
        if (isSameUser) return;
        if (isLoggedOut) return;
        if (!isPublic) return;
        if (!publicUser) return;
        if (!publicUserType) return;
        if (!currentUserType) return;

        const { mainHorseId } = currentUser.attributes.profile.publicData;

        if (currentUserType !== userTypeHorseowner || !mainHorseId) return;
        if (publicUserType !== userTypeRider) return;
        /**
         * visitedEntryId - riderId
         * visitorRepresentationId - mainHorseId
         */
        const riderId = publicUser.id.uuid;
        updateUserVisitorsData(riderId, riderId, mainHorseId, 'rider');
    }, [isSameUser, isLoggedOut, publicUserType, currentUserType, isPublic, userUuid]);

    useEffect(() => {
        if (!userUuid) return;

        onGetUserExternalReview(userUuid);
    }, [userUuid]);

    useEffect(() => {
        /**
         * update viewsNum field for riders only
         * when any user (but not the same) logged-in/out
         * visits the page
         */
        if (isSameUser) return;
        if (!isPublic) return;
        if (!publicUser) return;
        if (!publicUserType) return;
        if (publicUserType !== userTypeRider) return;

        updateUserViewsNumber(userUuid);
    }, [isSameUser, isPublic, userUuid, publicUserType]);

    if (userTypeParam && userType && userTypeParam !== userType) {
        return (
            <NamedRedirect name="ProfileSettingsPage" params={{ userType: userType || 'rider' }} />
        );
    }

    const inquiryDisabled = isRider && publicUserIsRider;

    const profileImageId = user.profileImage ? user.profileImage.id : null;
    const profileImage = image || { imageId: profileImageId };
    /**
     * Navigation bar is shown not only for the current user
     * but also when other user comes to rider profile page
     */
    const showNavBar = !computing && !!scrollY && isPublic && !mobilemenu;
    const galleryImagesMaybe =
        galleryImages && typeof galleryImages === 'string' ? JSON.parse(galleryImages) : null;

    const handleSubmit = ({
        firstName,
        lastName,
        bio: rawBio,
        location,
        age,
        licence,
        experience,
        language,
        drivingLicense,
        auto,
        galleryImages,
        riderQualification,
        riderCertification,
    }) => {
        const publicData = {
            location,
            age,
            licence,
            experience,
            language,
            drivingLicense: !!drivingLicense,
            auto: !!auto,
            emailVerified: currentUser.attributes.emailVerified,
            galleryImages: JSON.stringify(galleryImages),
            riderQualification,
            riderCertification,
        };
        // Ensure that the optional bio is a string
        const bio = rawBio || '';

        const profile = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            bio,
        };
        const uploadedImage = image;

        // Update profileImage only if file system has been accessed
        const updatedValues =
            uploadedImage && uploadedImage.imageId && uploadedImage.file
                ? { ...profile, profileImageId: uploadedImage.imageId }
                : profile;

        onUpdateProfile(updatedValues, publicData);
    };
    const showForm =
        user.id && !userNotAvailable && !currentUserRequestInProgress && !currentUserNotAvailable;

    const profileSettingsForm = showForm ? (
        <ProfileSettingsForm
            className={css.form}
            user={user}
            currentUser={ensureCurrentUser(currentUser)}
            publicUser={publicUser}
            inquiryDisabled={inquiryDisabled}
            isLoggedOut={isLoggedOut}
            isSameUser={isSameUser}
            isMobile={isMobile}
            showNavBar={showNavBar}
            initialValues={{
                firstName,
                lastName,
                bio,
                profileImage: user.profileImage,
                age,
                licence,
                experience,
                language,
                drivingLicense,
                auto,
                galleryImages: galleryImagesMaybe,
                riderQualification,
                riderCertification,
            }}
            profileImage={profileImage}
            onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
            onSubmit={handleSubmit}
            onUpdateProfile={onUpdateProfile}
            isPublic={isPublic}
            userDocuments={userDocuments}
            uploadDocumentsInProgress={uploadDocumentsInProgress}
            {...rest}
        />
    ) : null;
    const pageUserIsRider = userType === userTypeRider;

    const [userDisplayName] = userUuid ? user.attributes.profile.displayName.split(' ') : [];
    const userPublicName = firstName && lastName ? `${firstName} ${lastName}` : userDisplayName;

    const title = pageUserIsRider
        ? `Reiter-Profil von ${userPublicName} | HorseDeal`
        : intl.formatMessage({ id: 'ProfileSettingsPage.title' });
    const description = pageUserIsRider
        ? `Disziplinen, Niveau, Qualifikationen, Auszeichnungen und Ausbildungen von ${userPublicName}`
        : null;
    const noIndexTag = [
        {
            name: 'robots',
            content: 'noindex',
        },
    ];

    const showTopBarNonPublic = isSameUser || (isPublic ? !computing && !isMobile : true);
    const showTopBarPublic = isPublic && !computing && isMobile && !isSameUser;
    const userAvatar =
        user.profileImage && user.profileImage.attributes
            ? (
                  user.profileImage.attributes.variants['square-small2x'] ||
                  user.profileImage.attributes.variants['square-small'] ||
                  user.profileImage.attributes.variants['landscape-crop'] ||
                  {}
              ).url
            : null;

    const pageImagesProps =
        userAvatar && pageUserIsRider
            ? {
                  facebookImages: [
                      {
                          name: 'facebook',
                          url: userAvatar,
                          width: 480,
                          height: 480,
                      },
                  ],
                  twitterImages: [
                      {
                          name: 'twitter',
                          url: userAvatar,
                          width: 480,
                          height: 480,
                      },
                  ],
                  logo: userAvatar,
              }
            : {};
    return (
        <Page
            className={css.root}
            scrollingDisabled={scrollingDisabled}
            metaTags={userNotAvailable ? noIndexTag : null}
            title={title}
            description={description}
            {...pageImagesProps}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    {isMobile && <EmailConfirmationBar />}

                    {showTopBarNonPublic && (
                        <TopbarContainer
                            currentPage="ProfileSettingsPage"
                            desktopClassName={classNames({
                                [css.topbarDesktop]: showNavBar,
                            })}
                            showEmailNotificationBar={!isMobile}
                        />
                    )}
                    {showTopBarPublic && (
                        <nav className={css.topbarMob}>
                            <Button onClick={() => history.goBack()}>
                                <ArrowNextIcon />
                                <span>Zurück</span>
                            </Button>
                        </nav>
                    )}
                    {invitesModalOpen && (
                        <InvitesModal
                            isOpen
                            onClose={() => {
                                sessionStorage.removeItem(ONBOARDING_INVITES_SENT);
                                setInvitesModalOpen(false);
                            }}
                        />
                    )}
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    blobBackground={!!userNotAvailable}
                    className={classNames({
                        [css.userNotAvailable]: !!userNotAvailable,
                    })}
                >
                    <div
                        className={classNames({
                            [css.content]: !userNotAvailable,
                        })}
                    >
                        <div className={css.headingContainer}></div>
                        {isPublic && isSameUser && (
                            <EditProfileBar
                                userType={userType}
                                emailNotVerified={!emailVerified && email}
                                currentUser={currentUser}
                                scrolled={showNavBar}
                            />
                        )}
                        {profileSettingsForm}
                        {userNotAvailable && (
                            <NoResultsBlock
                                headerId="Dieses Profil existiert nicht mehr."
                                parapraphId="Das aufgerufene Profil wurde gelöscht und existiert nicht mehr."
                                rootClass={css.noResultsRoot}
                                isSearchForm
                            />
                        )}
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool, func, object, shape, string } = PropTypes;

ProfileSettingsPageComponent.propTypes = {
    currentUser: propTypes.currentUser,
    image: shape({
        id: string,
        imageId: propTypes.uuid,
        file: object,
        uploadedImage: propTypes.image,
    }),
    onImageUpload: func.isRequired,
    onUpdateProfile: func.isRequired,
    scrollingDisabled: bool.isRequired,
    updateInProgress: bool.isRequired,
    updateProfileError: propTypes.error,
    uploadImageError: propTypes.error,
    uploadInProgress: bool.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    const { currentUser, currentUserRequestInProgress } = state.user;
    const { ProfileSettingsPage, ProfilePage, OnboardingPage, ListingPage, Assets } = state;
    const {
        image,
        uploadImageError,
        uploadInProgress,
        updateInProgress,
        updateProfileError,
        horseownerListings,
        horseownerListingsInProgress,
    } = ProfileSettingsPage;

    const {
        userId,
        externalReviewsErrors,
        externalReviewsRequests,
        externalReviewsData,
        userShowLoading,
        activeTransactionsDataInProgress,
        activeTransactionsDataError,
        activeTransactionsData,
    } = ProfilePage;

    const userMatches = getMarketplaceEntities(state, [{ type: 'user', id: userId }]);

    const publicUser = userMatches.length === 1 ? userMatches[0] : null;
    const {
        userDocErrorMetadata, // ?
        promptUserBioInProgress,
        promptUserBioError,
        promptUserBio,
    } = OnboardingPage;

    const { assetsLoadingRequests, assetsData } = Assets;

    return {
        currentUserRequestInProgress,
        currentUser,
        publicUser,
        image,
        scrollingDisabled: isScrollingDisabled(state),
        updateInProgress,
        updateProfileError,
        uploadImageError,
        uploadInProgress,
        userDocErrorMetadata,
        externalReviewsErrors,
        externalReviewsRequests,
        externalReviewsData,
        userShowLoading,
        horseownerListings,
        horseownerListingsInProgress,
        promptUserBioInProgress,
        promptUserBioError,
        promptUserBio,
        activeTransactionsDataInProgress,
        activeTransactionsDataError,
        activeTransactionsData,
        assetsLoadingRequests,
        assetsData,
    };
};

const mapDispatchToProps = dispatch => ({
    onImageUpload: data => dispatch(uploadImage(data)),
    onUpdateProfile: (data, publicData) => dispatch(updateProfile(data, publicData)),
    // TODO use onGetEntityAssets instead

    onDocsListRequest: userId => dispatch(getEntityAssets({ id: userId })),
    onUploadSingleDocRequest: data => dispatch(uploadEntityAssets(data)),
    onRemoveSingleDocRequest: (Key, userId) =>
        dispatch(removeEntityAssets({ entityId: userId, idToDelete: Key })),

    onGetUserExternalReview: userReviewedId => dispatch(getUserExternalReview(userReviewedId)),
    onPromptUserBio: () => dispatch(promptUserBio()),
    dispatch,
});

const ProfileSettingsPage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    injectIntl
)(ProfileSettingsPageComponent);
ProfileSettingsPage.loadData = params => {
    const id = new UUID(params.id);
    return loadData(id);
};

export default ProfileSettingsPage;
