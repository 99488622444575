import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import config from '../../config';
import { metaTagProps } from '../../util/seo';
import { canonicalRoutePath, matchPathname } from '../../util/routes';
import css from './Page.css';

const MODAL_BREAKPOINT = 768;

const facebookImage = 'https://horsedeal.imgix.net/static/social-image-1200x630.webp';
const twitterImage = 'https://horsedeal.imgix.net/static/social-image-600x314.webp';

const preventDefault = e => {
    e.preventDefault();
};

const twitterPageURL = siteTwitterHandle => {
    if (siteTwitterHandle && siteTwitterHandle.charAt(0) === '@') {
        return `https://twitter.com/${siteTwitterHandle.substring(1)}`;
    } else if (siteTwitterHandle) {
        return `https://twitter.com/${siteTwitterHandle}`;
    }
    return null;
};
const zendeskBlackListSearch = ['?mobilemenu=open', '?mobilesearch=open'];
const zendeskBlackListPath = {
    /** Onboarding */
    OnboardingPage: { mobileOnly: true, name: 'OnboardingPage' },
    /** Listing page */
    EditListingPage: { mobileOnly: true, name: 'EditListingPage' },
    /** Profile page */
    ProfilePage: { mobileOnly: true, name: 'ProfilePage' },
    ProfilePagePreview: { mobileOnly: true, name: 'ProfilePagePreview' },
    EditListingPageExternal: { mobileOnly: true, name: 'EditListingPageExternal' },
    ProfileSettingsPage: { mobileOnly: false, name: 'ProfileSettingsPage' },
    /** Chat page */
    OrderDetailsPage: { mobileOnly: true, name: 'OrderDetailsPage' },
    OrderMessagesPage: { mobileOnly: true, name: 'OrderMessagesPage' },
    InboxPage: { mobileOnly: true, name: 'InboxPage' },
    /** Listing page */
    ListingPage: { mobileOnly: true, name: 'ListingPage' },
    CheckoutPage: { mobileOnly: true, name: 'CheckoutPage' },
    ListingPageVariant: { mobileOnly: true, name: 'ListingPageVariant' },
    ListingPageCanonical: { mobileOnly: true, name: 'ListingPageCanonical' },
    /** Search page */
    SearchPage: { name: 'SearchPage' },
    DeleteSearchGroupPage: { name: 'DeleteSearchGroupPage' },
    SearchFiltersPage: { name: 'SearchFiltersPage' },
    SearchListingsPage: { name: 'SearchListingsPage' },
    SearchMapPage: { name: 'SearchMapPage' },
};

const injectZendesk = script => {
    script.src =
        'https://static.zdassets.com/ekr/snippet.js?key=19386857-4c3f-410c-ba41-c15f3d6af8d8';
    script.id = 'ze-snippet';
    script.async = true;

    script.onload = () => {
        window.zE('webWidget', 'show');
        window.zE('webWidget', 'close');
    };

    document.body.appendChild(script);
};

const removeZendesk = (script, callback) => {
    if (script && window.zE) {
        window.zE && window.zE('webWidget', 'hide');
        script && document.body.removeChild(script);
        callback();
    }
};

class PageComponent extends Component {
    constructor(props) {
        super(props);
        // Keeping scrollPosition out of state reduces rendering cycles (and no bad states rendered)
        this.state = { script: null };
        this.scrollPosition = 0;
        this.contentDiv = null;
        this.scrollingDisabledChanged = this.scrollingDisabledChanged.bind(this);
        this.injectZendeskScript = this.injectZendeskScript.bind(this);
        this.removeZendeskScript = this.removeZendeskScript.bind(this);
    }

    injectZendeskScript() {
        const {
            location: { pathname, search },
        } = this.props;

        const routes = matchPathname(pathname, routeConfiguration());
        const suppressSearchIncluded = zendeskBlackListSearch.includes(search);
        const suppressConfig = (routes || []).reduce(
            (acc, r) => zendeskBlackListPath[r?.route.name] || acc,
            undefined
        );

        const isMobile = window.innerWidth < MODAL_BREAKPOINT;
        const shouldInjectZeScript =
            !suppressSearchIncluded &&
            (!suppressConfig || (suppressConfig && !suppressConfig.mobileOnly ? false : !isMobile));

        if (shouldInjectZeScript) {
            this.setState(
                {
                    script: document.createElement('script'),
                },
                () => injectZendesk(this.state.script)
            );
        }
    }

    removeZendeskScript() {
        this.setState({
            script: null,
        });
    }

    componentDidMount() {
        // By default a dropped file is loaded in the browser window as a
        // file URL. We want to prevent this since it might loose a lot of
        // data the user has typed but not yet saved. Preventing requires
        // handling both dragover and drop events.
        document.addEventListener('dragover', preventDefault);
        document.addEventListener('drop', preventDefault);
        this.injectZendeskScript();
    }

    componentWillUnmount() {
        document.removeEventListener('dragover', preventDefault);
        document.removeEventListener('drop', preventDefault);

        removeZendesk(this.state.script, this.removeZendeskScript);
    }

    componentDidUpdate(prevProps) {
        const {
            location: { search },
        } = this.props;

        if (prevProps.location.search === search) {
            return;
        }

        const suppressSearchIncluded = zendeskBlackListSearch.includes(search);

        if (this.state.script && suppressSearchIncluded) {
            removeZendesk(this.state.script, this.removeZendeskScript);
        } else {
            this.injectZendeskScript();
        }
    }

    scrollingDisabledChanged(currentScrollingDisabled) {
        if (currentScrollingDisabled && currentScrollingDisabled !== this.scrollingDisabled) {
            // Update current scroll position, if scrolling is disabled (e.g. modal is open)
            this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            this.scrollingDisabled = currentScrollingDisabled;
        } else if (currentScrollingDisabled !== this.scrollingDisabled) {
            this.scrollingDisabled = currentScrollingDisabled;
        }
    }

    render() {
        const {
            className,
            rootClassName,
            children,
            location,
            intl,
            scrollingDisabled,
            referrer,
            author,
            contentType = 'website',
            description,
            facebookImages,
            published,
            schema,
            tags,
            title,
            twitterHandle,
            twitterImages,
            updated,
            logo,
            ogDescription,
            ogTitle,
            metaTags: metaTagsProps,
        } = this.props;

        const classes = classNames(rootClassName || css.root, className, {
            [css.scrollingDisabled]: scrollingDisabled,
        });

        this.scrollingDisabledChanged(scrollingDisabled);
        const referrerMeta = referrer ? <meta name="referrer" content={referrer} /> : null;

        const canonicalRootURL = config.canonicalRootURL;
        const shouldReturnPathOnly = referrer && referrer !== 'unsafe-url';
        const canonicalPath = canonicalRoutePath(
            routeConfiguration(),
            location,
            shouldReturnPathOnly
        );
        const canonicalUrl = `${canonicalRootURL}${canonicalPath}`;

        const siteTitle = config.siteTitle;
        const schemaTitle = intl.formatMessage({ id: 'Page.schemaTitle' }, { siteTitle });
        const schemaDescription = intl.formatMessage({ id: 'Page.schemaDescription' });
        const metaTitle = title || schemaTitle;
        const metaDescription = description || schemaDescription;
        const facebookImgs = facebookImages || [
            {
                name: 'facebook',
                url: facebookImage,
                width: 1200,
                height: 630,
            },
        ];
        const twitterImgs = twitterImages || [
            {
                name: 'twitter',
                url: twitterImage,
                width: 600,
                height: 314,
            },
        ];

        const metaToHead = metaTagProps(
            {
                author,
                contentType,
                description: metaDescription,
                facebookImages: facebookImgs,
                twitterImages: twitterImgs,
                published,
                tags,
                title: metaTitle,
                twitterHandle,
                updated,
                url: canonicalUrl,
                locale: intl.locale,
                ogDescription,
                ogTitle,
            },
            metaTagsProps
        );

        // eslint-disable-next-line react/no-array-index-key
        const metaTags = metaToHead.map((metaProps, i) => <meta key={i} {...metaProps} />);

        const facebookPage = config.siteFacebookPage;
        const twitterPage = twitterPageURL(config.siteTwitterHandle);
        const instagramPage = config.siteInstagramPage;
        const sameOrganizationAs = [facebookPage, twitterPage, instagramPage].filter(
            v => v != null
        );

        // Schema for search engines (helps them to understand what this page is about)
        // http://schema.org
        // We are using JSON-LD format

        // Schema attribute can be either single schema object or an array of objects
        // This makes it possible to include several different items from the same page.
        // E.g. Product, Place, Video
        const schemaFromProps = (Array.isArray(schema) ? schema : [schema]).filter(s => !!s);

        const schemaArrayJSONString = JSON.stringify([
            ...schemaFromProps,
            {
                '@context': 'http://schema.org',
                '@type': 'Organization',
                '@id': `${canonicalRootURL}#organization`,
                url: canonicalRootURL,
                name: siteTitle,
                sameAs: sameOrganizationAs,
                logo: logo || `${canonicalRootURL}/static/webapp-icon-192x192.webp`,
                address: config.address,
            },
            {
                '@context': 'http://schema.org',
                '@type': 'WebSite',
                url: canonicalRootURL,
                description: schemaDescription,
                name: schemaTitle,
                publisher: {
                    '@id': `${canonicalRootURL}#organization`,
                },
            },
        ]);

        const scrollPositionStyles = scrollingDisabled
            ? { marginTop: `${-1 * this.scrollPosition}px` }
            : {};

        // If scrolling is not disabled, but content element has still scrollPosition set
        // in style attribute, we scrollTo scrollPosition.
        const hasMarginTopStyle = this.contentDiv && this.contentDiv.style.marginTop;
        if (!scrollingDisabled && hasMarginTopStyle) {
            window.requestAnimationFrame(() => {
                window.scrollTo(0, this.scrollPosition);
            });
        }

        return (
            <div className={classes}>
                <Helmet
                    htmlAttributes={{
                        lang: intl.locale,
                    }}
                >
                    <title>{title}</title>
                    {referrerMeta}
                    <link rel="canonical" href={canonicalUrl} />
                    {
                        // according to W3 recommendations, there should be only one meta tag
                        // responsible for encoding: either <meta charset="utf-8">
                        // which is currently set at index.html or the one commented below
                        // <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
                    }
                    {
                        // This use of the Content-Language value for an http-equiv attribute is deprecated by the HTML specification
                        // <meta httpEquiv="Content-Language" content={intl.locale} />
                    }
                    {metaTags}
                    <script type="application/ld+json">
                        {schemaArrayJSONString.replace(/</g, '\\u003c')}
                    </script>
                    <meta
                        name="google-site-verification"
                        content="fudx6zsj9uWoycJsdJlN1s_fkN0hytWu3moaC7ekHmI"
                    />
                    <meta
                        name="google-site-verification"
                        content="fudx6zsj9uWoycJsdJlN1s_fkN0hytWu3moaC7ekHmI"
                    />
                </Helmet>

                <div
                    className={css.content}
                    style={scrollPositionStyles}
                    ref={c => {
                        this.contentDiv = c;
                    }}
                >
                    {children}
                </div>
            </div>
        );
    }
}

const { any, array, arrayOf, bool, func, number, object, oneOfType, shape, string } = PropTypes;

PageComponent.propTypes = {
    className: string,
    rootClassName: string,
    children: any,
    scrollingDisabled: bool.isRequired,

    // Handle referrer policy
    referrer: string,

    // SEO related props
    author: string,
    contentType: string, // og:type
    description: string, // page description
    facebookImages: arrayOf(
        shape({
            width: number.isRequired,
            height: number.isRequired,
            url: string.isRequired,
        })
    ),
    twitterImages: arrayOf(
        shape({
            width: number.isRequired,
            height: number.isRequired,
            url: string.isRequired,
        })
    ),
    published: string, // article:published_time
    schema: oneOfType([object, array]), // http://schema.org
    tags: string, // article:tag
    title: string.isRequired, // page title
    twitterHandle: string, // twitter handle
    updated: string, // article:modified_time
    metaTags: arrayOf(object), // e.g. [{ name: string, content: string }]
    // from withRouter
    history: shape({
        listen: func.isRequired,
    }).isRequired,
    location: object.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const Page = injectIntl(withRouter(PageComponent));

Page.displayName = 'Page';

export default Page;
