import { getUserWishlist } from '../containers/FavoritePage/FavoritePage.duck';
import {
    CURRENT_USER_INITIAL_LOAD_COMPLETE,
    fetchUnreadMessages,
    updateUserProfileInfo,
} from '../ducks/user.duck';
import config from '../config';
import { updateCurrentUserLocationField } from '../util/localStorage';
import { queryOwnListings } from '../containers/ManageListingsPage/ManageListingsPage.duck';
import { stringifyToMarketplaceISO } from '../util/dates';
import { checkIOS } from '../util/userAgent';
import { checkCurrentUserUpdates } from '../ducks/Routing.duck';
import { userIsNotAvailable } from '../util/user';

const { userTypeHorseowner } = config;

const checkUserUpdatesOnFirstRender = () => dispatch => {
    const isNative = window.ReactNativeWebView;
    const { iOS } = checkIOS();

    if (isNative && iOS) {
        /**
         * Handle cases e.g. when a user buys
         * credits and the app is reloaded
         */
        dispatch(checkCurrentUserUpdates());
    }
};

const loadCurrentUserInitialData = ({ getState, dispatch }) => {
    dispatch(fetchUnreadMessages());
    dispatch(getUserWishlist());
    dispatch(checkUserUpdatesOnFirstRender());

    const { currentUser } = getState().user;

    if (userIsNotAvailable(currentUser)) return;

    const {
        attributes: {
            deleted,
            emailVerified,
            profile: {
                publicData: { userType, emailVerified: emailVerifiedPD, country },
            },
        },
    } = currentUser;

    const isHorseowner = userType === userTypeHorseowner;

    if (isHorseowner) {
        dispatch(queryOwnListings({}));
    }

    if (!deleted) {
        /**
         * if there is a country field, update localStorage value
         */
        if (country) {
            updateCurrentUserLocationField('countrySelected', country);
        }

        const reflectEmailVerificationStatus = !!emailVerified && !emailVerifiedPD;
        const params = {
            publicData: {
                lastVisitedAt: stringifyToMarketplaceISO(new Date()),
            },
        };

        if (reflectEmailVerificationStatus) {
            params.publicData = {
                emailVerified: true,
            };
        }

        dispatch(updateUserProfileInfo(params));
    }
};

export const reactOnCurrentUserDataLoad = () => ({ getState, dispatch }) => next => action => {
    const { type } = action;
    if (type === CURRENT_USER_INITIAL_LOAD_COMPLETE) {
        loadCurrentUserInitialData({ getState, dispatch });
    }

    next(action);
};
