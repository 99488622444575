import React, { useEffect, useState } from 'react';
import {
    AvailabilityBadge,
    AvatarExtraLarge,
    Button,
    FavoriteIcon,
    IconClose,
    IconSpinner,
    ListingCard,
    NamedLink,
    ReportProfile,
    UserDisplayName,
} from '../../components';
import { ensureTransaction, ensureUser } from '../../util/data';
import { IconEyeL } from '../../icons';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { ConditionalWrapper } from '../../util/common';
import { createSlug } from '../../util/urlHelpers';
import InfoProtectionSection from '../../components/TransactionPanel/InfoProtectionSection';
import classNames from 'classnames';
import { compose } from 'redux';
import { useIsMobile } from '../../hooks/useIsMobile';
import config from '../../config';
import css from './AsidePanel.css';
import { anonymizeOtherParty, resolveOtherPartyData } from '../../util/transaction';
import { useMatchingMatrix } from '../../hooks/useMatchingMatrix';
import ScoreBadge from '../../forms/ProfileSettingsForm/ScoreBadge';
import { useRiderListing } from '../../hooks/useRiderListing';

const { userTypeRider, userTypeHorseowner } = config;

const AsidePanel = ({
    transaction,
    setAsidePanelVisibility,
    navSectionClassName,
    containerRootClassName,
    currentUser,
    currentListing,
    intl,
}) => {
    const [isMobile] = useIsMobile(768);

    const {
        attributes: {
            profile: {
                publicData: { userType },
            },
        },
    } = currentUser;

    const { deleted: isDeleted } = currentListing.attributes || {};
    const ensuredTransaction = ensureTransaction(transaction);
    const currentProvider = ensureUser(ensuredTransaction.provider);
    const currentCustomer = ensureUser(ensuredTransaction.customer);
    const currentUserId = (currentUser.id || {}).uuid;
    const currentListingId = (currentListing.id || {}).uuid;
    const isHorseowner = userType === userTypeHorseowner; // buyer
    const isRider = userType === userTypeRider; // customer

    const otherParty = anonymizeOtherParty(
        resolveOtherPartyData(currentUser, currentCustomer, currentProvider)
    )(ensuredTransaction);

    const {
        id,
        attributes: {
            profile: {
                isAnonym,
                publicData: { userType: otherPartyType },
            },
        },
    } = otherParty;
    const otherPartyId = (id || {}).uuid;
    const otherPartyIsRider = otherPartyType === userTypeRider;
    const authorId = isHorseowner && otherPartyId ? otherPartyId : currentUserId;

    const asideComponentClasses = classNames({
        [css.asidePanel]: true,
        [css.asidePanelRider]: isRider,
        [containerRootClassName]: !!containerRootClassName,
    });

    const navSectionClasses = classNames({
        [navSectionClassName]: !!navSectionClassName,
    });

    const [riderListing, riderListingsRequestInProgress] = useRiderListing({
        params: { authorId },
    });

    /* useEffect */
    const [matchingMatrixLoading, matchingScore] = useMatchingMatrix({
        riderListingId: riderListing && riderListing.id ? riderListing.id.uuid : null,
        horseOwnerListingId: currentListingId,
    });

    return (
        <>
            <nav className={navSectionClasses}>
                <h2>
                    <FormattedMessage id="TransactionPanel.detailsHeading" />
                </h2>
                <Button
                    onClick={() => setAsidePanelVisibility(false)}
                    rootClassName={css.close}
                    title="close"
                    type="button"
                >
                    <span>
                        <FormattedMessage id="Modal.close" />
                    </span>
                    <IconClose rootClassName={css.closeIcon} />
                </Button>
            </nav>
            {isDeleted ? (
                <p className={css.listingDeletedNotification}>
                    Angaben sind nicht zulässig, da dieses Produkt vom Marktplatz gelöscht wurde.
                </p>
            ) : (
                <div className={asideComponentClasses}>
                    {isHorseowner && (
                        <section className={css.horseownerActionTab}>
                            {/* 
                        If FavoriteIcon is missed, the rider-listing is closed
                        in most cases the availability of a rider is notAvailable
                        */}
                            {riderListingsRequestInProgress && <IconSpinner />}
                            {riderListing && (
                                <FavoriteIcon
                                    currentListing={riderListing}
                                    rootClassName={css.favorite}
                                    inWishlistClassName={css.favoriteSelected}
                                />
                            )}

                            {otherPartyIsRider && <AvailabilityBadge user={otherParty} />}
                        </section>
                    )}
                    <AvatarExtraLarge
                        className={css.otherPartyAvatarLarge}
                        renderSizes="(max-width: 767px) 96px, 240px"
                        user={otherParty}
                    />
                    <h3 className={css.otherPartyDisplayName}>
                        <UserDisplayName user={otherParty} intl={intl} shouldBeTrimmed={isAnonym} />
                    </h3>
                    {!isAnonym && (
                        <NamedLink
                            className={css.otherPartyProfileLink}
                            name="ProfilePage"
                            params={{
                                id: otherParty.id.uuid,
                                userType: otherPartyType,
                            }}
                        >
                            <IconEyeL />
                            <FormattedMessage id="TransactionPanel.viewOtherPartyProfile" />
                        </NamedLink>
                    )}
                    {matchingMatrixLoading && <IconSpinner />}
                    {matchingScore && matchingScore.finalScore && (
                        <div className={css.matchingContainer}>
                            <ScoreBadge
                                scoreData={{ maxScore: 100, total: matchingScore.finalScore }}
                                scorePercentageText={
                                    <FormattedMessage
                                        id="TransactionPanel.scoreBadge"
                                        values={{ score: matchingScore.finalScore }}
                                    />
                                }
                            />
                        </div>
                    )}
                    <div className={css.divider} />
                    <h3 className={css.requestedAdvertisement}>
                        <FormattedMessage id="TransactionPanel.requestedAdvertisement" />
                    </h3>
                    <div className={css.detailCard}>
                        <ConditionalWrapper
                            condition={!isMobile && currentListing.id}
                            wrapper={children => (
                                <NamedLink
                                    isNotRouterLink
                                    name="ListingPage"
                                    params={{
                                        id: currentListing.id.uuid,
                                        slug: createSlug(currentListing),
                                    }}
                                >
                                    {children}
                                </NamedLink>
                            )}
                        >
                            <ListingCard
                                listing={currentListing}
                                maxParagraphHeight={89}
                                className={css.listingCard}
                                identitySectionClassName={css.identitySection}
                            />
                        </ConditionalWrapper>
                    </div>
                    <div className={css.divider} />
                    <InfoProtectionSection />
                    <ReportProfile
                        user={otherParty}
                        initialValues={{
                            firstName: otherParty.attributes.profile.displayName,
                            userEmail: '-',
                            profileLink: `profile/${otherParty.attributes.profile.publicData.userType}/public/${otherParty.id.uuid}`,
                            type: 'profile',
                        }}
                    />
                </div>
            )}
        </>
    );
};

export default compose(injectIntl)(AsidePanel);
