import React, { useState, useEffect, createContext } from 'react';
import { useSelector } from 'react-redux';

import { Form as FinalForm } from 'react-final-form';
import { useHistory } from 'react-router-dom';

import config from '../../config';
import { ModalPortal, IconSpinner } from '..';
import { FormattedMessage } from '../../util/reactIntl';

import css from './InquiryModal.css';
import classNames from 'classnames';
import { addIncludedDataToListing } from '../../forms/ProfileSettingsForm/helpers';
import { useListings } from '../../hooks/useListings';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { ensureUser } from '../../util/data';
import StepSendInquiryForm from './StepSendInquiryForm';
import StepChooseHorseForm from './StepChooseHorseForm';
import { useActiveTransactions } from './useActiveTransactions';
import { useEnquiryError } from './useEnquiryError';
import { useNoAvailableListings } from './useNoAvailableListings';
import { useActiveRequestForInitialListing } from './useActiveRequestForInitialListing';
import { useInquiryModalContext } from './useInquiryModalContext';
import { useInquiryDisabled } from './useInquiryDisabled';
import { CHOOSE_HORSE_STEP, SEND_INQUIRY_STEP } from './InquiryModal.helpers';

const { userTypeHorseowner } = config;

export const InquiryModalContext = createContext(undefined);

const InquiryModalContent = ({
    ridingListingAuthorId,
    sendEnquiryInProgress,
    sendEnquiryError,
    initialListing,
    notifyOnError,
    onSendEnquiry,
    publicUser,
    onClose,
    isOpen,
    ...rest
}) => {
    const history = useHistory();

    /** Redux store */
    const currentUser = useSelector(s => s.user.currentUser);
    const activeTransactionsData = useSelector(s => s.ProfilePage.activeTransactionsData);
    const activeTxDataError = useSelector(s => s.ProfilePage.activeTransactionsDataError);
    const activeTxDataInProgress = useSelector(s => s.ProfilePage.activeTransactionsDataInProgress);

    const { step, error, setError } = useInquiryModalContext();

    /** Get active transactions */
    useActiveTransactions();

    const [riderListings, riderListingLoading, , fulfilled] = useListings({
        params: { authorId: ridingListingAuthorId },
        allowed: !!ridingListingAuthorId,
    });
    const riderListing = riderListings ? riderListings[0] : null;

    const {
        id: { uuid: currentUserId },
        attributes: {
            profile: {
                publicData: { mainHorseId, userType },
            },
        },
    } = currentUser;

    const { id: publicUserIdContainer } = publicUser;
    const { uuid: publicUserId } = publicUserIdContainer || { uuid: null };

    const isOwner = userType === userTypeHorseowner;
    const riderListingId = riderListing && riderListing.id ? riderListing.id.uuid : null;
    const authorId = isOwner ? currentUserId : publicUserId;

    const [horseownerListings, horseownerListingsInProgress, included] = useListings({
        addDataToMarketplace: true,
        params: { authorId },
        allowed: !!authorId,
    });

    const horseownerListingsIdsStr = (horseownerListings || [])
        .map(({ id: { uuid } }) => uuid)
        .join(',');

    const filterActiveTxListings = ({ id: { uuid } }) =>
        !(
            Array.isArray(activeTransactionsData) &&
            activeTransactionsData.length > 0 &&
            activeTransactionsData.some(
                tx =>
                    tx.listingId === uuid &&
                    (tx.customerId === currentUserId || tx.customerId === publicUserId) &&
                    (tx.providerId === currentUserId || tx.providerId === publicUserId)
            )
        );

    const allListings =
        (initialListing
            ? [initialListing]
            : horseownerListings && included
            ? addIncludedDataToListing(horseownerListings, included)
            : null) || [];
    /** listings whithout active transactions */
    const listings = allListings.filter(filterActiveTxListings);

    const loading =
        horseownerListingsInProgress ||
        activeTxDataInProgress ||
        riderListingLoading ||
        (!!ridingListingAuthorId && !fulfilled);

    const isSendInquiry = !error && step === SEND_INQUIRY_STEP;
    const isChooseHorse = !error && step === CHOOSE_HORSE_STEP;
    /**
     * Disable the inquiry for both user types on certain conditions
     */
    useInquiryDisabled({
        setError,
        fulfilled,
        currentUser,
        riderListing,
    });

    useEnquiryError({ sendEnquiryError, activeTxDataError, setError });
    /**
     * No available listings to init a new transaction
     *
     * if a HO has a single listing and a rider already has tx with it;
     * if a rider has txs with all available HO listings;
     */
    useNoAvailableListings({
        horseownerListingsIdsStr,
        filterActiveTxListings,
        horseownerListings,
    });
    /**
     * The active request already exists for initial listing
     *
     * there is a specific horse listing - initial listing -
     * if a rider wants to send a request for a horseowner,
     *
     * show error in case the rider already has an active request
     * with this specific horse listing
     */
    useActiveRequestForInitialListing({ listings, loading });

    useEffect(() => {
        if (typeof notifyOnError === 'function') notifyOnError(error);
    }, [error, notifyOnError]);

    return (
        <ModalPortal
            id="inquiry-modal"
            isOpen={isOpen}
            step={step}
            onClose={loading ? () => null : onClose}
            containerClassName={css.modalContainer}
            contentClassName={classNames(css.modalContent, {
                [css.modalContentHorseListingStep]: isSendInquiry,
            })}
            containerClassNameJoined
            isSticky
        >
            {loading ? (
                <div className={css.loadingHolder}>
                    <IconSpinner />
                </div>
            ) : (
                <FinalForm
                    {...rest}
                    onSubmit={() => null}
                    initialValues={{
                        isSponsored: false,
                    }}
                    render={fieldRenderProps => {
                        const { values, form } = fieldRenderProps;
                        const {
                            listingId: listingIdValue,
                            listingTitle: listingTitleValue,
                            matchingScore,
                            comment,
                            isSponsored,
                        } = values || {};

                        const listingTitle = listingTitleValue || initialListing?.attributes?.title;

                        const disabled = isSendInquiry
                            ? !comment
                            : !listingIdValue || !matchingScore;

                        const userName =
                            publicUser && publicUser.attributes.profile
                                ? publicUser.attributes.profile.displayName.split(' ')[0]
                                : '';

                        const stepsProps = {
                            form,
                            values,
                            disabled: disabled || error || sendEnquiryInProgress,
                            inProgress: sendEnquiryInProgress,
                            publicUserName: userName,
                        };

                        return (
                            <>
                                <h4 className={css.modalHeading}>
                                    <FormattedMessage
                                        id={`InquiryModal.heading-${step}`}
                                        values={{ userName }}
                                    />
                                </h4>
                                <p>
                                    <FormattedMessage
                                        id={`InquiryModal.desc-${step}-${userType}`}
                                        values={{ userName, listingTitle }}
                                    />
                                </p>
                                {!!error && (
                                    <p className={css.error}>
                                        <FormattedMessage
                                            id={`InquiryModal.error-${
                                                typeof error === 'string' ? error : 'default'
                                            }`}
                                            values={{ userName, listingTitle }}
                                        />
                                    </p>
                                )}

                                {isChooseHorse && (
                                    <StepChooseHorseForm
                                        {...stepsProps}
                                        horseListingSelected={listingIdValue}
                                        riderListingLoading={riderListingLoading}
                                        riderListing={riderListing}
                                        mainHorseId={mainHorseId}
                                        listings={listings}
                                    />
                                )}

                                {isSendInquiry && (
                                    <StepSendInquiryForm
                                        {...stepsProps}
                                        matchingScore={matchingScore}
                                        onSubmit={async () => {
                                            const ownerEnquiry = isOwner && riderListingId;
                                            const listingId = ownerEnquiry
                                                ? riderListingId
                                                : listingIdValue;
                                            const listingSubstitution = ownerEnquiry
                                                ? listingIdValue
                                                : null;
                                            const txId = await onSendEnquiry({
                                                listingId,
                                                message: comment.trim(),
                                                listingSubstitutionId: listingSubstitution,
                                                isSponsored,
                                            });

                                            if (txId && txId.uuid) {
                                                history.push(
                                                    createResourceLocatorString(
                                                        'OrderDetailsPage',
                                                        routeConfiguration(),
                                                        { id: txId.uuid },
                                                        {}
                                                    )
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </>
                        );
                    }}
                />
            )}
        </ModalPortal>
    );
};

const InquiryModal = ({ publicUser: publicUserProp, ...rest }) => {
    const [error, setError] = useState(null);
    const [step, setStep] = useState(CHOOSE_HORSE_STEP);
    const [maxScore, setMaxScore] = useState(null);

    const publicUser = ensureUser(publicUserProp);

    return (
        <InquiryModalContext.Provider
            value={{ maxScore, setMaxScore, step, setStep, error, setError, publicUser }}
        >
            <InquiryModalContent {...rest} publicUser={publicUser} />
        </InquiryModalContext.Provider>
    );
};

export default InquiryModal;
