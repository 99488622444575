import { useEffect, useState } from 'react';
import { checkIOS } from '../../util/userAgent';

export const useFormFocusForSafari = () => {
    const [isMobSaf, setIsMobSaf] = useState(false);

    const onSendMessageFormFocus = () => {
        if (isMobSaf) {
            // Scroll to bottom
            window &&
                window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const { iOS, webkit } = checkIOS();
        setIsMobSaf(iOS && webkit);
    }, []);

    return [onSendMessageFormFocus];
};
