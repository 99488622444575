import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FieldCheckbox, IconSpinner } from '../../components';
import { IconPresentL } from '../../icons';
import { sponsoredTransactionAllowed } from '../../util/api';
import css from './SponsoredTransactionBlock.css';

export default ({ form, publicUserName }) => {
    const [disabled, setDisabled] = useState(true);
    const [computed, setComputed] = useState(false);

    const currentUser = useSelector(s => s.user.currentUser);
    const currentUserId = currentUser?.id?.uuid;

    const checkIfSponsoredTransactionAllowed = async userId => {
        try {
            const response = await sponsoredTransactionAllowed(userId);
            const data = await response.json();
            const isAllowed = data?.isAllowed;

            if (isAllowed) {
                // enable the field + set it as pre-selected
                setDisabled(false);
                form.change('isSponsored', true);
            }
        } catch (e) {
            //
        } finally {
            setComputed(true);
        }
    };

    useEffect(() => {
        if (currentUserId) {
            checkIfSponsoredTransactionAllowed(currentUserId);
        }
    }, [currentUserId]);

    if (!computed) {
        return <IconSpinner />;
    }

    return (
        <div className={css.root}>
            <div className={css.icon}>
                <IconPresentL />
            </div>
            <div className={css.heading}>
                <p>Treffer schenken</p>
                <FieldCheckbox
                    type="checkbox"
                    id="isSponsored"
                    name="isSponsored"
                    form={form}
                    className={css.toggler}
                    disabled={disabled}
                    togglerMode
                />
            </div>
            <p className={css.teaser}>30 Credits</p>
            <p className={css.description}>
                {publicUserName} benötigt keine Credits, um deine Anfrage zu akzeptieren.{' '}
                <a
                    href="https://support.horsedeal.com/hc/de/articles/23435125893010"
                    target="_blank"
                    rel="nofollow"
                >
                    Mehr erfahren
                </a>
            </p>
            <div className={css.footer}>
                <div className={css.badge}>Höchste Erfolgschancen</div>
                <div className={css.badge}>Neu</div>
            </div>
        </div>
    );
};
