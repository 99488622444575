import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Button,
    IconSpinner,
    InquiryModal,
    RiderNotAvailableModal,
    Tooltip,
} from '../../components';
import css from './ReinitiateTransactionButton.css';
import { FormattedMessage } from '../../util/reactIntl';
import { sendEnquiry } from '../../containers/ListingPage/ListingPage.duck';
import config from '../../config';
import {
    getActionButtonStateFromTx,
    resolveOtherPartyData,
    TRANSACTION_STATES_STATUS_ACCEPTED,
    TRANSACTION_STATES_STATUS_ENQUIRED,
} from '../../util/transaction';
import { createResourceLocatorString as createRLS } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import {
    ERROR_ACTIVE_TX_ALREADY_EXISTS,
    ERROR_INQUIRY_DISABLED_FOR_RIDER_NOT_AVAILABLE,
    ERROR_INQUIRY_DISABLED_FOR_RIDER_PROFILE_INCOMPLETE,
    ERROR_NO_AVAILABLE_LISTINGS_FOR_NEW_TX,
} from '../InquiryModal/InquiryModal.helpers';
import { fetchActiveTransactions } from '../../containers/ProfilePage/ProfilePage.duck';
import classNames from 'classnames';
import { trimDisplayNameLastWord } from '../../util/text';
import { ConditionalWrapper } from '../../util/common';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';

const { userTypeRider, userTypeHorseowner } = config;

const findTxWithSameUsers = ({ currentUserId, otherPartyId }) => tx =>
    (tx.customerId === currentUserId || tx.customerId === otherPartyId) &&
    (tx.providerId === currentUserId || tx.providerId === otherPartyId);

export default ({ transaction, listing }) => {
    const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
    const [riderNotAvailableStatus, setRiderNotAvailableStatus] = useState(null);

    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux store data */
    const allListings = useSelector(s => s.marketplaceData.entities.listing);
    const activeTransactionsData = useSelector(s => s.ProfilePage.activeTransactionsData);
    const activeTxDataInProgress = useSelector(s => s.ProfilePage.activeTransactionsDataInProgress);
    const currentUser = useSelector(state => state.user.currentUser);

    useEffect(() => {
        dispatch(fetchActiveTransactions());
    }, []);

    const otherParty = resolveOtherPartyData(
        currentUser,
        transaction.customer,
        transaction.provider
    );

    const { userType } = currentUser.attributes.profile.publicData;
    const { listingSubstitutionId } = transaction.attributes.protectedData;
    const {
        displayName: otherPartyDisplayName,
        publicData: { availabilityStatus: otherPartyAvailabilityStatus },
    } = otherParty.attributes.profile;

    const isRider = userType === userTypeRider;
    const isHorseowner = userType === userTypeHorseowner;
    const isRiderListing = listing.attributes.publicData.type === 'listing-rider';
    const currentUserId = currentUser.id.uuid;
    const otherPartyId = otherParty.id.uuid;
    const otherPartyName = trimDisplayNameLastWord(otherPartyDisplayName);
    const otherPartyIsNotAvailable =
        otherPartyAvailabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE;
    /** get only those txs where both parties are the same as it is for the current tx */
    const currentPartiesTransactions = activeTransactionsData.filter(
        findTxWithSameUsers({ currentUserId, otherPartyId })
    );

    const { state } = getActionButtonStateFromTx(currentPartiesTransactions);
    /** check for accepted or enquired transactions */
    const activeTxExists =
        state === TRANSACTION_STATES_STATUS_ACCEPTED ||
        state === TRANSACTION_STATES_STATUS_ENQUIRED;

    const navigate = id =>
        id && history.push(createRLS('OrderDetailsPage', routeConfiguration(), { id }, {}));

    const navigateToActiveTx = () => {
        const activeTransaction = activeTransactionsData.find(
            findTxWithSameUsers({ currentUserId, otherPartyId })
        );

        if (activeTransaction) {
            navigate(activeTransaction?.id?.uuid);
        }
    };

    const openRiderAvailabilityDialog = code => {
        setEnquiryModalOpen(false);
        setRiderNotAvailableStatus(code);
    };

    const handleInquiryError = code => {
        if (
            code === ERROR_ACTIVE_TX_ALREADY_EXISTS ||
            code === ERROR_NO_AVAILABLE_LISTINGS_FOR_NEW_TX
        ) {
            return navigateToActiveTx();
        }

        if (
            code === ERROR_INQUIRY_DISABLED_FOR_RIDER_NOT_AVAILABLE ||
            code === ERROR_INQUIRY_DISABLED_FOR_RIDER_PROFILE_INCOMPLETE
        ) {
            return openRiderAvailabilityDialog(code);
        }
    };

    const handleIntitateBtnClick = () => {
        const latestTransaction = activeTransactionsData.find(
            tx =>
                (tx.customerId === currentUserId || tx.customerId === otherPartyId) &&
                (tx.providerId === currentUserId || tx.providerId === otherPartyId)
        );

        if (activeTxExists && latestTransaction) {
            navigate(latestTransaction.id.uuid);
        } else {
            setEnquiryModalOpen(true);
        }
    };

    const resolveInitialListing = () => {
        if (isRider && isRiderListing) {
            /**
             * if a tx has been initiated by a horseowner,
             * and a rider wants to re-initiate a new tx,
             * the listing is a rider itself,
             * so we need to replace it with listingSubstitutionId
             */
            return allListings[listingSubstitutionId];
        }
        if (isRider) {
            /** if it's a rider's request, initialListing is a specific horse */
            return listing;
        }
        return null;
    };

    const resolveInquiryNotAllowedKeyForRider = () => {
        return 'noPublishedListingsForRider';
    };

    const resolveInquiryNotAllowedKeyForHorseowner = () => {
        if (otherPartyIsNotAvailable) return 'inquiryToRiderIsNotAllowedForHorseowner';
        return 'noPublishedListingsForHorseowner';
    };

    const resolveInquiryNotAllowedKey = () => {
        if (isRider) return resolveInquiryNotAllowedKeyForRider();
        if (isHorseowner) return resolveInquiryNotAllowedKeyForHorseowner();
    };

    const { state: listingState } = listing?.attributes || {};
    const isPublished = listingState === 'published';
    const reinitiateDisabled = activeTxExists ? false : !isPublished || otherPartyIsNotAvailable;
    const initialListing = resolveInitialListing();

    return (
        <>
            {activeTxDataInProgress ? (
                <IconSpinner />
            ) : (
                <div className={css.root}>
                    <ConditionalWrapper
                        condition={reinitiateDisabled}
                        wrapper={children => (
                            <Tooltip
                                position="top"
                                tooltipClassName={css.inquiryDisabledTooltip}
                                content={
                                    <>
                                        <p>Anfragen nicht möglich</p>
                                        <p>
                                            <FormattedMessage
                                                id={`Inquiry.action.disabled.desc.${resolveInquiryNotAllowedKey()}`}
                                                values={{ userName: otherPartyName }}
                                            />
                                        </p>
                                    </>
                                }
                            >
                                {children}
                            </Tooltip>
                        )}
                    >
                        <Button
                            type="button"
                            data-role="reinitiate-transaction"
                            disabled={reinitiateDisabled}
                            onClick={handleIntitateBtnClick}
                            className={classNames(css.button, {
                                [css.accepted]: !!activeTxExists,
                            })}
                        >
                            {activeTxExists ? 'Zur Konversation' : 'Neue Anfrage senden'}
                        </Button>
                    </ConditionalWrapper>
                </div>
            )}

            {enquiryModalOpen && (
                <InquiryModal
                    isOpen
                    onClose={() => setEnquiryModalOpen(false)}
                    publicUser={otherParty}
                    initialListing={initialListing}
                    notifyOnError={handleInquiryError}
                    ridingListingAuthorId={isRider ? currentUserId : otherPartyId}
                    onSendEnquiry={async values => await dispatch(sendEnquiry(values))}
                />
            )}
            {!!riderNotAvailableStatus && (
                <RiderNotAvailableModal
                    isOpen
                    otherPartyName={otherPartyName}
                    riderNotAvailableStatus={riderNotAvailableStatus}
                    onClose={() => setRiderNotAvailableStatus(null)}
                    notifyOnAvailabilityChange={() => {
                        setRiderNotAvailableStatus(null);
                    }}
                />
            )}
        </>
    );
};
