import {
    ERROR_ACTIVE_TX_ALREADY_EXISTS,
    ERROR_NO_AVAILABLE_LISTINGS_FOR_NEW_TX,
} from '../../components/InquiryModal/InquiryModal.helpers';
import routeConfiguration from '../../routeConfiguration';
import { getUserLocationUnitStr } from '../../util/location';
import { getPlaceDetailsGeoData } from '../../util/geocoder';
import { createResourceLocatorString } from '../../util/routes';

export const navigateTo = (id, navRef, callback) => {
    const element = document.querySelector(id);

    if (!element) {
        return;
    }
    const topBarElem = document.querySelector('[data-role="top-bar"]');

    const topBarHeight = topBarElem ? topBarElem.getBoundingClientRect().height : 72;
    const navHeight = navRef && navRef.current ? navRef.current.getBoundingClientRect().height : 51;

    const { bottom, height } = element.getBoundingClientRect();

    window.scrollTo({
        top: bottom - height - topBarHeight - navHeight + window.scrollY,
        behavior: 'smooth',
    });

    typeof callback === 'function' && callback(id);
};

export const searchForNearestListings = async (history, params) => {
    try {
        const locationUnitsStr = getUserLocationUnitStr({
            ...params,
        });

        const predictionData = await getPlaceDetailsGeoData({ locationUnitsStr });

        if (!predictionData) {
            throw new Error();
        }

        history.push(
            createResourceLocatorString(
                'SearchPage',
                routeConfiguration(),
                {},
                {
                    address: predictionData.address,
                    bounds: predictionData.bounds,
                    origin: predictionData.origin,
                }
            )
        );
    } catch (e) {
        history.push(createResourceLocatorString('SearchPage', routeConfiguration()));
    }
};

export const addIncludedDataToListing = (listingsData, includedData = []) =>
    listingsData.map(l => ({
        ...l,
        images: includedData.filter(
            ({ type, id: { uuid } }) =>
                type === 'image' &&
                l.relationships.images.data.some(image => image.id.uuid === uuid)
        ),
    }));

export const navigateToLatestTx = (code, history, latestTransactionId) => {
    if (
        !latestTransactionId ||
        (code !== ERROR_ACTIVE_TX_ALREADY_EXISTS && code !== ERROR_NO_AVAILABLE_LISTINGS_FOR_NEW_TX)
    )
        return;

    history.push(
        createResourceLocatorString(
            'OrderDetailsPage',
            routeConfiguration(),
            { id: latestTransactionId },
            {}
        )
    );
};
