import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { stringify } from '../../../../util/urlHelpers';
import { FormattedMessage, injectIntl } from '../../../../util/reactIntl';
import { NamedLink, IconSpinner } from '../../../../components';
import TopbarDesktopSearchPanel from '../../../../components/TopbarDesktop/TopbarDesktopSearchPanel';
import config from '../../../../config';
import { redirectToURLWithModalState } from '../../../../components/Topbar/Topbar.helpers';
import { TopbarLocationSearchForm } from '../../../../forms';
import { requestCurrentLocation } from '../../../../ducks/location.duck';
import {
    encodeBoundsToStr,
    encodeOriginToStr,
} from '../../../../components/TopbarDesktop/TopbarDesktopSearchPanel.helpers';

import SectionHeroHeader from './SectionHeroHeader';
import css from './SectionHero.css';

const { listingTypeHorse, userTypeRider, userTypeHorseowner, listingTypeRider } = config;

const userToListingTypeMapper = {
    [userTypeRider]: listingTypeHorse,
    [userTypeHorseowner]: listingTypeRider,
};

const SectionHero = ({
    intl,
    userType,
    type,
    computing,
    isMobile,
    location,
    history,
    currentUser,
    hideSearhBarForNoAuth = false,
}) => {
    const dispatch = useDispatch();
    /** Redux store data */
    const currentLocation = useSelector(s => s.location.currentLocation);
    const loading = useSelector(s => s.location.currentLocationRequestInProgress);

    const [initialValues, setInitialValues] = useState(undefined);

    const initializeRedirectParams = () => {
        if (!initialValues || !initialValues.location || !initialValues.location.selectedPlace)
            return {};

        return {
            search: stringify({
                address: initialValues.location.search,
                bounds: encodeBoundsToStr(initialValues.location.selectedPlace.bounds),
                origin: encodeOriginToStr(initialValues.location.selectedPlace.origin),
                distance: initialValues.optionDistance,
                listingType,
            }),
        };
    };

    const currentUserDefined = !!userType && currentUser && currentUser.id;
    const isHorseOwner = userType === userTypeHorseowner;

    const horseownerActionLink = !isHorseOwner ? null : (
        <p className={css.horseownerActionLink}>
            <FormattedMessage
                id="SectionHero.createYourListingForFree"
                values={{
                    createListingLink: (
                        <NamedLink name="NewListingPage">
                            <FormattedMessage id="SectionHero.createListingLink" />
                        </NamedLink>
                    ),
                }}
            />
        </p>
    );

    const inProgress = computing || loading;
    const showSearchBar = !inProgress && (hideSearhBarForNoAuth ? currentUserDefined : true);

    const listingType = type || userToListingTypeMapper[userType] || 'horse';

    const { city, postalCode, mobility } = currentUser.attributes.profile.publicData;
    const { radius } = mobility || { radius: '15 km' };
    const { address } = currentUserDefined
        ? {
              address: `${postalCode} ${city}`,
          }
        : {};

    useEffect(() => {
        dispatch(requestCurrentLocation());
    }, []);

    useEffect(() => {
        if (!currentLocation) return;

        setInitialValues({
            optionDistance: radius,
            location: {
                search: address,
                selectedPlace: { ...currentLocation },
            },
        });
    }, [currentLocation]);

    return (
        <section className={css.root}>
            <div className={css.container}>
                <SectionHeroHeader userType={userType} />

                {inProgress && <IconSpinner />}

                {isMobile && showSearchBar && (
                    <>
                        <TopbarLocationSearchForm
                            rootClassName={css.mobLocationForm}
                            listingTypeTab={isHorseOwner ? listingTypeRider : listingTypeHorse}
                            initialValues={
                                initialValues && {
                                    distance: initialValues.optionDistance,
                                    address: initialValues.location.search,
                                }
                            }
                            onTabPanelToggle={() =>
                                redirectToURLWithModalState(
                                    {
                                        location: {
                                            ...location,
                                            ...initializeRedirectParams(),
                                        },
                                        history,
                                    },
                                    'mobilesearch'
                                )
                            }
                        />

                        {horseownerActionLink}
                    </>
                )}
                {!isMobile && showSearchBar && (
                    <div>
                        <TopbarDesktopSearchPanel
                            intl={intl}
                            listingTypeTab={isHorseOwner ? listingTypeRider : listingTypeHorse}
                            rootClassName={css.sectionHeroSearchPanel}
                            initialValues={initialValues}
                        />
                        {horseownerActionLink}
                    </div>
                )}
            </div>
        </section>
    );
};

export default compose(withRouter, injectIntl)(SectionHero);
