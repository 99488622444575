import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { EditPencilIcon, IconEyeL } from '../../icons';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import css from './ProfileSettingsForm.css';

import EditUserInfoModal, { EDIT_SECTIONS } from './EditUserInfoModal';

import ChecklistSection from './sections/ChecklistSection';
import EditUserMainInfoSection from './sections/EditUserMainInfoSection';
import TrainingsSection from './sections/TrainingsSection';
import AwardsSection from './sections/AwardsSection';
import RecommendationsSection from './sections/RecommendationsSection';
import SkillsSection from './sections/SkillsSection';
import QualificationSection from './sections/QualificationSection';
import DisciplinesSection from './sections/DisciplinesSection';
import GallerySection from './sections/GallerySection';
import BioSection from './sections/BioSection';
import AvailabilitySection from './sections/AvailabilitySection';
import InterestsSection from './sections/InterestsSection';
import VerificationSection from './sections/VerificationSection';
import LanguagesSection from './sections/LanguagesSection';
import LocationAndMobilitySection from './sections/LocationAndMobilitySection';
import { isProfileComplete } from '../../util/user';
import { NamedLink, ReportProfile, PreviewDocModal } from '../../components';
import NavigationBar from './NavigationBar';
import config from '../../config';

import ListingsSection from './sections/ListingsSection';
import MatchingRateSection from './sections/MatchingRateSection';
import { useListings } from '../../hooks/useListings';
import useBestMatchingListing from '../../hooks/useBestMatchingListing';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';
import { useIsMounted } from '../../hooks/useIsMounted';
import { parse } from '../../util/urlHelpers';
import { setLastSeenListings } from '../../util/localStorage';
import { USER_AVAILABILITY } from './EditUserInfoModalSectionConstants';
import StepsToBecomeAvailableAlert from './StepsToBecomeAvailableAlert';
import UserTipsCarousel from './UserTipsCarousel';

const { userTypeRider, userTypeHorseowner } = config;

const ProfileSettingsForm = props => {
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
    const [previewDoc, setPreviewDoc] = useState(null);
    const [editSectionName, setEditSectionName] = useState(null);
    const [modalParams, setModalParams] = useState(null);
    const [removeParamsOnModalClose, setRemoveParamsOnModalClose] = useState(false);
    const appIsMounted = useIsMounted();

    const {
        initialValues,
        user,
        publicUser,
        currentUser,
        isSameUser,
        uploadDocumentsInProgress,
        userDocuments,
        isPublic,
        onDocsListRequest,
        onUpdateProfile,
        updateInProgress,
        onRemoveSingleDocRequest,
        onUploadSingleDocRequest,
        userDocErrorMetadata,
        externalReviewsErrors,
        externalReviewsRequests,
        externalReviewsData,
        isLoggedOut,
        onPromptUserBio,
        promptUserBioInProgress,
        promptUserBioError,
        promptUserBio,
        inquiryDisabled,
        history,
        location,
        intl,
        params,
        isMobile,
        showNavBar,
    } = props;
    const { availabilityStatusChange } = parse(location.search);
    const { attributes, id } = user;
    const { profile, emailVerified: emailVerifiedFromAttr } = attributes;
    const { publicData, bio, displayName } = profile;
    const {
        phoneVerified,
        city,
        postalCode,
        availability,
        openForPartTimeProposals,
        mobility,
        interest,
        languages,
        riderQualification,
        riderCertification,
        skills,
        awards,
        trainings,
        emailVerified: emailVerifiedFromPD,
        availabilityStatus,
        userType,
    } = publicData;

    const isRider = userType === userTypeRider;
    const isOwner = userType === userTypeHorseowner;
    const emailVerified = emailVerifiedFromAttr || emailVerifiedFromPD;
    const { isPrimaryFieldsComplete } = isProfileComplete(user);

    const {
        attributes: {
            profile: {
                publicData: { userType: currentUserType, mainHorseId },
            },
        },
    } = currentUser;

    const { userType: pageUserType } = params || {};
    const isRiderPage = pageUserType === userTypeRider;
    const currentUserIsOwner = currentUserType === userTypeHorseowner;
    const currentUserIsRider = currentUserType === userTypeRider;
    const currentUserId = !isLoggedOut && currentUser ? currentUser.id.uuid : null;
    const userId = !isLoggedOut && id ? id.uuid : null;
    const publicUserId = isPublic && id ? id.uuid : null;

    const [userName] = (displayName || '').split(' ');

    const handleEditSection = sectionName => {
        if (EDIT_SECTIONS[sectionName]) {
            setEditSectionName(sectionName);
            setEditModalOpen(true);
        }
    };

    const renderEditPencilIcon = (sectionName, className, children = null, props = {}) =>
        isPublic ? null : (
            <code
                className={css.editPencilIcon}
                onClick={() => handleEditSection(sectionName)}
                {...props}
            >
                <EditPencilIcon rootClassName={className} />
                {children}
            </code>
        );

    useEffect(() => {
        /**
         * open edit avail.status modal in
         * case e.g. if a user is redirected
         * to his profile from email
         */
        if (!availabilityStatusChange) return;
        setModalParams({
            isConfirm: true,
        });
        handleEditSection(USER_AVAILABILITY);
        setRemoveParamsOnModalClose(true);
    }, [availabilityStatusChange]);

    useEffect(() => {
        onDocsListRequest(user.id.uuid);
    }, [user.id.uuid]);
    /**
     * isRider && currentUserIsOwner - public user is rider & current is owner
     * !isRider && currentUserIsRider - public user is owner & current is rider
     * isLoggedOut - use id from url
     */
    const authorId =
        isRider && currentUserIsOwner
            ? userId
            : !isRider && currentUserIsRider
            ? currentUserId
            : isLoggedOut
            ? publicUserId
            : null;

    const [riderListings] = useListings({
        params: { authorId },
        allowed:
            authorId &&
            !isSameUser &&
            !(isRider && currentUserIsRider) &&
            !(isOwner && currentUserIsOwner),
    });

    const riderListing = Array.isArray(riderListings)
        ? riderListings.find(
              ({
                  attributes: {
                      publicData: { userRepresentationId },
                  },
              }) => !!userRepresentationId
          )
        : null;

    const [scoreReqInProgress, score] = useBestMatchingListing({
        riderListing,
        /**
         * currentUser has to be an owner
         */
        ownerId: isRiderPage && currentUserIsOwner && !isSameUser ? currentUserId : null,
        mainHorseId,
    });

    const scoreData = isLoggedOut ? null : score;
    const recommendations = externalReviewsData && externalReviewsData[user.id.uuid];
    const riderListingId = riderListing && riderListing.id && riderListing.id.uuid;

    useEffect(() => {
        riderListingId && setLastSeenListings(riderListingId);
    }, [riderListingId]);

    return (
        <FinalForm
            {...props}
            initialValues={{
                ...initialValues,
            }}
            render={fieldRenderProps => {
                const {
                    className,
                    user,
                    onImageUpload,
                    profileImage,
                    rootClassName,
                    uploadImageError,
                    uploadInProgress,
                    form,
                } = fieldRenderProps;

                const {
                    attributes: {
                        profile: {
                            publicData: { userType },
                        },
                    },
                } = user;

                const showAlert =
                    (!isPrimaryFieldsComplete ||
                        availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE) &&
                    !isPublic &&
                    isRider;

                const logoutCommonProps = {
                    pageParams: params,
                    userName,
                    isLoggedOut,
                    switchUserRoleAction:
                        appIsMounted &&
                        !isLoggedOut &&
                        ((!isSameUser && isRider && currentUserType === userTypeRider) ||
                            (!isSameUser && !isRider && currentUserType === userTypeHorseowner)),
                };

                const checklistSection = !isPublic && isRider && (
                    <ChecklistSection
                        currentUser={user}
                        userDocuments={userDocuments}
                        uploadDocumentsInProgress={uploadDocumentsInProgress}
                        onUpdateProfile={onUpdateProfile}
                    />
                );

                const matchingRateSection = isPublic && (
                    <MatchingRateSection
                        currentUser={currentUser}
                        user={user}
                        isLoggedOut={isLoggedOut}
                        isRiderPage={isRider}
                        isSameUser={isSameUser}
                        listingToRedirect={isRider ? riderListing : null}
                        scoreReqInProgress={scoreReqInProgress}
                        scoreData={scoreData}
                        createdAtDesc="ProfileSettingsForm.userCreatedAt"
                        dateFormatConfig={{ year: 'numeric' }}
                    />
                );

                const locationAndMobilitySection = (
                    <LocationAndMobilitySection
                        renderEditPencilIcon={renderEditPencilIcon}
                        mobility={mobility}
                        city={city}
                        postalCode={postalCode}
                        isPublic={isPublic}
                        handleEditSection={handleEditSection}
                        userType={userType}
                        setModalParams={setModalParams}
                        scoreData={scoreData}
                        scoreReqInProgress={scoreReqInProgress}
                    />
                );

                const availabilitySection = isRider && (
                    <AvailabilitySection
                        availability={availability}
                        renderEditPencilIcon={renderEditPencilIcon}
                        openForPartTimeProposals={openForPartTimeProposals}
                        isPublic={isPublic}
                        handleEditSection={handleEditSection}
                        scoreData={scoreData}
                        scoreReqInProgress={scoreReqInProgress}
                    />
                );

                const interestsSection = isRider && (
                    <InterestsSection
                        renderEditPencilIcon={renderEditPencilIcon}
                        interest={interest}
                        isPublic={isPublic}
                        handleEditSection={handleEditSection}
                        scoreData={scoreData}
                        scoreReqInProgress={scoreReqInProgress}
                    />
                );

                const verificationSection = (
                    <VerificationSection
                        renderEditPencilIcon={renderEditPencilIcon}
                        phoneVerified={phoneVerified}
                        emailVerified={emailVerified}
                        isPublic={isPublic}
                    />
                );

                const languagesSection = (
                    <LanguagesSection
                        languages={languages}
                        renderEditPencilIcon={renderEditPencilIcon}
                        handleEditSection={handleEditSection}
                        isPublic={isPublic}
                    />
                );

                const bioSection = (
                    <BioSection
                        bio={bio}
                        isPublic={isPublic}
                        renderEditPencilIcon={renderEditPencilIcon}
                        handleEditSection={handleEditSection}
                        isRider={isRider}
                        displayName={displayName}
                        {...logoutCommonProps}
                    />
                );

                const listingSection = isPublic && !isRider && (
                    <ListingsSection user={user} currentUser={currentUser} />
                );

                const gallerySection = isRider && (
                    <GallerySection
                        renderEditPencilIcon={renderEditPencilIcon}
                        rootClassName={rootClassName}
                        uploadDocumentsInProgress={uploadDocumentsInProgress}
                        userDocuments={userDocuments}
                        className={className}
                        isPublic={isPublic}
                        userType={userType}
                    />
                );

                const disciplinesSection = isRider && (
                    <DisciplinesSection
                        renderEditPencilIcon={renderEditPencilIcon}
                        handleEditSection={handleEditSection}
                        publicData={publicData}
                        isPublic={isPublic}
                        scoreData={scoreData}
                        scoreReqInProgress={scoreReqInProgress}
                        {...logoutCommonProps}
                    />
                );

                const qualificationSection = isRider && (
                    <QualificationSection
                        qualifications={riderQualification}
                        certifications={riderCertification}
                        isPublic={isPublic}
                        renderEditPencilIcon={renderEditPencilIcon}
                        uploadDocumentsInProgress={uploadDocumentsInProgress}
                        userDocuments={userDocuments}
                        setModalParams={setModalParams}
                        handleEditSection={handleEditSection}
                        setPreviewModalOpen={setPreviewModalOpen}
                        setPreviewDoc={setPreviewDoc}
                        scoreData={scoreData}
                        scoreReqInProgress={scoreReqInProgress}
                        {...logoutCommonProps}
                    />
                );

                const skillsSection = isRider && (
                    <SkillsSection
                        renderEditPencilIcon={renderEditPencilIcon}
                        skills={skills}
                        isPublic={isPublic}
                        handleEditSection={handleEditSection}
                        scoreData={scoreData}
                        scoreReqInProgress={scoreReqInProgress}
                        {...logoutCommonProps}
                    />
                );

                const recommendationsSection = isRider && (
                    <RecommendationsSection
                        isPublic={isPublic}
                        currentUser={user}
                        externalReviewsErrors={externalReviewsErrors}
                        externalReviewsRequests={externalReviewsRequests}
                        recommendations={recommendations}
                        isSameUser={isSameUser}
                        intl={intl}
                        {...logoutCommonProps}
                    />
                );

                const awardsSection = isRider && (
                    <AwardsSection
                        awards={awards}
                        renderEditPencilIcon={renderEditPencilIcon}
                        setModalParams={setModalParams}
                        handleEditSection={handleEditSection}
                        userDocuments={userDocuments}
                        uploadDocumentsInProgress={uploadDocumentsInProgress}
                        isPublic={isPublic}
                        setPreviewModalOpen={setPreviewModalOpen}
                        setPreviewDoc={setPreviewDoc}
                        {...logoutCommonProps}
                    />
                );

                const trainingsSection = isRider && (
                    <TrainingsSection
                        trainings={trainings}
                        renderEditPencilIcon={renderEditPencilIcon}
                        setModalParams={setModalParams}
                        handleEditSection={handleEditSection}
                        userDocuments={userDocuments}
                        uploadDocumentsInProgress={uploadDocumentsInProgress}
                        isPublic={isPublic}
                        setPreviewModalOpen={setPreviewModalOpen}
                        setPreviewDoc={setPreviewDoc}
                        {...logoutCommonProps}
                    />
                );

                const reportProfile = (
                    <ReportProfile
                        user={publicUser}
                        initialValues={{
                            firstName: user.attributes.profile.displayName,
                            userEmail: '-', // no email for public user
                            profileLink: `profile/${userType}/public/${id.uuid}`,
                            type: 'profile',
                        }}
                    />
                );

                return (
                    <>
                        {user.id && !isPublic ? (
                            <NamedLink
                                className={css.profileLink}
                                name="ProfilePagePreview"
                                params={{ id: user.id.uuid, userType }}
                            >
                                <IconEyeL />
                                <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                            </NamedLink>
                        ) : null}
                        {!isPublic && <UserTipsCarousel />}
                        {showAlert && (
                            <StepsToBecomeAvailableAlert
                                user={user}
                                handleEditSection={handleEditSection}
                                setModalParams={setModalParams}
                            />
                        )}
                        {showNavBar && isRider && (
                            <NavigationBar
                                user={user}
                                recommendations={externalReviewsData || []}
                                userDocuments={userDocuments}
                                currentUser={currentUser}
                            />
                        )}
                        {isEditModalOpen && (
                            <EditUserInfoModal
                                uploadDocumentsInProgress={uploadDocumentsInProgress}
                                onRemoveSingleDocRequest={onRemoveSingleDocRequest}
                                onUploadSingleDocRequest={onUploadSingleDocRequest}
                                userDocErrorMetadata={userDocErrorMetadata}
                                userDocuments={userDocuments}
                                isOpen={isEditModalOpen}
                                editSectionName={editSectionName}
                                user={user}
                                currentUser={currentUser}
                                onUpdateProfile={onUpdateProfile}
                                onDocsListRequest={() => onDocsListRequest(user.id.uuid)}
                                updateInProgress={updateInProgress}
                                modalParams={modalParams}
                                onPromptUserBio={onPromptUserBio}
                                promptUserBioInProgress={promptUserBioInProgress}
                                promptUserBioError={promptUserBioError}
                                promptUserBio={promptUserBio}
                                setModalParams={setModalParams}
                                onClose={() => {
                                    setEditModalOpen(false);
                                    setEditSectionName(null);
                                    setModalParams(null);

                                    if (removeParamsOnModalClose) {
                                        setRemoveParamsOnModalClose(false);
                                        history.replace(
                                            createResourceLocatorString(
                                                'ProfileSettingsPage',
                                                routeConfiguration(),
                                                { userType: userTypeRider },
                                                {}
                                            )
                                        );
                                    }
                                }}
                            />
                        )}
                        <EditUserMainInfoSection
                            form={form}
                            isSameUser={isSameUser}
                            user={user}
                            currentUser={currentUser}
                            uploadInProgress={uploadInProgress}
                            isPublic={isPublic}
                            riderPage={isRider}
                            onImageUpload={onImageUpload}
                            renderEditPencilIcon={renderEditPencilIcon}
                            image={profileImage}
                            uploadImageError={uploadImageError}
                            onUpdateProfile={onUpdateProfile}
                            inquiryDisabled={inquiryDisabled}
                            intl={intl}
                            recommendations={recommendations}
                            externalReviewsRequests={externalReviewsRequests}
                            handleEditSection={handleEditSection}
                            riderListing={riderListing}
                            scoreData={scoreData}
                            isLoggedOut={isLoggedOut}
                            transactionDataInProgress={props.activeTransactionsDataInProgress}
                        />
                        {!isMobile ? (
                            <section className={css.mainInfoSectionDesktop}>
                                <aside className={css.asideMainInfo}>
                                    {checklistSection}
                                    {matchingRateSection}
                                    {locationAndMobilitySection}
                                    {availabilitySection}
                                    {interestsSection}
                                    {verificationSection}
                                    {languagesSection}
                                    {isPublic && !isSameUser && reportProfile}
                                </aside>
                                <main className={css.mainInfo}>
                                    {bioSection}
                                    {listingSection}
                                    {gallerySection}
                                    {disciplinesSection}
                                    {qualificationSection}
                                    {skillsSection}
                                    {recommendationsSection}
                                    {awardsSection}
                                    {trainingsSection}
                                </main>
                            </section>
                        ) : (
                            <section className={css.mainInfoSectionMob}>
                                {checklistSection}
                                {matchingRateSection}
                                {locationAndMobilitySection}
                                {availabilitySection}
                                {interestsSection}
                                {bioSection}
                                {listingSection}
                                {gallerySection}
                                {disciplinesSection}
                                {qualificationSection}
                                {skillsSection}
                                {recommendationsSection}
                                {awardsSection}
                                {trainingsSection}
                                {languagesSection}
                                {verificationSection}
                                {isPublic && !isSameUser && reportProfile}
                            </section>
                        )}

                        {isPreviewModalOpen && previewDoc && (
                            <PreviewDocModal
                                isOpen
                                previewDoc={previewDoc}
                                onClose={() => {
                                    setPreviewModalOpen(false);
                                    setPreviewDoc(null);
                                }}
                            />
                        )}
                    </>
                );
            }}
        />
    );
};

export default compose(injectIntl, withRouter)(ProfileSettingsForm);
