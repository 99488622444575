import config from '../config';
import { getCurrentUserLocation, getUserCurrentLocationSP } from './localStorage';
import Geocoder from '../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import { excludeTypes as excludeTypesCommon } from '../components/LocationAutocompleteInput/LocationAutocompleteInputImpl.helper';
import { ultimateGoogleMapsCountriesConfig } from './googleMaps';

const { defaultCountry, maps } = config;
const { supportedCountries: SC, supportedCountriesMapper } = maps;

export const exctractPublicDataCountry = currentUser => {
    const {
        attributes: {
            profile: { publicData },
        },
    } = currentUser || { attributes: { profile: { publicData: {} } } };
    const { country: publicDataCountry = null } = publicData || {};

    return publicDataCountry;
};

export const getUserCountry = () => {
    const { country: ipAddressCountry, countrySelected } = getCurrentUserLocation();
    const countryMapped =
        supportedCountriesMapper[countrySelected] || supportedCountriesMapper[ipAddressCountry];

    return (
        countryMapped ||
        (countrySelected && SC[countrySelected] ? countrySelected : null) ||
        (ipAddressCountry && SC[ipAddressCountry] ? ipAddressCountry : null) ||
        defaultCountry
    );
};

export const getUserCountryName = () => SC[getUserCountry()].name;

export const getUserAddress = () => {
    const { defaultAddress } = SC[getUserCountry()];
    const { bounds, origin, address } = defaultAddress;
    return { bounds, origin, address };
};

export const isSupportedCountry = (country, config) =>
    country && (config || SC)[country] ? country : defaultCountry;

export const getUserCountrySpecInfo = (country, config = null) =>
    (config || SC)[isSupportedCountry(country, config)];

/**
 * Resolves location string from user public data values
 * the order of units has to be the same as google api address from response
 * @returns string
 */
export const getUserLocationUnitStr = ({
    intl,
    country = '',
    postalCode = '',
    city = '',
    streetAddress = '',
    houseNumber = '',
}) =>
    `${streetAddress} ${houseNumber}, ${postalCode} ${city}, ${
        country
            ? intl.formatMessage({
                  id: `PayoutDetailsForm.countryNames.${country}`,
              })
            : ''
    } `.trim();
/**
 * Check if search params match with current user local storage search params
 * @param {object} searchParams - origin, address
 * @returns
 */
export const userLocationSearchParamsMatch = searchParams => {
    const currentLocationLS = getUserCurrentLocationSP() || {};
    const { address, origin } = searchParams;
    const { address: lsAddress, origin: lsOrigin } = currentLocationLS;

    const addressMatch = address === lsAddress;
    const originMatch =
        origin && lsOrigin && origin.lat === lsOrigin.lat && origin.lng === lsOrigin.lng;

    return addressMatch && originMatch;
};
