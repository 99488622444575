import { createListingFromTempData, provideSignupAnalytics } from '../../ducks/Auth.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { checkMarketplaceCurrentUser } from '../../util/data';
import { calculateAge } from '../../util/user';
import { fetchCurrentUser, updateUserProfileInfo } from '../../ducks/user.duck';
import {
    commonDisciplines,
    PART_TIME_OPTION_FULL,
    PART_TIME_OPTION_INDEF,
    PART_TIME_OPTION_TEMP,
} from '../../marketplace-custom-config';
import config from '../../config';
import { sendSGEmail } from '../../util/api';
import { getUserCountry } from '../../util/location';
import { getPlaceDetailsGeoData } from '../../util/geocoder';
import { excludeTypes } from '../../components/LocationAutocompleteInput/LocationAutocompleteInputImpl.helper';
import { stringifyToMarketplaceISO } from '../../util/dates';

const {
    userTypeRider,
    listingTypeHorse,
    listingTypeRider,
    canonicalRootURL,
    maps: { supportedCountries },
} = config;

export const DISCARD_ERRORS = 'app/OnboardingPage/DISCARD_ERRORS';

export const PROMPT_USER_BIO_REQUEST = 'app/OnboardingPage/PROMPT_USER_BIO_REQUEST';
export const PROMPT_USER_BIO_SUCCESS = 'app/OnboardingPage/PROMPT_USER_BIO_SUCCESS';
export const PROMPT_USER_BIO_FAILED = 'app/OnboardingPage/PROMPT_USER_BIO_FAILED';

const initialState = {
    promptUserBioInProgress: false,
    promptUserBioError: null,
    promptUserBio: null,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case PROMPT_USER_BIO_REQUEST:
            return {
                ...state,
                promptUserBioError: null,
                promptUserBio: null,
                promptUserBioInProgress: true,
            };
        case PROMPT_USER_BIO_SUCCESS:
            return {
                ...state,
                promptUserBioError: null,
                promptUserBioInProgress: false,
                promptUserBio: payload,
            };
        case PROMPT_USER_BIO_FAILED:
            return {
                ...state,
                promptUserBioInProgress: false,
                promptUserBioError: payload,
                promptUserBio: null,
            };
        case DISCARD_ERRORS:
            return {
                ...state,
                promptUserBioError: null,
            };
        default:
            return state;
    }
}

export const promptUserBioRequest = () => ({
    type: PROMPT_USER_BIO_REQUEST,
});
export const promptUserBioSuccess = data => ({
    type: PROMPT_USER_BIO_SUCCESS,
    payload: data,
});
export const promptUserBioError = error => ({
    type: PROMPT_USER_BIO_FAILED,
    payload: error,
});

export const discardErrors = () => ({
    type: DISCARD_ERRORS,
});

export const promptUserBio = () => async (dispatch, getState) => {
    const state = getState();

    const {
        attributes: { profile },
    } = checkMarketplaceCurrentUser(state);
    const {
        firstName,
        publicData: {
            userType,
            birthDate,
            postalCode,
            city,
            interest,
            availability: pbAvailability,
            openForPartTimeProposals,
            desiredDisciplines,
            riderQualification,
        },
    } = profile;

    const availabilityConfig = {
        flexible: 'Ich bin flexibel',
        [PART_TIME_OPTION_INDEF]: 'Suche etwas Unbefristetes',
        [PART_TIME_OPTION_TEMP]: 'Suche etwas Befristetes',
        [PART_TIME_OPTION_FULL]: 'Ich bin offen',
    };
    const openForPTPConfig = {
        [PART_TIME_OPTION_INDEF]: 'Etwas Unbefristetes',
        [PART_TIME_OPTION_TEMP]: 'Etwas Befristetes',
        [PART_TIME_OPTION_FULL]: 'Etwas Unbefristetes oder etwas Befristetes',
    };
    const role = userType === 'rider' ? 'Reiter' : 'Pferdebesitzer';
    const availability = availabilityConfig[pbAvailability] || `${pbAvailability} pro Woche`;

    dispatch(promptUserBioRequest());

    try {
        const prompt = `Erstelle mir einen Profiltext aus den folgenden Informationen:
    Name: ${firstName}
    Rolle: ${role}
    Alter: ${calculateAge(birthDate)} Jahre
    Suche in: ${postalCode} ${city}
    Interessen: ${(interest || []).join(', ')}
    Verfügbarkeit: ${availability}
    Offen für: ${openForPTPConfig[openForPartTimeProposals]}
    Disziplinen: ${(desiredDisciplines || [])
        .map(
            disciplineKey =>
                (commonDisciplines.find(({ key }) => key === disciplineKey) || {}).label ||
                disciplineKey
        )
        .join(', ')}
    Qualifikationen: ${(riderQualification || []).join(', ')}
    Füge hinzu: Für mehr Informationen, schaue gerne auf meinem Profil vorbei oder sende mir eine Anfrage.`;

        const response = await fetch(`/api/openai/prompt`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt }),
        });

        const data = await response.json();

        if (data.error) {
            throw new Error(data.error.message);
        }

        if (!data.text) {
            throw new Error('Text generation failed.');
        }

        dispatch(promptUserBioSuccess(data.text));
    } catch (error) {
        dispatch(promptUserBioError(error.message || error));
    }
};

export const resolveCollectingUserInfo = (
    publicData = {},
    profileAttr = {},
    lastStep = false
) => async (dispatch, getState) => {
    if (lastStep) {
        dispatch(provideSignupAnalytics());
        const user = getState().user.currentUser;
        const { identityProviders, profile } = user.attributes;
        const { idpId } = (identityProviders || [{}])[0] || {};
        const { userType, postalCode, city } = profile.publicData;
        const publicAddress = `${postalCode} ${city}`;

        const lastStepParams = {
            ...profileAttr,
            publicData: {
                ...publicData,
                infoCollected: true,
                lastVisitedAt: stringifyToMarketplaceISO(new Date()),
            },
            protectedData: {
                joinDate: stringifyToMarketplaceISO(new Date()),
            },
            privateData: {
                idpAuthMethod: idpId || 'email',
            },
        };

        await dispatch(updateUserProfileInfo(lastStepParams));

        getPlaceDetailsGeoData({
            locationUnitsStr: publicAddress,
            excludeTypes,
        })
            .then(({ bounds, origin }) => {
                if (!bounds || !origin) {
                    throw new Error();
                }

                const userCountryCode = getUserCountry();
                const userCountryStr = (
                    supportedCountries[userCountryCode] || supportedCountries['CH']
                ).name;
                const boundsStr = [bounds.ne.lat, bounds.ne.lng, bounds.sw.lat, bounds.sw.lng].join(
                    '%2C'
                );
                const originStr = [origin.lat, origin.lng].join('%2C');
                const publicAddressStr = [postalCode, city].join('%20');

                const isRider = userType === userTypeRider;
                const templateByUserRole = isRider
                    ? 'userCreatedRiderTemplate'
                    : 'userCreatedHorseownerTemplate';

                const listingType = isRider ? listingTypeHorse : listingTypeRider;
                /**
                 * send sendGrid email on user created event
                 * the email template depends on userType, postalCode, city
                 */
                const params = {
                    recipientEmail: user.attributes.email,
                    emailTemplate: templateByUserRole,
                    groupIdName: 'ownAccountGroupId',
                    data: {
                        postalCode,
                        city,
                        recipientFirstName: user.attributes.profile.firstName,
                        canonicalRootURL,
                        searchPath: `/s?address=${publicAddressStr}%2C%20${userCountryStr}&bounds=${boundsStr}&origin=${originStr}&listingType=${listingType}`,
                    },
                };

                sendSGEmail(JSON.stringify(params));
            })
            .catch(e => {
                // do nothing
            });

        await dispatch(fetchCurrentUser());
        await dispatch(createListingFromTempData());

        return Promise.resolve({});
    }
    return dispatch(updateProfile(profileAttr, publicData));
};
