import { excludeTypes as excludeTypesCommon } from '../components/LocationAutocompleteInput/LocationAutocompleteInputImpl.helper';
import { ultimateGoogleMapsCountriesConfig } from './googleMaps';
import Geocoder from '../components/LocationAutocompleteInput/GeocoderGoogleMaps';

export const getPlacePredictions = async ({
    locationUnitsStr,
    excludeTypes,
    searchConfigurationsProp,
}) => {
    try {
        const geocoder = new Geocoder();

        const searchConfigurations = searchConfigurationsProp || {
            componentRestrictions: {
                country: Object.values(ultimateGoogleMapsCountriesConfig).map(({ abbr }) => abbr),
            },
        };

        const { predictions } = await geocoder.getPlacePredictions(
            locationUnitsStr,
            searchConfigurations,
            excludeTypes || [
                'locality',
                'country',
                'administrative_area_level_1',
                'postal_code',
                ...excludeTypesCommon,
            ]
        );

        return predictions;
    } catch (e) {
        return null;
    }
};

/**
 * Resolve place details from search string
 *
 * @param {*} locationUnitsStr string
 * @returns {Promise<util.propTypes.place | null>} place details from the
 * 1st google api autocomplete prediction
 */
export const getPlaceDetailsGeoData = async ({
    locationUnitsStr,
    excludeTypes,
    searchConfigurationsProp,
}) => {
    try {
        const geocoder = new Geocoder();

        const predictions = await getPlacePredictions({
            locationUnitsStr,
            excludeTypes,
            searchConfigurationsProp,
        });

        if (!predictions || predictions.length === 0) {
            throw new Error();
        }

        const predictionData = await geocoder.getPlaceDetails(predictions[0]);

        if (!predictionData) {
            throw new Error();
        }

        return predictionData;
    } catch (e) {
        return null;
    }
};
