import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { array, arrayOf, bool, func, number, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import { ensureListing, ensureTransaction, ensureUser } from '../../util/data';
import { trimDisplayNameLastWord } from '../../util/text';
import routeConfiguration from '../../routeConfiguration';
import { checkIOS } from '../../util/userAgent';
import {
    // BookingPanel, // TODO check if it is used
    NamedLink,
    AvailableUnlocksModal,
    UserDisplayName,
    TransactionQuestionSet,
    Tooltip,
    SecondaryButton,
} from '../../components';
import config from '../../config';
import { displayNames, resolveStateDataFn } from './TransactionPanel.helper';
import { SendMessageForm } from '../../forms';

// These are internal components that make this file more readable.
// import BreakdownMaybe from './BreakdownMaybe';
import FeedSection from './FeedSection';
import AcceptDeclineRequestButtonsMaybe from './AcceptDeclineRequestButtonsMaybe';
// import SaleButtonsModal from './SaleButtonsModal';
import css from './TransactionPanel.css';
import { IconShevronLeft, IconEyeL, ProtectionInfoIcon } from '../../icons';
import { anonymizeOtherParty, resolveOtherPartyData } from '../../util/transaction';
import {
    consultChatRequestMixpanel,
    respondOnCustomerInquiry as respondOnCustomerInquiryThunk,
} from '../../containers/TransactionPage/TransactionPage.duck';
import { connect, useDispatch, useSelector } from 'react-redux';
import CreditsPurchasingModal from '../CreditsPurchasingModal/CreditsPurchasingModal';
import { CREDITS_NUM_TO_UNLOCK_PAGE } from '../../containers/TransactionPage/TransactionPage.duck';
import { RIDER_AVAILABILITY_NOT_AVAILABLE } from '../../marketplace-custom-config';
import SponsoredChatLabel from './SponsoredChatLabel';
import { useFormFocusForSafari } from './useFormFocusForSafari';

const SEND_MESSAGE_FORM_ID = 'TransactionPanel.SendMessageForm';
const { userTypeRider } = config;

const TransactionPanel = props => {
    const {
        rootClassName,
        className,
        containerRootClassName,
        currentUser,
        savePaymentMethodFailed,
        sendMessageError,
        intl,
        timeSlots,
        fetchTimeSlotsError,
        onAppointmentSend: handleAppointmentSending,
        onAppointmentHandling: handleAppointmentAction,
        navSectionClassName,
        inquiryError,
        onGetUserWishlist,
        asidePanelVisible,
        setAsidePanelVisibility,
        setInboxPanelVisible,
        isMobile,
        onInitialRender,
        pageId,
        ...rest
    } = props;
    const dispatch = useDispatch();
    const onRespondOnCustomerInquiry = (txData, declineDataMaybe) =>
        dispatch(respondOnCustomerInquiryThunk(txData, declineDataMaybe));

    const [spendCreditsModalVisible, setSpendCreditsModalVisible] = useState(false);
    const [purchaseCreditsModalVisible, setPurchaseCreditsModalVisible] = useState(false);
    const [purchaseCreditsError, setPurchaseCreditsError] = useState(null);
    const [imageUploadRequested, setImageUploadRequested] = useState(false);
    const [dragOverFORM, setDragOverFORM] = useState(false);
    const [formError, setFormError] = useState(null);
    const [onSendMessageFormFocus] = useFormFocusForSafari();
    const ensuredTransaction = ensureTransaction(rest.transaction);
    const currentListing = ensureListing(ensuredTransaction.listing);
    const currentProvider = ensureUser(ensuredTransaction.provider);
    const currentCustomer = ensureUser(ensuredTransaction.customer);

    const { id: txId } = ensuredTransaction;
    const {
        attributes: {
            profile: {
                publicData: { userType: currentUserType },
            },
        },
    } = currentUser || { id: { uuid: null } };

    const otherParty = anonymizeOtherParty(
        resolveOtherPartyData(currentUser, currentCustomer, currentProvider)
    )(ensuredTransaction);

    const isCustomer = currentCustomer?.id?.uuid === currentUser?.id?.uuid;
    const isProvider = currentProvider?.id?.uuid === currentUser?.id?.uuid;
    const isSponsored = ensuredTransaction?.attributes?.protectedData?.isSponsored;

    const {
        isAnonym,
        publicData: { userType: otherPartyUserType, availabilityStatus },
    } = otherParty.attributes.profile;

    const otherPartyId = (otherParty.id || {}).uuid;
    const otherPartyIsRider = otherPartyUserType === userTypeRider;
    const otherPartyIsNotAvailable =
        otherPartyIsRider && availabilityStatus === RIDER_AVAILABILITY_NOT_AVAILABLE;
    const otherPartyDisplayName = trimDisplayNameLastWord(
        otherParty.attributes.profile.displayName || ''
    );
    /**
     * represent horseowner wishlist;
     * maybe, a rider has been added to it
     */
    useEffect(() => {
        if (isProvider) onGetUserWishlist();
    }, [isProvider]);

    useEffect(() => {
        if (txId) onInitialRender(txId);
    }, [txId]);

    const transactionRolePublicData = isCustomer
        ? currentProvider.attributes.profile.publicData
        : currentCustomer.attributes.profile.publicData;

    if (transactionRolePublicData) {
        delete transactionRolePublicData['auto'];
        delete transactionRolePublicData['drivingLicense'];
        delete transactionRolePublicData['language'];
    }

    const noListingInfo = currentListing && !currentListing.id;
    const isListingDeleted = !noListingInfo && currentListing.attributes.deleted;
    const isCustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = isCustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = isCustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;
    /** listing deleted */

    const stateData = resolveStateDataFn({
        currentTransaction: ensuredTransaction,
        isProvider,
        currentUser,
    });

    const { otherUserDisplayNameString } = displayNames(
        currentUser,
        currentProvider,
        currentCustomer,
        intl,
        UserDisplayName
    );

    const { publicData: listingPublicData, state: listingState } = currentListing.attributes;
    const listingIsClosed = listingState === 'closed';

    const {
        showQuestionsSet,
        hideSendMessagePanel,
        showAcceptDeclineRequestButtons,
        showSponsoredLabel,
        showMessageNotification,
        showStatusPanel,
        hideFooter,
    } = stateData;

    const showSendMessageForm =
        !listingIsClosed &&
        !otherPartyIsNotAvailable &&
        !hideSendMessagePanel &&
        !isCustomerBanned &&
        !isCustomerDeleted &&
        !isProviderBanned &&
        !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
        { id: 'TransactionPanel.sendMessagePlaceholder' },
        { name: otherUserDisplayNameString }
    );

    const paymentMethodsPageLink = (
        <NamedLink name="PaymentMethodsPage">
            <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
        </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);

    const labelClasses = classNames(
        css.sendMessageForm,
        dragOverFORM ? css.sendMessageFormHovered : ''
    );

    const customerName = trimDisplayNameLastWord(currentCustomer.attributes.profile.displayName);
    const providerName = trimDisplayNameLastWord(currentProvider.attributes.profile.displayName);

    const respondOnCustomerInquiry = async (declineMessageMaybe, reason) => {
        const {
            attributes: {
                email,
                profile: { firstName, lastName },
            },
        } = currentUser;
        const isDeclineAction = declineMessageMaybe && reason;

        await onRespondOnCustomerInquiry(
            {
                txId,
                email,
                lastName,
                firstName,
                otherParty,
                currentListing,
            },
            isDeclineAction ? { declineMessage: declineMessageMaybe, reason } : {}
        );
    };

    const handleAcceptOrDeclineRequest = (declineMessageMaybe, reason) => {
        if (declineMessageMaybe) {
            return respondOnCustomerInquiry(declineMessageMaybe, reason);
        }

        if (isSponsored) {
            // do not show credits modal in case of sponsored transaction
            return respondOnCustomerInquiry();
        }
        const {
            attributes: {
                profile: {
                    publicData: { userType },
                    privateData: { creditsAvailable = [] },
                },
            },
        } = currentUser;

        const creditsAvailableByType = Number(creditsAvailable[userType] || 0);

        if (creditsAvailableByType < CREDITS_NUM_TO_UNLOCK_PAGE) {
            setPurchaseCreditsModalVisible(true);
        } else {
            setSpendCreditsModalVisible(true);
        }
    };

    const containerClassNames = classNames({
        [css.container]: true,
        [css.containerNoFooter]: hideFooter,
        [containerRootClassName]: !!containerRootClassName,
    });

    const { ridingsNum } = listingPublicData || {};
    const days = ridingsNum == 1 ? 'Tag' : 'Tagen';
    const ridingsNumTooltipContent = intl.formatMessage(
        {
            id: 'TransactionPanel.ridingsNumTooltipContent',
        },
        { ridingsNum, days }
    );
    const classesContainer = classNames(
        containerClassNames,
        !showStatusPanel && css.containerWithoutShowStatusPanel
    );

    const scrollToMessage = ({ uuid }) => {
        const el = document.querySelector(`#msg-${uuid}`);

        if (el) {
            el.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
            });
        }
    };

    const onMessageSubmit = ({ message: messageValue }, form) => {
        let textareaValue = null;
        let eventTextAreaTarget = null;

        try {
            if (typeof window !== 'undefined') {
                eventTextAreaTarget = window.event.target.getElementsByTagName('textarea')[0];
                textareaValue = eventTextAreaTarget.value;
            }
        } catch (e) {}

        const message = textareaValue ? textareaValue : messageValue ? messageValue.trim() : null;

        if (!message) {
            return;
        }

        const { id } = ensuredTransaction;

        rest.onSendMessage(id, message)
            .then(messageId => {
                if (eventTextAreaTarget) {
                    eventTextAreaTarget.value = '';
                }

                form.reset();
                scrollToMessage(messageId);
            })
            .catch(e => {
                // Ignore, Redux handles the error
            });
    };

    const onImageUploadHandler = files => {
        if (typeof files !== 'object' || !files[0] || files.length > 1 || imageUploadRequested) {
            const formError = imageUploadRequested
                ? 'File loading is in progress.'
                : 'File either has an invalid type, or the total number of files is bigger than 1.';

            return setFormError(formError);
        }

        const fileSizeExceeded = files[0].size / 1024 ** 2 > 10;

        if (fileSizeExceeded) {
            return setFormError('File size exceeded.');
        }

        setImageUploadRequested(true);
        setFormError(null);

        rest.onSendFile(ensuredTransaction, files[0]).finally(() => {
            setImageUploadRequested(false);
        });
    };

    const onDragHandler = (e, flag) => {
        // function responsible for changing label background
        e.preventDefault();
        e.stopPropagation();
        setDragOverFORM(flag);
    };

    const onDropHandler = (e, form) => {
        setDragOverFORM(false);

        const droppedFiles = e.dataTransfer && e.dataTransfer.files;

        if (!droppedFiles) {
            return setFormError('Selected file is invalid.');
        }

        onImageUploadHandler(droppedFiles);
    };

    return (
        <>
            <nav
                className={classNames(css.navSection, {
                    [navSectionClassName]: !!navSectionClassName,
                })}
            >
                <p
                    className={css.navigateToInbox}
                    onClick={() => {
                        rest.history.push(
                            createResourceLocatorString('OrderMessagesPage', routeConfiguration())
                        );
                    }}
                >
                    <IconShevronLeft />
                    <FormattedMessage id="TransactionPanel.navigateToInbox" />
                </p>
                <h2 className={css.otherPartyProfileInfo}>
                    <UserDisplayName user={otherParty} intl={intl} shouldBeTrimmed={isAnonym} />
                    {!isAnonym && (
                        <IconEyeL
                            clickHandler={() =>
                                rest.history.push(
                                    createResourceLocatorString(
                                        'ProfilePage',
                                        routeConfiguration(),
                                        {
                                            userType: otherPartyUserType,
                                            id: otherPartyId,
                                        },
                                        {}
                                    )
                                )
                            }
                        />
                    )}
                </h2>

                {/* desktop */}
                <SecondaryButton
                    type="button"
                    onClick={() => {
                        if (noListingInfo || isListingDeleted) return;
                        setAsidePanelVisibility(!asidePanelVisible);
                    }}
                    className={classNames({
                        [css.asidePanelActionHighlighted]: asidePanelVisible,
                        [css.asidePanelActionDisabled]: noListingInfo || isListingDeleted,
                        [css.asidePanelDesktop]: true,
                    })}
                >
                    <FormattedMessage id="TransactionPanel.viewDetails" />
                </SecondaryButton>
                {/* mob */}
                <div
                    className={classNames(css.asidePanelMob, {
                        [css.asidePanelMobDisabled]: noListingInfo || isListingDeleted,
                    })}
                    onClick={() => {
                        if (noListingInfo || isListingDeleted) return;
                        setAsidePanelVisibility(true);
                    }}
                >
                    <span className={css.ellipsis}>...</span>
                </div>
            </nav>
            <div className={classes}>
                <div className={classesContainer}>
                    <div className={css.txInfo}>
                        <aside className={css.dataProtectionSidenote}>
                            <ProtectionInfoIcon />
                            <FormattedMessage
                                id="TransactionPanel.dataProtectionSidenote"
                                values={{
                                    displayName: trimDisplayNameLastWord(
                                        otherPartyDisplayName || ''
                                    ),
                                }}
                            />
                        </aside>
                        {ridingsNum && (
                            <Tooltip
                                content={ridingsNumTooltipContent}
                                tooltipClassName={css.tooltip}
                            >
                                <div className={css.ridingsNumButton} data-tooltip={'item'}>
                                    <FormattedMessage
                                        id="EditListingDescriptionForm.numberOfRidings"
                                        values={{ value: ridingsNum }}
                                    />
                                </div>
                            </Tooltip>
                        )}

                        {savePaymentMethodFailed ? (
                            <p className={css.genericError}>
                                <FormattedMessage
                                    id="TransactionPanel.savePaymentMethodFailed"
                                    values={{ paymentMethodsPageLink }}
                                />
                            </p>
                        ) : null}

                        <FeedSection
                            currentTransaction={ensuredTransaction}
                            currentUser={currentUser}
                            handleAppointmentAction={handleAppointmentAction}
                            {...rest}
                        />

                        {isSponsored && showSponsoredLabel && (
                            <SponsoredChatLabel otherParty={otherParty} isCustomer={isCustomer} />
                        )}

                        {showAcceptDeclineRequestButtons && (
                            <AcceptDeclineRequestButtonsMaybe
                                onRespondOnCustomerInquiry={handleAcceptOrDeclineRequest}
                                revieweeName={otherUserDisplayNameString}
                                currentListing={currentListing}
                                inquiryError={inquiryError}
                                currentUser={currentUser}
                                otherParty={otherParty}
                            />
                        )}
                        {showMessageNotification && (
                            <p className={css.showMessageNotification}>
                                <FormattedMessage
                                    id="TransactionPanel.showMessageNotification"
                                    values={{ displayName: otherPartyDisplayName }}
                                />
                            </p>
                        )}

                        {showSendMessageForm && (
                            <React.Fragment>
                                {formError && <span className={css.actionError}>{formError}</span>}
                                {purchaseCreditsError && (
                                    <span className={css.actionError}>
                                        Entschuldigen Sie, etwas ist schiefgelaufen. Versuchen Sie,
                                        die Seite neu zu laden oder wenden Sie sich an den Support.
                                    </span>
                                )}
                                <SendMessageForm
                                    formId={SEND_MESSAGE_FORM_ID}
                                    rootClassName={labelClasses}
                                    messagePlaceholder={sendMessagePlaceholder}
                                    sendMessageError={sendMessageError}
                                    otherParty={otherParty}
                                    onFocus={onSendMessageFormFocus}
                                    onBlur={() => null}
                                    onSubmit={onMessageSubmit}
                                    onDrag={onDragHandler}
                                    onDrop={onDropHandler}
                                    imageUploadRequested={imageUploadRequested}
                                    onImageUploadHandler={onImageUploadHandler}
                                    currentListing={currentListing}
                                    currentTransaction={ensuredTransaction}
                                    currentUser={currentUser}
                                    customerName={customerName}
                                    providerName={providerName}
                                    isCustomer={isCustomer}
                                    handleAppointmentSending={handleAppointmentSending}
                                    isMobile={isMobile}
                                    {...rest}
                                />
                            </React.Fragment>
                        )}
                        {showQuestionsSet && (
                            <TransactionQuestionSet currentUserType={currentUserType} intl={intl} />
                        )}
                    </div>
                </div>

                {spendCreditsModalVisible && (
                    <AvailableUnlocksModal
                        isOpen
                        onClose={() => setSpendCreditsModalVisible(false)}
                        creditsNum={CREDITS_NUM_TO_UNLOCK_PAGE}
                        modalHeading="Anfrage freischalten"
                        modalDesc="Nutze deine Credits, um diese Anfrage zu akzeptieren."
                        currentUser={currentUser}
                        handleAcceptOrDeclineRequest={() => {
                            respondOnCustomerInquiry();
                            setSpendCreditsModalVisible(false);
                        }}
                    />
                )}
                {purchaseCreditsModalVisible && (
                    <CreditsPurchasingModal
                        isOpen
                        heading="Alles aufgebraucht!"
                        description="Füge mehr Credits hinzu, um diese Anfrage zu akzeptieren."
                        sidenote={<p>Wähle die Anzahl Credits</p>}
                        currentUser={currentUser}
                        onClose={() => setPurchaseCreditsModalVisible(false)}
                        onError={() => {
                            setPurchaseCreditsModalVisible(false);
                            setPurchaseCreditsError();
                        }}
                    />
                )}
            </div>
        </>
    );
};

TransactionPanel.propTypes = {
    rootClassName: string,
    className: string,

    currentUser: propTypes.currentUser,
    transaction: propTypes.transaction.isRequired,
    totalMessagePages: number.isRequired,
    oldestMessagePageFetched: number.isRequired,
    messages: arrayOf(propTypes.message).isRequired,
    initialMessageFailed: bool,
    savePaymentMethodFailed: bool,
    fetchMessagesInProgress: bool.isRequired,
    fetchMessagesError: propTypes.error,
    sendMessageError: propTypes.error,
    sendReviewError: propTypes.error,
    onSendMessage: func.isRequired,
    timeSlots: arrayOf(propTypes.timeSlot),
    fetchTimeSlotsError: propTypes.error,
    // from injectIntl
    intl: intlShape,
};

const mapDispatchToProps = dispatch => ({
    onInitialRender: txId => dispatch(consultChatRequestMixpanel(txId)),
});

export default compose(connect(null, mapDispatchToProps), withRouter, injectIntl)(TransactionPanel);
