/**
 * TODO: create a separate file for session storage
 * or rename the current to browserStorage.js
 */
import { getUserAddressInfo } from '../services/ipinfo';
import { exctractPublicDataCountry } from './location';
import { parse } from './urlHelpers';

const LS_VERSIONING_KEY = '__version';
const CASH_TIME_IN_SECONDS = 2628000; // one month in seconds
/**
 * User location calculated from ip request
 */
const USER_LOCATION_LS_NAME = 'usr_loc';
const USER_SEARCH_PREDICTIONS = 'usr_srch_prdc';
const USER_SEARCH_PREDICTIONS_VERSION =
    process.env.REACT_APP_USER_SEARCH_PREDICTIONS_VERSION || '00.00.00';
const LAST_SEEN_LISTINGS = 'lst_sn_lstng';

export const ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT = 'ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT';
export const ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT_VERSION =
    process.env.REACT_APP_ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT_VERSION || '00.00.00';

export const TEMP_LISTING_STORAGE_KEY = 'TL0000';
export const TEMP_LISTING_LINK_KEY = 'TL0011';
export const TEMP_LISTING_SLUG_DATA_KEY = 'tempListingSlugData';
// session storage
export const UTM_CAMPAIGN_USER_DATA = 'utmcud';
/**
 * User location search params received from google api
 * e.g. when a user select current location as a search string
 */
const USER_CURRENT_LOCATION_SEARCH_PARAMS = 'uclsp';

const LAST_SEEN_LISTINGS_MAX_NUM = 10;
const PREDICTIONS_MAX_NUM = 80;

const _getStorage = storageName => {
    if (typeof window === 'object') {
        return window[storageName];
    }

    return {
        getItem: () => null,
        setItem: () => null,
        removeItem: () => null,
    };
};

const _localStorage = (() => _getStorage('localStorage'))();
const _sessionStorage = (() => _getStorage('sessionStorage'))();
/**
 * Get browser storage field
 * @param {string} lsKey
 * @param {object} storage - session or local storage
 * @returns
 */
const getBSDataByKey = (lsKey, browserStorage = _localStorage) => {
    const lsFieldData = browserStorage.getItem(lsKey);

    try {
        if (lsFieldData) {
            return JSON.parse(lsFieldData);
        }
        throw new Error('');
    } catch (e) {
        return {};
    }
};

const setLSDataByKey = (lsKey, data) => _localStorage.setItem(lsKey, JSON.stringify(data));

export const removeTempListingData = () => _localStorage.removeItem(TEMP_LISTING_STORAGE_KEY);

export const removeTempListingLink = () => _localStorage.removeItem(TEMP_LISTING_LINK_KEY);

export const getTempListingData = () => getBSDataByKey(TEMP_LISTING_STORAGE_KEY);

export const getTempListingLink = () => getBSDataByKey(TEMP_LISTING_LINK_KEY);

export const getCurrentUserLocation = () => getBSDataByKey(USER_LOCATION_LS_NAME);

export const getLastSeenListings = () => getBSDataByKey(LAST_SEEN_LISTINGS);

export const getUserCurrentLocationSP = () => getBSDataByKey(USER_CURRENT_LOCATION_SEARCH_PARAMS);

export const getUTMSessionStorageData = () =>
    getBSDataByKey(UTM_CAMPAIGN_USER_DATA, _sessionStorage);
/**
 *
 * @param {object} searchParams - origin, address
 */
export const setUserCurrentLocationSP = searchParams => {
    setLSDataByKey(USER_CURRENT_LOCATION_SEARCH_PARAMS, searchParams);
};

export const setLastSeenListings = id => {
    const dataStored = getLastSeenListings();

    if (!Array.isArray(dataStored) || dataStored.length === 0) {
        return setLSDataByKey(LAST_SEEN_LISTINGS, [id]);
    }

    const idAlreadySet = dataStored.includes(id);

    const list = idAlreadySet
        ? /**
           * if a listing visited again, move it to the 1st place
           */
          [...dataStored.filter(storedId => storedId !== id), id]
        : [...dataStored, id];

    if (list.length > LAST_SEEN_LISTINGS_MAX_NUM) {
        list.shift();
    }

    setLSDataByKey(LAST_SEEN_LISTINGS, list);
};

export const setTempListingData = data =>
    _localStorage.setItem(
        TEMP_LISTING_LINK_KEY,
        JSON.stringify({
            [TEMP_LISTING_SLUG_DATA_KEY]: data,
        })
    );

export const setCurrentUserLocation = async store => {
    const userCurrentLocation = _localStorage.getItem(USER_LOCATION_LS_NAME);

    const { user } = store;
    const { currentUser } = user || {};

    const publicDataCountry = exctractPublicDataCountry(currentUser);

    if (userCurrentLocation) {
        try {
            const { createdAt, city, country, postal, countrySelected } = JSON.parse(
                userCurrentLocation
            );
            const timePastSinceLastUpd =
                (new Date().getTime() - new Date(createdAt).getTime()) / 1000;

            const userIsUnknown = !publicDataCountry;
            const userCountrySelected = userIsUnknown || publicDataCountry === countrySelected;
            const necessaryDataCollected = city && country && postal && userCountrySelected;

            const shouldRefetch = timePastSinceLastUpd >= CASH_TIME_IN_SECONDS;
            /** if data should be refetched, just do nothing,
             * otherwise throw error and return an user CL data
             */
            if (!shouldRefetch && necessaryDataCollected) {
                throw new Error({});
            }
        } catch (e) {
            console.log('Skip re-fetch user location info');
            return userCurrentLocation;
        }
    }

    try {
        const { error, message, ...locationData } = await getUserAddressInfo();

        if (error) {
            return _localStorage.removeItem(USER_LOCATION_LS_NAME);
        }

        _localStorage.setItem(
            USER_LOCATION_LS_NAME,
            /** countrySelected should be considered as the value with the highest priority,
             *  and it is set as soon as an info about user's location
             * is received from current user data at publicData field
             */
            JSON.stringify({
                ...locationData,
                createdAt: new Date(),
                countrySelected: publicDataCountry || null,
            })
        );

        return getCurrentUserLocation();
    } catch (e) {
        return {};
    }
};

export const updateCurrentUserLocationField = (key, value) => {
    const userCurrentLocation = _localStorage.getItem(USER_LOCATION_LS_NAME);
    if (userCurrentLocation) {
        try {
            const userData = JSON.parse(userCurrentLocation);
            if (userData.hasOwnProperty(key)) {
                userData[key] = value;
                _localStorage.setItem(USER_LOCATION_LS_NAME, JSON.stringify(userData));
            }
            return true;
        } catch (e) {
            return false;
        }
    }
    return false;
};

// const increasePredictionNum = (userPredictionData, searchAsKey) => {
//     try {
//         userPredictionData[searchAsKey].useNum = userPredictionData[searchAsKey].useNum + 1;

//         _localStorage.setItem(USER_SEARCH_PREDICTIONS, JSON.stringify(userPredictionData));
//     } catch (e) {
//         // do nothing
//     }
// };

const initiPredictionData = () => {
    _localStorage.setItem(
        USER_SEARCH_PREDICTIONS,
        JSON.stringify({
            [LS_VERSIONING_KEY]: USER_SEARCH_PREDICTIONS_VERSION,
        })
    );
};
/**
 * Find keys which has 'searchAsKey' as a substring
 * @param {string} searchAsKey
 * @param {object} userPredictionData
 * @returns
 */
const findSimilarDataByKey = (searchAsKey, userPredictionData) => {
    /** similar search should work only for letters */
    if (searchAsKey.match(/[0-9]/)) return undefined;

    const shakeKey = k =>
        k
            // .split('')
            // .sort()
            // .join('')
            .trim()
            .toLowerCase();

    const similarData = Object.entries(userPredictionData)
        .filter(([s]) => s !== LS_VERSIONING_KEY)
        .reduce(
            (acc, [s, v]) => ({
                ...acc,
                [s]: {
                    ...v,
                    similarKey: shakeKey(s),
                },
            }),
            {}
        );

    return Object.values(similarData).find(v => v.similarKey.includes(shakeKey(searchAsKey)));
};

export const checkUserPredictionData = searchAsKey => {
    const userPredictionJSON = _localStorage.getItem(USER_SEARCH_PREDICTIONS);

    if (!userPredictionJSON) {
        initiPredictionData();
        return undefined;
    }
    try {
        const userPredictionData = JSON.parse(userPredictionJSON);
        const data =
            userPredictionData[searchAsKey] || userPredictionData[searchAsKey.toLowerCase()];
        const noVersioning = !userPredictionData[LS_VERSIONING_KEY];

        if (noVersioning) {
            initiPredictionData();
            throw new Error({});
        }

        if (
            userPredictionData[LS_VERSIONING_KEY] &&
            userPredictionData[LS_VERSIONING_KEY] !== USER_SEARCH_PREDICTIONS_VERSION
        ) {
            _localStorage.removeItem(USER_SEARCH_PREDICTIONS);
            throw new Error({});
        }

        // const similarData = findSimilarDataByKey(searchAsKey, userPredictionData);

        // if (similarData) return similarData;

        if (!data) {
            throw new Error({});
        }
        /** prediction num is not increased for similar data */
        /** right now the data from increasePredictionNum is not used, timestamp is used instead */
        // increasePredictionNum(userPredictionData, searchAsKey);

        return data;
    } catch (e) {
        return undefined;
    }
};

export const setUserPredictionData = (searchAsKey, predictions, extraParams = {}) => {
    try {
        const userPredictionJSON = _localStorage.getItem(USER_SEARCH_PREDICTIONS);
        const userPredictionData = JSON.parse(userPredictionJSON);
        const userPredictionEntries = Object.entries(userPredictionData);
        const maxKeysNumExceeded = userPredictionEntries.length >= PREDICTIONS_MAX_NUM;

        if (maxKeysNumExceeded) {
            const { key } = userPredictionEntries.reduce(
                (acc, [key, item]) => {
                    if (key === LS_VERSIONING_KEY) return acc;
                    if (item.timestamp === 'Infinity') return acc;

                    if (!item.timestamp || item.timestamp < acc.timestamp) {
                        acc = { key, ...item };
                    }
                    return acc;
                },
                { key: 'null', timestamp: Infinity }
            );
            // delete the least used key
            delete userPredictionData[key];
        }

        const data = predictions.map(({ place_id, distance, predictionPlace, description }) => ({
            place_id,
            distance,
            predictionPlace,
            description,
        }));

        const userPredictionDataUpdated = {
            ...userPredictionData,
            [searchAsKey]: { data, timestamp: new Date().getTime(), ...extraParams },
        };

        _localStorage.setItem(USER_SEARCH_PREDICTIONS, JSON.stringify(userPredictionDataUpdated));

        return true;
    } catch (e) {
        return false;
    }
};

export const getOneSignalPromptData = ({ userType, currentUserId }) => {
    try {
        const data = getBSDataByKey(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT);
        if (!data.accepted || !data[LS_VERSIONING_KEY]) {
            throw new Error({});
        }

        const versioning = data[LS_VERSIONING_KEY];

        if (versioning !== ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT_VERSION) {
            _localStorage.removeItem(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT);
            throw new Error({});
        }

        const externalId = `${userType}-${currentUserId}`;

        if (!Array.isArray(data.subscriptions)) {
            /**
             * re-set subscriptions LS field for already subscribed users
             */
            setOneSignalPromptData({
                userType,
                currentUserId,
            });
            return data;
        }

        if (data.subscriptions.includes(externalId)) {
            /**
             * user already subscribed
             */
            return data;
        }
        /**
         * a new external_id is detected, do not clear LS field
         * instead suggest to a user to re-subscribe for a new acc
         */
        return undefined;
    } catch (e) {
        return undefined;
    }
};

export const setOneSignalPromptData = ({ userType, currentUserId }) => {
    const externalId = `${userType}-${currentUserId}`;
    const alreadyStoredData = getBSDataByKey(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT);

    if (!alreadyStoredData[LS_VERSIONING_KEY]) {
        /** initialize the LS OneSignal data */
        const data = {
            accepted: true,
            [LS_VERSIONING_KEY]: ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT_VERSION,
            subscriptions: [externalId],
        };

        return setLSDataByKey(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT, data);
    }

    const subscriptions = alreadyStoredData.subscriptions || [];
    const newExternalId = !subscriptions.includes(externalId);

    if (newExternalId) {
        /** update user subscriptions with new externalId */
        setLSDataByKey(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT, {
            ...alreadyStoredData,
            subscriptions: [...subscriptions, externalId],
        });
    }
};

/**
 * https://www.horsedeal.com/signup -> campaignURL: https://www.horsedeal.com/signup
 * utm_source=google -> campaignSource: google
 * utm_medium=cpc -> campaignMedium: cpc
 * utm_campaign=horseowner-painpoint-holidays -> campaignName: horseowner-painpoint-holidays
 * utm_id=123123123 -> campaignID: 123123123
 * utm_content=v1 -> campaignContent: v1
 */
const utmDTO = ({ utm_source, utm_medium, utm_campaign, utm_id, utm_content, campaignURL }) => ({
    campaignURL,
    campaignSource: utm_source,
    campaignMedium: utm_medium,
    campaignName: utm_campaign,
    campaignID: utm_id,
    campaignContent: utm_content,
});

export const setUTMDataToSessionStorage = () => {
    /**
     * if there are utm data in the url search,
     * set them to session storage;
     *
     * when a user decides to create an acc,
     * set those utm fields into a new acc data
     */

    try {
        const { search, origin, pathname } = window.location;
        const searchParsed = parse(search);
        const { utm_campaign, utm_id } = searchParsed;

        if (!utm_id || !utm_campaign) return;

        _sessionStorage.setItem(
            UTM_CAMPAIGN_USER_DATA,
            JSON.stringify(
                utmDTO({
                    ...searchParsed,
                    campaignURL: `${origin}${pathname}`,
                })
            )
        );
    } catch (e) {
        // do nothing
    }
};
