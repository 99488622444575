import React, { useState } from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { EntityLocationFormFields } from '../../forms';
import css from './EditListingLocationForm.css';
import { FieldCheckbox } from '../../components';
import { IconLock } from '../../icons';
import { usePresavedInitialLocationValues } from './usePresavedInitialLocationValues';
import { PREDICT_LOCATION_STEP } from '../EntityLocationFormFields/EntityLocationFormFields';
import { useNewInitialLocationValues } from './useNewInitialLocationValues';

const EditListingLocationPageOne = ({ intl, values, currentListing, form, valid }) => {
    const [step, setStep] = useState(PREDICT_LOCATION_STEP);

    usePresavedInitialLocationValues({ form, values, currentListing });

    useNewInitialLocationValues({ form, currentListing, values, step });

    return (
        <div>
            <h1 className={css.locationTypeTitle}>
                <FormattedMessage id="EditListingLocationForm.locationTypeTitle" />
            </h1>
            <p className={css.locationTypeSubTitle}>
                <FormattedMessage id="EditListingLocationForm.locationTypeSubTitle" />
            </p>

            <EntityLocationFormFields
                notifyOnChange={({ currentStep }) => setStep(currentStep)}
                form={form}
                values={values}
                valid={valid}
                noLocationFoundContent="Ich finde die Stalladresse nicht"
            />

            <div className={css.lockerWrapper}>
                <IconLock />
                <FormattedMessage id="EditListingLocationForm.lockTitle" />
            </div>
            <div>
                <div className={css.mobilityContainer}>
                    <h4 className={css.mobilityTitle}>
                        <FormattedMessage id="EditListingLocationForm.mobilityTitle" />
                    </h4>
                </div>
                <FieldCheckbox
                    type="checkbox"
                    id="mobility"
                    name="mobility"
                    label={intl.formatMessage({
                        id: 'EditListingLocationForm.accessiblityTitle',
                    })}
                    value="mobility"
                    className={css.locationFormFieldsItem}
                    form={form}
                    togglerMode
                    reversePosition
                    isSingleElem
                />
            </div>
        </div>
    );
};

export default EditListingLocationPageOne;
