import React from 'react';
import classNames from 'classnames';

import { Button, Form, FieldTextInput } from '../../components';

import css from './StepConfirmPhoneNumber.css';
import { Form as FinalForm } from 'react-final-form';
import { required, composeValidators, isNumber } from '../../util/validators';
import { ProtectionInfoIcon } from '../../icons';
import { addMaskToPhoneNumber, ENTER_PHONE_NUMBER } from './helpers';
import { useIsMobile } from '../../hooks/useIsMobile';

export default ({
    otpCode,
    countryCode: countryCodeFromProps,
    currentPhoneNumber,
    submitInProgress,
    verifyPhoneNumber,
    handleReSendOtp,
    setVerificationState,
    otpError,
    setOtpError,
    phoneVerificationError,
}) => {
    const [isMobile] = useIsMobile();

    const handleChange = code => {
        setOtpError(null);

        if (code.length > 6) {
            return setOtpError('Der Code muss 6-stellig sein.');
        }
        if (code.length == 6 && parseInt(code) !== otpCode) {
            return setOtpError('Codes stimmen nicht überein.');
        }
    };

    return (
        <div className={css.completeProfileWrapper}>
            <h3 className={css.completeProfileHeader}>
                Code eingeben und Mobilnummer verifizieren
            </h3>
            <p className={css.completeProfileDescription}>
                Wir haben dir einen 6-stelligen SMS-Code an deine Mobilnummer <br />
                <b>
                    {countryCodeFromProps}{' '}
                    {addMaskToPhoneNumber(currentPhoneNumber, countryCodeFromProps)}
                </b>{' '}
                gesendet.
            </p>
            <div className={classNames(css.modalFormBody, css.modalFormBodyFullHeight)}>
                <FinalForm
                    onSubmit={verifyPhoneNumber}
                    initialValues={{ otp: '' }}
                    render={fieldRenderProps => {
                        const { handleSubmit, invalid, values } = fieldRenderProps;
                        const { otp } = values;
                        const submitDisabled = !!(
                            invalid ||
                            submitInProgress ||
                            otpError ||
                            !otp ||
                            otp.length < 6
                        );

                        return (
                            <Form onSubmit={handleSubmit}>
                                <FieldTextInput
                                    id="otp"
                                    name="otp"
                                    placeholder="Code"
                                    type={isMobile ? 'tel' : 'text'}
                                    className={css.otpField}
                                    notifyOnChange={value => handleChange(value)}
                                    validate={composeValidators(
                                        required('Dieses Feld wird benötigt.'),
                                        isNumber('Es sind nur Zahlen erlaubt.')
                                    )}
                                />

                                {Boolean(otpError || phoneVerificationError) && (
                                    <p className={css.error}>
                                        {otpError || phoneVerificationError}
                                    </p>
                                )}

                                <p className={css.modalNotification}>
                                    <span style={{ color: '#B2B2B2', cursor: 'auto' }}>
                                        Kein SMS-Code erhalten?{' '}
                                    </span>
                                    <span onClick={() => handleReSendOtp()} className={css.link}>
                                        Nochmals senden{' '}
                                    </span>
                                    <br />
                                    <span style={{ color: '#B2B2B2', cursor: 'auto' }}>
                                        {' '}
                                        Falsche Mobilnummer?
                                    </span>
                                    <span
                                        onClick={() => setVerificationState(ENTER_PHONE_NUMBER)}
                                        className={css.link}
                                    >
                                        {' '}
                                        Ändern
                                    </span>
                                </p>

                                <footer>
                                    <p className={css.completeProfileProtectionMessage}>
                                        <ProtectionInfoIcon />
                                        Deine Mobilnummer ist nicht öffentlich sichtbar.
                                    </p>
                                    <Button
                                        type="submit"
                                        inProgress={submitInProgress}
                                        disabled={submitDisabled}
                                    >
                                        Verifizieren
                                    </Button>
                                </footer>
                            </Form>
                        );
                    }}
                />
            </div>
        </div>
    );
};
