import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import {
    IconSocialMediaYoutube,
    IconSocialMediaInstagram,
    IconSocialMediaTiktok,
    IconSocialMediaFacebook,
    ExternalLink,
    NamedLink,
    LanguagesSwitcher,
} from '../../components';
import { HeartIcon } from '../../icons';

import css from './Footer.css';
import { getCurrentUserLocation } from '../../util/localStorage';

const windowDefined = typeof window !== 'undefined';
const isNative = windowDefined && (window.isNative || window.ReactNativeWebView);

const {
    siteYoutubePage,
    siteInstagramPage,
    siteTikTokPage,
    siteFacebookPage,
    listingTypeRider,
    initialSearchAddress,
    defaultCountry,
    maps: { supportedCountries },
} = config;

const renderSocialMediaIcons = () => [
    <ExternalLink
        key="linkToInstagram"
        href={siteInstagramPage}
        className={css.socialLink}
        title="TikTok"
    >
        <IconSocialMediaInstagram />
    </ExternalLink>,

    <ExternalLink
        key="linkToFacebook"
        href={siteFacebookPage}
        className={css.socialLink}
        title="Facebook"
    >
        <IconSocialMediaFacebook />
    </ExternalLink>,

    <ExternalLink
        key="linkToTikTok"
        href={siteTikTokPage}
        className={css.socialLink}
        title="Instagram"
    >
        <IconSocialMediaTiktok />
    </ExternalLink>,

    <ExternalLink
        key="linkToYoutube"
        href={siteYoutubePage}
        className={css.socialLink}
        title="YouTube"
    >
        <IconSocialMediaYoutube />
    </ExternalLink>,
];

const copyright = (
    <li key="copyright" className={classNames(css.listItem, css.copyright)}>
        © 2025 HorseDeal AG
    </li>
);

const bottomLinks = [
    <li className={css.listItem} key="impressum">
        <Link to="/impressum" className={css.link}>
            Impressum
        </Link>
    </li>,

    <li className={css.listItem} key="datenschutz">
        <Link to="/datenschutz" className={css.link}>
            Datenschutz
        </Link>
    </li>,

    <li className={css.listItem} key="cookie">
        <span
            onClick={() => {
                const UC_UI_defined = typeof window !== 'undefined' && window.UC_UI;

                UC_UI_defined && window.UC_UI.showSecondLayer();
            }}
            className={css.link}
        >
            Cookie-Einstellungen
        </span>
    </li>,

    <li className={css.listItem} key="agb">
        <Link to="/agb" className={css.link}>
            AGB
        </Link>
    </li>,
];

const Footer = ({ rootClassName, className, intl }) => {
    const { country, countrySelected } = getCurrentUserLocation();
    const socialMediaIcons = renderSocialMediaIcons(intl);
    const {
        code,
        defaultAddress: { origin },
    } =
        supportedCountries[countrySelected] ||
        supportedCountries[country] ||
        supportedCountries[defaultCountry];

    const { address: initialAddress, bounds: initialBounds } = initialSearchAddress[code];
    const classes = classNames(rootClassName || css.root, className);

    return (
        <footer className={classes}>
            <div className={css.content}>
                <section className={css.sectionLinksMain}>
                    <div className={css.footerLinkBlock}>
                        <p>Für Besitzer:innen</p>
                        <ul className={css.list}>
                            <li className={css.listItem}>
                                <NamedLink name="NewListingPage" className={css.link}>
                                    Kostenlos Inserieren
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="FindRiderPage" className={css.link}>
                                    Vorteile als Besitzer:in
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink
                                    name="SearchPage"
                                    to={{
                                        search: `?address=${initialAddress}&bounds=${initialBounds}&origin=${origin}&listingType=${listingTypeRider}&pub_activities=has_any%3AAusreiten%2CTraining%2CTurniere`,
                                    }}
                                    className={css.link}
                                >
                                    Reiter:in finden
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink
                                    name="SearchPage"
                                    to={{
                                        search: `?address=${initialAddress}&bounds=${initialBounds}&origin=${origin}&listingType=${listingTypeRider}&pub_activities=has_any%3ASpazieren`,
                                    }}
                                    className={css.link}
                                >
                                    Spazierer:in finden
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink
                                    name="SearchPage"
                                    to={{
                                        search: `?address=${initialAddress}&bounds=${initialBounds}&origin=${origin}&listingType=${listingTypeRider}&pub_activities=has_any%3APflegen`,
                                    }}
                                    className={css.link}
                                >
                                    Pfleger:in finden
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="ReferralPage" className={css.link}>
                                    Freunde einladen
                                </NamedLink>
                            </li>
                        </ul>
                    </div>
                    <div className={css.footerLinkBlock}>
                        <p>Für Pferdemenschen</p>
                        <ul className={css.list}>
                            <li className={css.listItem}>
                                <NamedLink name="SignupRider" className={css.link}>
                                    Kostenlos Registrieren
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="FindHorsePage" className={css.link}>
                                    Vorteile als Pferdemensch
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="ReitbeteiligungFindenPage" className={css.link}>
                                    Reitbeteiligung finden
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="SpazierbeteiligungFindenPage" className={css.link}>
                                    Spazierbeteiligung finden
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="PflegebeteiligungFindenPage" className={css.link}>
                                    Pflegebeteiligung finden
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="ReferralPage" className={css.link}>
                                    Freunde einladen
                                </NamedLink>
                            </li>
                        </ul>
                    </div>
                    <div className={css.footerLinkBlock}>
                        <p>HorseDeal</p>
                        <ul className={css.list}>
                            <li className={css.listItem}>
                                <NamedLink name="AboutUs" className={css.link}>
                                    Über uns
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <a className={css.link} href="/community">
                                    Community
                                </a>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="BecomePartnerPage" className={css.link}>
                                    Für Unternehmen
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="ForClubs" className={css.link}>
                                    Für Vereine
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="CareerPage" className={css.link}>
                                    Karriere
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <a className={css.link} href="/kontakt">
                                    Kontakt
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={css.footerLinkBlock}>
                        <p>Ressourcen</p>
                        <ul className={css.list}>
                            <li className={css.listItem}>
                                <NamedLink name="MatchingPage" className={css.link}>
                                    Matching-Algorithmus
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <a className={css.link} href="/support">
                                    Häufige Fragen
                                </a>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="KodexPage" className={css.link}>
                                    Unser Kodex
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <NamedLink name="SuperDeals" className={css.link}>
                                    SuperDeals
                                </NamedLink>
                            </li>
                            <li className={css.listItem}>
                                <a className={css.link} href="/presse">
                                    Presse
                                </a>
                            </li>
                            <li className={css.listItem}>
                                <a className={css.link} href="/blog">
                                    Blog
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>

                <section className={css.sectionSocialStores}>
                    {isNative ? null : (
                        <div className={css.socialStores}>
                            <a
                                href="https://support.horsedeal.com/hc/de/articles/17567786473618-Bietet-HorseDeal-eine-Mobile-App-an"
                                target="_blank"
                                rel="noopener noreferrer"
                                className={css.storelink}
                            >
                                <img
                                    src="https://www.horsedeal.com/static/icons/google-playstore-badge.svg"
                                    className={css.appPlayStoreIcon}
                                    alt="Playstore Badge"
                                />
                            </a>
                            <a
                                href="https://support.horsedeal.com/hc/de/articles/17567786473618-Bietet-HorseDeal-eine-Mobile-App-an"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img
                                    src="https://www.horsedeal.com/static/icons/apple-appstore-badge.svg"
                                    className={css.appPlayStoreIcon}
                                    alt="Appstore Badge"
                                />
                            </a>
                        </div>
                    )}

                    <LanguagesSwitcher rootClassName={css.langSwitcher} />
                </section>

                <section className={css.mob}>
                    <ul className={css.bottomLinksMobile}>{bottomLinks}</ul>
                </section>

                <aside className={css.sectionSideNote}>
                    Erstellt mit
                    <HeartIcon />
                    für dich.
                </aside>

                <section className={css.sectionSocialLinks}>
                    <div className={css.desktop}>
                        <ul className={css.list}>{[copyright, ...bottomLinks]}</ul>
                    </div>
                    <div className={css.socialLinks}>{socialMediaIcons}</div>
                </section>

                <aside className={classNames(css.mob, css.mobileCopyright)}>
                    © 2025 HorseDeal AG
                </aside>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    rootClassName: string,
    className: string,
    intl: intlShape.isRequired,
};

export default injectIntl(Footer);
