import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { PROVIDE_LOGOUT_MIXPANEL } from '../ducks/Auth.duck';
import { userDocumentsFromAssetsData } from '../util/userDocumentsFromAssets';
import { getSectionCompleteness } from '../util/sectionCompleteness';
import { mixpanelIsActivated } from './initMixpanel';
import { PUBLISH_LISTING_SUCCESS } from '../containers/EditListingPage/EditListingPage.duck';
import { commonDisciplines, experienceAllowedOptions } from '../marketplace-custom-config';
import config from '../config';
import {
    weightToBucket,
    heightToBucket,
    distanceToBucket,
    startAndEndDateToBucket,
    matchingScoreToBucket,
    mobilityRadiusToBucket,
} from './trackingBuckets';
import { PROVIDE_SEND_ENQUIRY_MIXPANEL } from '../containers/ListingPage/ListingPage.duck';
import { getMatchingScore, getMatchingScoreBulk } from '../util/api';
import { getDaysSince } from './helper';
import { transformAvailability, transformMobilityTransport } from './trackingTransformers';
import { createTrackingDataProvider } from './trackingDataProvider';
import {
    PROVIDE_ANSWER_CHAT_REQUEST_MIXPANEL,
    PROVIDE_CONSULT_CHAT_REQUEST_MIXPANEL,
} from '../containers/TransactionPage/TransactionPage.duck';
import { PROVIDE_LISTING_SEARCH_EXECUTED_MIXPANEL } from '../containers/SearchPage/SearchPage.duck';

const { listingTypeRider, userTypeRider, userTypeHorseowner, listingTypeHorse } = config;

const birthDateToDate = birthDate => {
    if (!birthDate) return undefined;
    const { day, month, year } = birthDate;
    return new Date(`${month}/${day}/${year}`);
};

const extractRiderProperties = (currentUser, userDocuments) => {
    if (!currentUser?.attributes?.profile?.publicData) return undefined;

    const sectionCompleteness = getSectionCompleteness(currentUser, userDocuments);

    if (!sectionCompleteness) return undefined;
    const { all, mandatory } = sectionCompleteness;

    const {
        attributes: {
            profile: { publicData },
        },
    } = currentUser;

    const {
        availabilityStatus,
        mobility,
        interest,
        desiredDisciplines,
        disciplinesLevel,
    } = publicData;

    return {
        rider_availability_status: availabilityStatus,
        rider_mobility_radius: mobilityRadiusToBucket(mobility?.radius),
        rider_interests: interest || [],
        rider_disciplines: desiredDisciplines || [],
        rider_levels: disciplinesLevel,
        rider_mandatory_sections_completeness: Math.ceil(mandatory * 100),
        rider_all_sections_completeness: Math.ceil(all * 100),
    };
};

const getNumberOfPublishedListingsFromOwnListingsEntities = ownListingsEntities => {
    if (!ownListingsEntities) return 0;
    const { ownListing } = ownListingsEntities;
    if (!ownListing) return 0;
    const listings = Object.values(ownListing);
    return listings.reduce(
        (sum, listing) => sum + (listing.attributes?.state === 'published' ? 1 : 0),
        0
    );
};

const extractOwnerProperties = (publicData, ownListingsEntities) => {
    if (!publicData) return undefined;

    return {
        horse_owner_nb_of_horses: getNumberOfPublishedListingsFromOwnListingsEntities(
            ownListingsEntities
        ),
    };
};

let currentUserValue;
const trackUserChange = (user, assetsData, ownListingsEntities) => {
    if (!mixpanel?.people) return;
    const previousCurrentUser = currentUserValue;
    if (!user) {
        currentUserValue = undefined;
        return;
    }

    const { currentUser } = user;
    if (!currentUser) {
        currentUserValue = undefined;
        return;
    }

    const userUuid = currentUser?.id?.uuid;
    if (!userUuid) {
        currentUserValue = undefined;
        return;
    }

    if (currentUser === previousCurrentUser) return;

    const { attributes } = currentUser;

    const profile = attributes?.profile;
    const publicData = profile?.publicData;

    const birthDate = birthDateToDate(publicData?.birthDate);

    const type = profile?.publicData?.userType;

    const userDocuments = userDocumentsFromAssetsData(userUuid, assetsData);

    // @TODO this can be optimized to fire less
    mixpanel.people.set({
        user_type: type,
        $first_name: profile?.firstName,
        $last_name: profile?.lastName,
        $email: attributes?.email,
        user_location_country: publicData?.country,
        user_location_postal_code: publicData?.postalCode,
        user_location_city: publicData?.city,
        user_location_address: `${publicData?.streetAddress} ${publicData?.houseNumber}`,
        user_experience: publicData?.experience,
        user_experience_focus: publicData?.experienceFocus,
        user_experience_regularity: publicData?.experienceRegularity,
        user_birthdate: birthDate,
        user_age: birthDate
            ? Math.floor(moment(new Date()).diff(moment(birthDate), 'years', true))
            : undefined,
        user_height_bucket: heightToBucket(publicData?.userHeight),
        user_weight_bucket: weightToBucket(publicData?.userWeight),
        ...(type === userTypeRider
            ? extractRiderProperties(currentUser, userDocuments)
            : extractOwnerProperties(publicData, ownListingsEntities)),
    });
};

let profilePageValue;
const trackProfilePageChange = (user, profilePage) => {
    if (!mixpanel?.people) return;

    const previousProfilePage = profilePageValue;

    if (!profilePage) {
        profilePageValue = undefined;
        return;
    }
    if (previousProfilePage === profilePage) return;

    const type = user?.currentUser?.attributes?.profile?.publicData?.userType;
    if (!type) return;

    if (type === userTypeRider) {
        mixpanel.people.set({ rider_nb_recommendations: profilePage.userRatingTotalCount ?? 0 });
    }
};

const trackHorseListingCreated = payload => {
    const {
        data: {
            attributes: {
                publicData: {
                    startDateISO,
                    endDateISO,
                    availability,
                    desiredExperience,
                    activities,
                    desiredDisciplines,
                    SSC_qualification,
                    skills,
                    addressDetails: { location: city, country },
                },
            },
        },
    } = payload;

    const getLabelForDiscipline = discipline =>
        commonDisciplines.find(commonDiscpline => commonDiscpline.key === discipline)?.label || '';

    mixpanel.track('Horse Listing Created', {
        horse_startdate_option_chosen: startAndEndDateToBucket(startDateISO, endDateISO),
        horse_required_availability: transformAvailability(availability),
        horse_required_experience:
            experienceAllowedOptions.find(option => option.value === desiredExperience)?.label ||
            'Keine',
        horse_activities_list: activities,
        horse_required_dispciplines_list: desiredDisciplines.map(getLabelForDiscipline),
        horse_required_qualifications_list: SSC_qualification.filter(qualification =>
            qualification.includes('.')
        ).map(qualification => {
            const [discipline, level] = qualification.split('.');
            return `${getLabelForDiscipline(discipline)} ${level}`;
        }),
        horse_required_skills_list: skills,
        horse_location_city: city,
        horse_location_country: country,
    });
};

const fetchMatchingScoreBucket = async (riderListingId, horseOwnerListingId) => {
    try {
        const matchingScoreRes = await getMatchingScore({
            riderListingId,
            horseOwnerListingId,
        });
        return matchingScoreToBucket(
            matchingScoreRes.finalScore ? matchingScoreRes.finalScore : undefined
        );
    } catch (e) {
        return undefined;
    }
};

const getGeolocationFromListingOrUser = listingOrUser => {
    if (!listingOrUser) return undefined;

    switch (listingOrUser.type) {
        case 'listing':
            return listingOrUser.attributes.geolocation;
        case 'user':
        case 'currentUser':
            return listingOrUser.attributes.profile.publicData.userLocation;
        default:
            return undefined;
    }
};

const getDistanceFromListingOrUser = (listingOrUserA, listingOrUserB) =>
    getDistance(
        getGeolocationFromListingOrUser(listingOrUserA),
        getGeolocationFromListingOrUser(listingOrUserB)
    );

const getDistance = (geolocationA, geolocationB) => {
    if (!geolocationA || !geolocationB) return undefined;
    const maps = window.google.maps;
    if (!maps) return undefined;
    const { computeDistanceBetween } = maps.geometry.spherical;

    const toMapsLatLng = geolocation => {
        return new maps.LatLng(geolocation.lat, geolocation.lng);
    };

    const latLngA = toMapsLatLng(geolocationA);
    const latLngB = toMapsLatLng(geolocationB);

    if (!latLngA || !latLngB) return undefined;

    return computeDistanceBetween(latLngA, latLngB) / 1000;
};

const getDataForChatEvent = async (trackingDataProvider, riderListingId, horseListingId) => {
    const horseListing = await trackingDataProvider.getListing(horseListingId);
    const riderListing = await trackingDataProvider.getListing(riderListingId);
    const riderUser = await trackingDataProvider.getUser(
        riderListing?.relationships?.author?.data?.id?.uuid
    );
    return {
        horseListing,
        riderListing,
        riderUser,
    };
};

const getChatEventPropsForHorseListing = async (
    trackingDataProvider,
    riderListingId,
    horseListingId
) => {
    const { horseListing, riderUser } = await getDataForChatEvent(
        trackingDataProvider,
        riderListingId,
        horseListingId
    );

    const {
        attributes: {
            publicData: { startDateISO, endDateISO },
        },
    } = horseListing;

    const {
        attributes: {
            createdAt,
            profile: {
                publicData: { mobility },
            },
        },
    } = riderUser;

    return {
        days_since_joined: getDaysSince(createdAt, new Date()),
        matching_rate_bucket: await fetchMatchingScoreBucket(riderListingId, horseListingId),
        distance_from_horse_location_bucket: distanceToBucket(
            getDistanceFromListingOrUser(riderUser, horseListing)
        ),
        horse_startdate_option_chosen: startAndEndDateToBucket(startDateISO, endDateISO),
        rider_mobility_radius: mobilityRadiusToBucket(mobility?.radius),
    };
};

const getChatEventPropsForRiderListing = async (
    trackingDataProvider,
    riderListingId,
    horseListingId
) => {
    const { horseListing, riderListing, riderUser } = await getDataForChatEvent(
        trackingDataProvider,
        riderListingId,
        horseListingId
    );

    const {
        attributes: {
            createdAt,
            profile: {
                publicData: { city, country, mobility, availabilityStatus, availability },
            },
        },
    } = riderUser;

    return {
        days_since_joined: getDaysSince(createdAt, new Date()),
        matching_rate_bucket: await fetchMatchingScoreBucket(riderListingId, horseListingId),
        distance_from_horse_location_bucket: distanceToBucket(
            getDistanceFromListingOrUser(riderListing, horseListing)
        ),
        rider_mobility: transformMobilityTransport(mobility?.transport),
        rider_mobility_radius: mobilityRadiusToBucket(mobility?.radius),
        user_location_city: city,
        user_location_country: country,
        rider_availability_option_chosen: transformAvailability(availability),
        rider_availability_status: availabilityStatus,
    };
};

const trackEnquirySent = async (trackingDataProvider, payload) => {
    const { state } = trackingDataProvider;
    const { listingId, listingSubstitutionId, transactionId } = payload;
    const { currentUser } = state.user;
    const {
        attributes: {
            profile: {
                publicData: { userType },
                protectedData: { representationListingId },
            },
        },
    } = currentUser;

    const sharedProps = {
        transaction_id: transactionId?.uuid,
    };

    if (userType === userTypeRider) {
        const props = await getChatEventPropsForHorseListing(
            trackingDataProvider,
            representationListingId,
            listingId
        );
        mixpanel.track(`Horse Contact Request Sent`, {
            ...props,
            ...sharedProps,
            horse_listing_id: listingId,
        });
    } else {
        const props = await getChatEventPropsForRiderListing(
            trackingDataProvider,
            listingId,
            listingSubstitutionId
        );
        mixpanel.track(`Rider Contact Request Sent`, {
            ...props,
            ...sharedProps,
            rider_listing_id: listingId,
        });
    }
};

const trackEnquiryInteraction = async (trackingDataProvider, payload, interaction, extraProps) => {
    const { state } = trackingDataProvider;
    const {
        currentUser: {
            attributes: {
                profile: {
                    publicData: { userType },
                },
            },
        },
    } = state.user;
    const txUUID = payload.txId.uuid;

    const transaction = await trackingDataProvider.getTransaction(txUUID);
    if (!transaction) return;

    const {
        attributes: {
            protectedData: { initiatedAs },
            lastTransitionedAt,
        },
        relationships: {
            customer: {
                data: {
                    id: { uuid: customerId },
                },
            },
            listing: {
                data: {
                    id: { uuid: listingId },
                },
            },
            provider: {
                data: {
                    id: { uuid: providerId },
                },
            },
        },
    } = transaction;

    // Do not track transactions that were initiated by the current user
    if (initiatedAs === userType) return;

    const sharedProps = {
        days_since_sent: getDaysSince(lastTransitionedAt, new Date()),
        transaction_id: txUUID,
        ...extraProps,
    };

    if (initiatedAs === userTypeRider) {
        const horseListingId = listingId;
        const riderListing = await trackingDataProvider.getRiderListing(customerId);

        const props = await getChatEventPropsForHorseListing(
            trackingDataProvider,
            riderListing?.id?.uuid,
            horseListingId
        );

        mixpanel.track(`Horse Contact Request ${interaction}`, {
            ...props,
            ...sharedProps,
            horse_listing_id: horseListingId,
        });
    } else {
        const horseListingId = listingId;
        const riderUserId = providerId;
        const riderListing = await trackingDataProvider.getRiderListing(riderUserId);

        const props = await getChatEventPropsForRiderListing(
            trackingDataProvider,
            riderListing.id.uuid,
            horseListingId
        );

        mixpanel.track(`Rider Contact Request ${interaction}`, {
            ...props,
            ...sharedProps,
            rider_listing_id: riderListing.id.uuid,
        });
    }
};

const trackSearch = async (trackingDataProvider, payload) => {
    const { state } = trackingDataProvider;
    const {
        user: { currentUser },
    } = state;

    // do not tracked not logged in users
    if (!currentUser?.id) return;

    const {
        attributes: {
            profile: {
                publicData: { userType, mainHorseId },
                protectedData: { representationListingId },
            },
        },
    } = currentUser;

    const { params, listings = [], trackingData } = payload;

    // do not track search events from other pages
    if (trackingData.initiator !== 'SearchPage') return;

    const { pub_type, page } = params;

    // only track the first page
    if (page !== 1) return;

    if (pub_type === listingTypeRider && userType === userTypeRider) return;
    if (pub_type === listingTypeHorse && userType === userTypeHorseowner) return;

    const scores = await getMatchingScoreBulk({
        ...(userType === userTypeRider
            ? { riderListingId: representationListingId }
            : { mainHorseId }),
        listingIds: listings.map(l => l.id.uuid),
    }).catch(_ => []);

    const amendedListings = listings.map(l => ({
        ...l,
        finalMatchingScore: Array.isArray(scores)
            ? scores.find(s => s.listingId === l.id.uuid)?.matchingScore?.finalScore || -1
            : -1,
        distanceInKm: getDistance(getGeolocationFromListingOrUser(l), params.origin),
    }));

    const within10Km = [];
    const within15Km = [];
    const within20Km = [];
    const within30Km = [];
    const within10KmMatching31To69 = [];
    const within10KmMatching70To100 = [];
    const within15KmMatching31To69 = [];
    const within15KmMatching70To100 = [];
    const within30KmMatching31To69 = [];
    const within30KmMatching70To100 = [];

    amendedListings.forEach(listing => {
        if (listing.distanceInKm <= 10) within10Km.push(listing);
        if (listing.distanceInKm <= 15) within15Km.push(listing);
        if (listing.distanceInKm <= 20) within20Km.push(listing);
        if (listing.distanceInKm <= 30) within30Km.push(listing);
        if (
            listing.distanceInKm <= 10 &&
            listing.finalMatchingScore >= 31 &&
            listing.finalMatchingScore < 70
        )
            within10KmMatching31To69.push(listing);
        if (
            listing.distanceInKm <= 10 &&
            listing.finalMatchingScore >= 70 &&
            listing.finalMatchingScore <= 100
        )
            within10KmMatching70To100.push(listing);
        if (
            listing.distanceInKm <= 15 &&
            listing.finalMatchingScore >= 31 &&
            listing.finalMatchingScore < 70
        )
            within15KmMatching31To69.push(listing);
        if (
            listing.distanceInKm <= 15 &&
            listing.finalMatchingScore >= 70 &&
            listing.finalMatchingScore <= 100
        )
            within15KmMatching70To100.push(listing);
        if (
            listing.distanceInKm <= 30 &&
            listing.finalMatchingScore >= 31 &&
            listing.finalMatchingScore < 70
        )
            within30KmMatching31To69.push(listing);
        if (
            listing.distanceInKm <= 30 &&
            listing.finalMatchingScore >= 70 &&
            listing.finalMatchingScore <= 100
        )
            within30KmMatching70To100.push(listing);
    });

    const list_filters_used = [];

    if (params.pub_SSC_desiredDisciplinesLevels) list_filters_used.push('Niveau');
    if (params.pub_SSC_qualification) list_filters_used.push('Qualifikation');
    if (params.pub_activities) list_filters_used.push('Interessen');
    if (params.pub_age) list_filters_used.push('Alter');
    if (params.pub_availability) list_filters_used.push('Verfügbarkeit');
    if (params.pub_desiredDisciplines) list_filters_used.push('Disziplin');
    if (params.pub_mobilityTransport) list_filters_used.push('Mobilität');
    if (params.price) list_filters_used.push('Preis');
    if (params.services) list_filters_used.push('Service');
    if (params.pub_hight) list_filters_used.push('Grösse');
    if (params.pub_gender) list_filters_used.push('Geschlecht');
    if (params.pub_furnishing) list_filters_used.push('Reitanlage');

    const nb_key = pub_type === listingTypeHorse ? 'horse' : 'rider';

    mixpanel.track(`${pub_type === listingTypeHorse ? 'Horse Listing' : 'Rider'} Search Executed`, {
        search_location_address: trackingData.originAddress,
        list_filters_used,
        [`nb_${nb_key}_within_10km`]: within10Km.length,
        [`nb_${nb_key}_within_15km`]: within15Km.length,
        [`nb_${nb_key}_within_20km`]: within20Km.length,
        [`nb_${nb_key}_within_30km`]: within30Km.length,
        [`nb_${nb_key}_within_10km_matching_31-69`]: within10KmMatching31To69.length,
        [`nb_${nb_key}_within_10km_matching_70-100`]: within10KmMatching70To100.length,
        [`nb_${nb_key}_within_15km_matching_31-69`]: within15KmMatching31To69.length,
        [`nb_${nb_key}_within_15km_matching_70-100`]: within15KmMatching70To100.length,
        [`nb_${nb_key}_within_30km_matching_31-69`]: within30KmMatching31To69.length,
        [`nb_${nb_key}_within_30km_matching_70-100`]: within30KmMatching70To100.length,
    });
};

export const mixpanelMiddleware = sdk => ({ getState }) => next => action => {
    if (!mixpanelIsActivated) return;

    // The app should never fail in production because of tracking
    try {
        const { type, payload } = action;
        const state = getState();
        const {
            user,
            ProfilePage,
            Assets: { assetsData },
            ManageListingsPage: { ownEntities: ownListingsEntities },
        } = state;

        const trackingDataProvider = createTrackingDataProvider(sdk, state);

        trackUserChange(user, assetsData, ownListingsEntities);
        trackProfilePageChange(user, ProfilePage);

        if (type === PUBLISH_LISTING_SUCCESS) {
            trackHorseListingCreated(payload);
        }

        if (type === PROVIDE_SEND_ENQUIRY_MIXPANEL) {
            trackEnquirySent(trackingDataProvider, payload);
        }

        if (type === PROVIDE_CONSULT_CHAT_REQUEST_MIXPANEL) {
            trackEnquiryInteraction(trackingDataProvider, payload, 'Consulted');
        }

        if (type === PROVIDE_ANSWER_CHAT_REQUEST_MIXPANEL) {
            trackEnquiryInteraction(trackingDataProvider, payload, 'Answered', {
                accepted: payload.accepted,
            });
        }

        if (type === PROVIDE_LISTING_SEARCH_EXECUTED_MIXPANEL) {
            trackSearch(trackingDataProvider, payload);
        }

        if (type === PROVIDE_LOGOUT_MIXPANEL) {
            // following https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#call-reset-at-logout
            mixpanel.reset();
        }
    } catch (error) {
        if (process.env.NODE_ENV === 'development') throw error;
        console.error(error);
    }

    next(action);
};
