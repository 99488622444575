import React from 'react';
import { OverlayView } from 'react-google-maps';
import { CustomOverlayView } from './CustomOverlayView';
import { getPixelPositionOffset } from '../SearchMap.helpers.js';
import { userLocationSearchParamsMatch } from '../../../util/location.js';
import css from './CurrentLocationMarker.css';

export default ({ searchParams }) =>
    userLocationSearchParamsMatch(searchParams) ? (
        <CustomOverlayView
            position={searchParams.origin}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
        >
            <div className={css.marker}>
                <div />
            </div>
        </CustomOverlayView>
    ) : null;
