import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { notifyIosAppOnLogIn } from '../../util/ios';
/**
 * Show OneSignal banner to a user on successful login via idp
 */
export const useIdpAuthSuccess = currentUser => {
    const currentUserId = currentUser?.id?.uuid;

    useEffect(() => {
        if (!currentUserId) return;

        try {
            /**
             * For native app a user is redirected to Landing page
             * instead /idp-auth-success page as it works for the web
             */
            const idpAuthSuccess = Cookies.get('st-authsuccess')
                ? JSON.parse(Cookies.get('st-authsuccess').replace('j:', ''))
                : null;

            if (idpAuthSuccess && idpAuthSuccess.isNative) {
                /** send a message to IOS native app on successful login via idp */
                notifyIosAppOnLogIn(currentUser);
            }
        } catch (e) {
            // do nothing
        } finally {
            Cookies.remove('st-authsuccess');
        }
    }, [currentUserId]);
};
