import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { Avatar, Logo, Menu, MenuLabel, MenuContent, NamedLink } from '../../components';
import config from '../../config';
import css from './TopbarDesktop.css';
import { TopbarLocationSearchForm } from '../../forms';
import { IconBell, IconMenu, IconProfil } from '../../icons';
import ProfileMenuContent from '../Topbar/ProfileMenuContent';
import { ConditionalWrapper } from '../../util/common';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
import TopbarDesktopSearchPanel from './TopbarDesktopSearchPanel';
import { useListingType } from '../../hooks/useListingType';
import routeConfiguration from '../../routeConfiguration';
import { matchPathname } from '../../util/routes';
import { parse } from '../../util/urlHelpers';
import { isEmailNotVerified } from '../../util/user';
import ProfileMenuContentLoggedOut from '../Topbar/ProfileMenuContentLoggedOut';
import { requestCurrentLocation } from '../../ducks/location.duck';

const { userTypeHorseowner, userTypeRider } = config;

const EDIT_USER_AVAILABILITY_ID = 'EditUserProfileInfo.userAvailabilityId.topBar';

const TopbarDesktop = ({
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserUnreadMessagesRider,
    currentUserUnreadMessagesOwner,
    intl,
    isAuthenticated,
    initialSearchFormValues = {},
    hasDraftListing,
    notificationCount,
    disableScrollRequests = [],
    location,
    history,
    ...rest
}) => {
    const dispatch = useDispatch();
    /** Redux store data */
    const currentLocation = useSelector(s => s.location.currentLocation);
    const loading = useSelector(s => s.location.currentLocationRequestInProgress);

    const { desktopsearch, address: searchParamsAddress } = parse(location.search);

    const emailNotVerified = isEmailNotVerified(currentUser);

    const routes = matchPathname(location.pathname, routeConfiguration());
    const {
        route: { name },
    } = routes[0] || { route: { name: '' } };

    const isRiderFindPage = name === 'FindRiderPage';
    const isHorseFindPage = name === 'FindHorsePage';
    const isHorseLandingPage = name === 'LandingPage';

    const isLandingPages = isHorseLandingPage || isRiderFindPage || isHorseFindPage;

    const {
        attributes: {
            profile: {
                publicData: { userType },
            },
        },
    } = ensureCurrentUser(currentUser);

    const userIsRider = userType === userTypeRider;
    const userIsOwner = userType === userTypeHorseowner;
    const userIsDefined = currentUser && currentUser.id;
    const userPBMaybe = userIsDefined ? currentUser.attributes.profile.publicData : null;
    const userAddressMaybe = userPBMaybe ? `${userPBMaybe.postalCode} ${userPBMaybe.city}` : null;

    const [mounted, setMounted] = useState(false);
    const [availabilityModalBeingOpen, setAvailabilityModalBeingOpen] = useState(false);
    const [menuOpenForced, setMenuOpenForced] = useState(false);
    const [tabOpenKey, setTabOpenKey] = useState(new Date().toString());
    const [searchPanelOpen, setSearchPanelOpen] = useState(false);
    const [listingTypeTab, setListingTypeTab] = useListingType({ location, userType });
    const [initialFormValues, setInitialFormValues] = useState({});
    const [initialSearchPanelValues, setInitialSearchPanelValues] = useState(null);

    const menuRef = useRef();
    /**
     * If availability modal is open,
     * prevent topbar from closing
     */
    const isAvailabilityBadgeModalOpen = disableScrollRequests.some(
        comp => comp && comp.componentId === EDIT_USER_AVAILABILITY_ID && comp.disableScrolling
    );

    const menuForceOpenedProps = {
        key: tabOpenKey,
        isOpen: isAvailabilityBadgeModalOpen || menuOpenForced,
        onToggleActive: () => setMenuOpenForced(isAvailabilityBadgeModalOpen || !menuOpenForced),
    };
    /**
     * !searchParamsAddress - skip initialize form fields from user data
     * in case url has search params, e.g. a user is at Search Page
     */
    const initializeAddressInfoFromUserData =
        userIsDefined && userAddressMaybe && !searchParamsAddress;
    const initialDistance = '15 km';

    useEffect(() => {
        if (!currentLocation || !userAddressMaybe) return;

        setInitialSearchPanelValues({
            optionDistance: initialDistance,
            location: {
                search: userAddressMaybe,
                selectedPlace: { ...currentLocation },
            },
        });
    }, [currentLocation, userAddressMaybe]);

    useEffect(() => {
        setInitialFormValues(
            initializeAddressInfoFromUserData
                ? {
                      address: userAddressMaybe,
                      distance: initialDistance,
                  }
                : {}
        );
    }, [initializeAddressInfoFromUserData, userAddressMaybe]);

    useEffect(() => {
        if (initializeAddressInfoFromUserData) {
            dispatch(requestCurrentLocation());
        } else {
            setInitialSearchPanelValues(null);
        }
    }, [initializeAddressInfoFromUserData]);

    useEffect(() => {
        desktopsearch && setSearchPanelOpen(true);
    }, [desktopsearch]);

    useEffect(() => {
        function handleVisibilityChange() {
            setTabOpenKey(new Date().toString());
            setMenuOpenForced(false);
        }
        /**
         * when a user switches between a browser tabs
         * and the menu is open, the focus of it is lost;
         * taking into acc. that the value menuOpenForced
         * is true, the behaviour of the menu becomes invalid
         */
        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('blur', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('blur', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        if (isAvailabilityBadgeModalOpen) {
            setAvailabilityModalBeingOpen(true);
        }
    }, [isAvailabilityBadgeModalOpen]);

    useEffect(() => {
        /**
         * after availability modal is closed,
         * focus on menu component in order
         * to re-enable outside click functionality
         */
        if (
            !isAvailabilityBadgeModalOpen &&
            availabilityModalBeingOpen &&
            menuRef &&
            menuRef.current
        ) {
            menuRef.current.focus();
            setAvailabilityModalBeingOpen(false);
        }
    }, [isAvailabilityBadgeModalOpen, availabilityModalBeingOpen]);

    useEffect(() => {
        setMounted(true);
    }, []);

    const authenticatedOnClientSide = mounted && isAuthenticated;
    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
    const classes = classNames(rootClassName || css.root, className);

    const inboxLink = authenticatedOnClientSide ? (
        <NamedLink className={css.inboxLink} name="InboxPage">
            <FormattedMessage id="TopbarDesktop.inbox" />
            {notificationDot}
        </NamedLink>
    ) : null;

    const myHorse =
        authenticatedOnClientSide && userIsOwner ? (
            <NamedLink className={css.inboxLink} name="ManageListingsPage">
                <FormattedMessage id="TopbarDesktop.myListingsLink" />
                {hasDraftListing && <div className={css.notificationDot} />}
            </NamedLink>
        ) : null;

    const profileMenu = authenticatedOnClientSide && (
        <Menu
            {...menuForceOpenedProps}
            retrieveMenuRef={ref => {
                ref && (menuRef.current = ref);
            }}
            forceRight
            useArrow={false}
        >
            <MenuLabel className={css.profileMenuLabel}>
                <IconMenu />
                <Avatar className={css.avatar} user={currentUser} disableProfileLink />
            </MenuLabel>
            <MenuContent
                className={classNames(css.profileMenuContent, {
                    [css.emailNotVerified]: emailNotVerified,
                })}
            >
                <ProfileMenuContent
                    currentUser={currentUser}
                    notificationDot={notificationDot}
                    currentPage={currentPage}
                    ignoreMenuType
                    hasDraftListing={hasDraftListing}
                    notificationCount={notificationCount}
                    availabilityModalId={EDIT_USER_AVAILABILITY_ID}
                    {...rest}
                />
            </MenuContent>
        </Menu>
    );

    const profileMenuLoggedOut = !authenticatedOnClientSide && (
        <Menu
            {...menuForceOpenedProps}
            retrieveMenuRef={ref => {
                ref && (menuRef.current = ref);
            }}
            forceRight
            useArrow={false}
        >
            <MenuLabel className={classNames(css.profileMenuLabelLoggedOut, css.profileMenuLabel)}>
                <IconMenu />
                <code>
                    <IconProfil />
                </code>
            </MenuLabel>
            <MenuContent
                className={classNames(css.profileMenuContent, {
                    [css.emailNotVerified]: emailNotVerified,
                })}
            >
                <ProfileMenuContentLoggedOut ignoreMenuType currentPage={currentPage} />
            </MenuContent>
        </Menu>
    );

    const createListing = !userIsRider && (
        <NamedLink className={css.createListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarDesktop.createListing" />
        </NamedLink>
    );

    return (
        <ConditionalWrapper
            condition={searchPanelOpen /* && !!userIsDefined */}
            wrapper={children => (
                <OutsideClickHandler onOutsideClick={() => setSearchPanelOpen(false)}>
                    {children}
                </OutsideClickHandler>
            )}
        >
            <nav className={classes}>
                <main className={css.topBarMainNavigationHolder}>
                    <NamedLink className={css.logoLink} name="LandingPage">
                        <Logo
                            format="desktop"
                            className={css.logo}
                            alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
                        />
                    </NamedLink>

                    {isLandingPages && !userIsDefined ? (
                        <>
                            <NamedLink
                                className={classNames(css.findListingsLink, css.firstLink, {
                                    [css.selected]: isHorseFindPage,
                                })}
                                name="FindHorsePage"
                            >
                                <FormattedMessage id="TopbarDesktop.findHorses" />
                                <code />
                            </NamedLink>
                            <NamedLink
                                className={classNames(css.findListingsLink, {
                                    [css.selected]: isRiderFindPage,
                                })}
                                name="FindRiderPage"
                            >
                                <FormattedMessage id="TopbarDesktop.findRiders" />
                                <code />
                            </NamedLink>
                        </>
                    ) : (
                        <TopbarLocationSearchForm
                            rootClassName={css.topBarLocationForm}
                            onTabPanelToggle={value => setSearchPanelOpen(value)}
                            searchPanelOpen={searchPanelOpen}
                            listingTypeTab={listingTypeTab}
                            setListingTypeTab={setListingTypeTab}
                            initialValues={initialFormValues}
                        />
                    )}
                </main>

                <aside className={css.topBarAsideNavigationHolder}>
                    <div className={css.topBarNotificationBadge}>
                        <code
                            tabIndex="0"
                            role="button"
                            aria-label="Notifications"
                            href="#"
                            className="wisp"
                        >
                            <IconBell />
                        </code>
                    </div>
                    {inboxLink}
                    {myHorse}
                    {createListing}
                    {profileMenu}
                    {profileMenuLoggedOut}
                </aside>

                {searchPanelOpen && !loading && (
                    <TopbarDesktopSearchPanel
                        intl={intl}
                        listingTypeTab={listingTypeTab}
                        setListingTypeTab={setListingTypeTab}
                        onSetSearchPanelOpen={setSearchPanelOpen}
                        initialValues={initialSearchPanelValues}
                        applyOverlay
                    />
                )}
            </nav>
        </ConditionalWrapper>
    );
};

const { bool, func, object, string } = PropTypes;

TopbarDesktop.propTypes = {
    rootClassName: string,
    className: string,
    currentUser: propTypes.currentUser,
    currentPage: string,
    isAuthenticated: bool.isRequired,
    onLogout: func.isRequired,
    initialSearchFormValues: object,
    intl: intlShape.isRequired,
};

export default compose(withRouter)(TopbarDesktop);
