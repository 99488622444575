import React, { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { compose } from 'redux';
import { CURRENT_LOCATION_ID, ultimateGoogleMapsCountriesConfig } from '../../util/googleMaps';
import { obfuscatedCoordinates } from '../../util/maps';
import { injectIntl } from '../../util/reactIntl';
import { capitalizeFormEntry } from '../../util/text';
import {
    composeValidators,
    autocompleteSearchRequired,
    autocompletePlaceSelected,
    minLength,
    required,
    numbersAndLetters,
    isValidAddress,
    onlyNumbers,
} from '../../util/validators';
import {
    FieldSelect,
    FieldTextInput,
    IconSpinner,
    LocationAutocompleteInputField,
    Map,
} from '../../components';
import config from '../../config';
import Geocoder from '../../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import { getUserCountry, getUserLocationUnitStr } from '../../util/location';
import { getPlaceDetailsGeoData } from '../../util/geocoder';
import classNames from 'classnames';
import css from './EntityLocationFormFields.css';

export const PREDICT_LOCATION_STEP = 'location';
export const FILL_ADDRESS_STEP = 'address';

const DEBOUNCE_WAIT_TIME = 500;
const identity = v => v;

const { stripe } = config;
const { supportedCountries: stripeSupportedCountries } = stripe;

const countriesOrderConfig = { CH: 0, DE: 1, AT: 2, NL: 3 };

const allowedCodes = Object.values(ultimateGoogleMapsCountriesConfig).map(({ abbr }) =>
    abbr.toUpperCase()
);
// ['CH', 'DE', 'AT', 'NL'];

const availableCountries = stripeSupportedCountries
    .reduce((allCountries, country) => {
        const { code } = country;
        if (allowedCodes.includes(code)) {
            allCountries.push(country);
        }

        return allCountries;
    }, [])
    .sort((a, b) => countriesOrderConfig[a.code] - countriesOrderConfig[b.code]);

/**
 * @param {*} typeToFind route, postal_code, etc
 * @param {*} addressComponents google api address_components
 * @returns string
 */
export const findByType = (typeToFind, addressComponents) =>
    (addressComponents.find(({ types = [] }) => types.includes(typeToFind)) || {}).long_name;

const EntityLocationFormFields = ({
    intl,
    initialValues = {},
    form,
    values,
    valid,
    noLocationFoundContent,
    initialStep = PREDICT_LOCATION_STEP,
    notifyOnChange = () => null,
}) => {
    const [currentStep, setCurrentStep] = useState(initialStep);
    const [predictionInProgress, setPredictionInProgress] = useState(false);
    const [predictionSelected, setPredictionSelected] = useState(null);
    const [locationUnits, setLocationUnits] = useState(null);
    const [geocoder, setGeocoder] = useState(null);
    const [timeoutID, setTimeoutID] = useState(null);

    const { location, city, houseNumber, streetAddress, postalCode, country } = values;

    const { getFieldState, change: changeFormField } = form;

    const { valid: locationValid } = getFieldState('location') || {};

    const { active: streetAddressActive } = getFieldState('streetAddress') || {
        active: true,
    };
    const { active: houseNumberActive } = getFieldState('houseNumber') || {
        active: true,
    };
    const { active: postalCodeActive } = getFieldState('postalCode') || {
        active: true,
    };
    const { active: cityActive } = getFieldState('city') || { active: true };

    const predictAllowed =
        !streetAddressActive && !houseNumberActive && !postalCodeActive && !cityActive;

    const isLocation = currentStep === PREDICT_LOCATION_STEP;
    const isAddress = currentStep === FILL_ADDRESS_STEP;
    const allFieldsAreFilled = city && houseNumber && streetAddress && postalCode;

    const resolvePredictionAllowed =
        allFieldsAreFilled && currentStep === FILL_ADDRESS_STEP && predictAllowed && valid;

    const geolocationMaybe =
        predictionSelected && predictionSelected.origin ? predictionSelected : null;

    const cacheKey = geolocationMaybe
        ? `${geolocationMaybe.origin.lat}_${geolocationMaybe.origin.lng}`
        : null;

    const mapProps = geolocationMaybe
        ? { obfuscatedCenter: obfuscatedCoordinates(geolocationMaybe.origin, cacheKey) }
        : {};

    const map = geolocationMaybe && (
        <section className={css.mapSection}>
            <Map
                {...mapProps}
                mapsConfig={{
                    ...config.maps,
                    fuzzy: {
                        ...config.maps.fuzzy,
                        enabled: true,
                        defaultZoomLevel: 15,
                        offset: 500,
                    },
                }}
            />
        </section>
    );

    const predictLocation = async () => {
        if (!geocoder || !locationUnits || predictionInProgress) {
            return;
        }
        setPredictionInProgress(true);

        if (form) {
            /** may be used to notify form on request being made */
            form.change('location', undefined);
        }

        try {
            const { value } = getFieldState('country') || {};
            /**
             * predict locations related to selected country only
             */
            const searchConfigurations = value
                ? {
                      componentRestrictions: {
                          country: value.toLowerCase(),
                      },
                  }
                : null;

            const predictionData = await getPlaceDetailsGeoData({
                locationUnitsStr: locationUnits,
                searchConfigurationsProp: searchConfigurations,
            });

            if (!predictionData || !predictionData.origin) {
                throw new Error();
            }

            if (form) {
                form.change('location', predictionData.origin);
            }
            setPredictionSelected(predictionData);
            setPredictionInProgress(false);
        } catch (e) {
            setPredictionInProgress(false);
        }
    };
    useEffect(() => {
        if (isLocation && location && locationValid) {
            /** set values from address_components when a user selects prediction */
            const {
                selectedPlace: { address_components = [] },
            } = location;
            const changeFieldIfAny = (key, value) => value && changeFormField(key, value);

            changeFieldIfAny('streetAddress', findByType('route', address_components));
            changeFieldIfAny('houseNumber', findByType('street_number', address_components));
            changeFieldIfAny('postalCode', findByType('postal_code', address_components));
            changeFieldIfAny('city', findByType('locality', address_components));

            setCurrentStep(FILL_ADDRESS_STEP);
        }
    }, [isLocation, location, locationValid]);

    useEffect(() => {
        const { streetAddress, houseNumber, postalCode, city, country } = initialValues;
        /** country is taken from user id  */
        if (city && houseNumber && streetAddress && postalCode) {
            setLocationUnits(
                getUserLocationUnitStr({
                    streetAddress,
                    houseNumber,
                    postalCode,
                    city,
                    country,
                    intl,
                })
            );

            setCurrentStep(FILL_ADDRESS_STEP);
        }
    }, [initialValues]);

    useEffect(() => {
        if (geocoder) {
            return;
        }

        setGeocoder(new Geocoder());
    }, []);

    useEffect(() => {
        debounce(() => {
            predictAllowed &&
                setLocationUnits(
                    getUserLocationUnitStr({
                        intl,
                        country,
                        postalCode,
                        city,
                        streetAddress,
                        houseNumber,
                    })
                );
        }, DEBOUNCE_WAIT_TIME)();
    }, [resolvePredictionAllowed]);

    useEffect(() => {
        if (timeoutID) clearTimeout(timeoutID);

        const newTimeoutID = setTimeout(() => {
            predictLocation(locationUnits);
        }, 350);

        setTimeoutID(newTimeoutID);

        return () => {
            setTimeoutID(null);
        };
    }, [locationUnits]);

    useEffect(() => {
        notifyOnChange({ currentStep });
    }, [currentStep]);

    const formatMessage = id =>
        intl.formatMessage({
            id,
        });

    const userCountry = getUserCountry();

    const searchConfigurations = userCountry
        ? {
              componentRestrictions: {
                  country: [userCountry.toLowerCase()],
              },
          }
        : null;

    return (
        <>
            {predictionInProgress ? <IconSpinner /> : map}

            <div>
                {isLocation && (
                    <>
                        <LocationAutocompleteInputField
                            name="location"
                            format={identity}
                            placeholder={formatMessage('CollectUserInfoWizard.locationPlaceholder')}
                            className={css.inputLocationWrapper}
                            predictionsClassName={css.locationPrediction}
                            validate={composeValidators(
                                autocompleteSearchRequired('Address required'),
                                autocompletePlaceSelected('Address required')
                            )}
                            useDefaultPredictions
                            searchConfigurations={searchConfigurations}
                            defaultPredictions={[{ id: CURRENT_LOCATION_ID, predictionPlace: {} }]}
                        />
                        <p
                            className={css.notLocationFound}
                            onClick={() => setCurrentStep('address')}
                        >
                            {noLocationFoundContent || 'Ich finde meine Wohnadresse nicht'}
                        </p>
                    </>
                )}
                {isAddress && (
                    <>
                        <div
                            className={classNames([
                                css.formRowAdaptive,
                                css.formRowLocation,
                                css.formRowLocationMain,
                            ])}
                        >
                            <FieldTextInput
                                // 'route'
                                type="text"
                                id="streetAddress"
                                name="streetAddress"
                                className={css.fieldItem}
                                placeholder={formatMessage(
                                    'CollectUserInfoWizard.streetPlaceholder'
                                )}
                                validate={composeValidators(
                                    required(formatMessage('CollectUserInfoWizard.streetRequired')),
                                    isValidAddress('Zahlen und Sonderzeichen sind nicht erlaubt.')
                                )}
                                disabled={predictionInProgress}
                                handleBlur={() => capitalizeFormEntry('streetAddress', form)}
                            />
                            <FieldTextInput
                                // 'street_number'
                                type="text"
                                id="houseNumber"
                                name="houseNumber"
                                className={css.fieldItem}
                                placeholder={formatMessage(
                                    'CollectUserInfoWizard.numberPlaceholder'
                                )}
                                validate={composeValidators(
                                    required(
                                        formatMessage('CollectUserInfoWizard.houseNumberRequired')
                                    ),
                                    numbersAndLetters('Es sind nur Buchstaben oder Zahlen erlaubt.')
                                )}
                            />
                        </div>
                        <div className={classNames([css.formRowAdaptive, css.formRowLocation])}>
                            <FieldTextInput
                                // 'postal_code'
                                type="number"
                                id="postalCode"
                                name="postalCode"
                                className={css.fieldItem}
                                placeholder={formatMessage(
                                    'CollectUserInfoWizard.indexPlaceholder'
                                )}
                                disabled={predictionInProgress}
                                validate={composeValidators(
                                    required(
                                        formatMessage('CollectUserInfoWizard.postalIndexRequired')
                                    ),
                                    onlyNumbers(
                                        formatMessage(
                                            'CollectUserInfoWizard.onlyNumbersAllowedMessage'
                                        )
                                    ),
                                    minLength(
                                        formatMessage('CollectUserInfoWizard.postalIndexRequired'),
                                        4
                                    )
                                )}
                            />
                            <FieldTextInput
                                /** locality */
                                type="text"
                                id="city"
                                name="city"
                                className={css.fieldItem}
                                placeholder={formatMessage('CollectUserInfoWizard.cityLabel')}
                                validate={composeValidators(
                                    required(
                                        formatMessage('CollectUserInfoWizard.residenceRequired')
                                    ),
                                    isValidAddress('Zahlen und Sonderzeichen sind nicht erlaubt.')
                                )}
                                disabled={predictionInProgress}
                                handleBlur={() => capitalizeFormEntry('city', form)}
                            />
                        </div>
                        <FieldSelect
                            id="country"
                            name="country"
                            className={css.fieldItem}
                            disabled={predictionInProgress}
                            validate={required(
                                formatMessage('CollectUserInfoWizard.countryRequired')
                            )}
                            placeholder={formatMessage('CollectUserInfoWizard.countryPlaceholder')}
                            form={form}
                            optionsList={availableCountries.map(c => ({
                                value: c.code,
                                label: intl.formatMessage({
                                    id: `PayoutDetailsForm.countryNames.${c.code}`,
                                }),
                            }))}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default compose(injectIntl)(EntityLocationFormFields);
