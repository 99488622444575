import { getOneSignalPromptData, ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT } from './localStorage';
/**
 * Check if it is needed to show the OneSignal push notifications banner
 *
 * @param {object} currentUser - sdk current user
 * @returns boolean
 */
export const shouldSkipOSBanner = currentUser => {
    const {
        id,
        attributes: {
            profile: {
                publicData: { userType },
            },
        },
    } = currentUser;
    const { uuid: currentUserId } = id || {};
    /**
     * show to a user the prompt per session
     */
    const shownPerSession = window.sessionStorage.getItem(ONE_SIGNAL_PUSH_NOTIFICATION_PROMPT);
    /**
     * if a user accepts the prompt, set the same flag
     * to the local storage, so that the flag becomes
     * permanent, and a user is not shown the prompt
     * during new sessions
     */
    const promptLSData = getOneSignalPromptData({ userType, currentUserId });
    const alreadyAccepted = promptLSData && promptLSData.accepted;
    const skipPushNotificationBanner = shownPerSession || alreadyAccepted;

    return skipPushNotificationBanner;
};
